import * as React from 'react';
import styled from 'styled-components';

interface Props {
  text: string;
  color: string;
  size?: number;
  fontSize?: number;
}

export default function Status(props: Props) {
  const { text, color, size = 104, fontSize = 16 } = props;

  return (
    <StatusContainer size={size} color={color} fontSize={fontSize}>
      {text}
    </StatusContainer>
  );
}

const StatusContainer = styled.div<{
  color: string;
  size: number;
  fontSize: number;
}>`
  padding: 1px 6px;
  border-radius: 12px;
  font-size: ${(props) => props.fontSize};
  font-weight: bold;
  width: ${(props) => props.size}px;
  overflow-wrap: break-word;
  text-align: center;
  background-color: ${(props) => props.color};
  color: white;
`;
