import * as React from 'react';
import { Container } from './style';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Form, Field, Button } from 'components/element';
import { useMutationChangePassword } from 'repositories/auth';
import { toast } from 'react-toastify';
import LoadingView from 'components/LoadingView';

const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required(),
  password: Yup.string().required(),
  passwordConfirmation: Yup.string()
    .required()
    .oneOf([Yup.ref('password')]),
});

export default function ChangePassword(props: any) {
  const [changePassword] = useMutationChangePassword();
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      passwordConfirmation: '',
    },
    validateOnMount: true,
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const response = await changePassword({
          variables: {
            payload: {
              old_password: values.oldPassword,
              password: values.password,
              password_confirmation: values.passwordConfirmation,
            },
          },
        });

        response && toast.success(response.data?.changePassword.message);
      } catch (e) {}
      setSubmitting(false);
    },
  });

  return (
    <Container>
      <Form formik={formik}>
        <Field
          type="password"
          name="oldPassword"
          placeholder={t('common.old_password')}
        />
        <Field
          type="password"
          name="password"
          placeholder={t('common.password_new')}
        />
        <Field
          type="password"
          name="passwordConfirmation"
          placeholder={t('common.password_confirmation_new')}
        />
        <Button
          type="submit"
          isLoading={formik.isSubmitting}
          disabled={!formik.isValid}
        >
          {t('common.save')}
        </Button>
      </Form>
      <LoadingView isOpen={formik.isSubmitting} />
    </Container>
  );
}
