import { gql } from '@apollo/client';

export const STYLIST_FRAGMENT = gql`
  fragment Stylist on Stylist {
    id
    name
    email
    phoneNumber
    imagePath
    isAvailable
    rate
    rateCount
    updatedAt
    createdAt
    realName
    date
    pivot {
      outletId
      stylistId
    }
  }
`;
