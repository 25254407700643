import styled, { css, keyframes } from 'styled-components';
import color from 'styles/color';
import ReactSelect from 'react-select';
import { NavLink } from 'react-router-dom';

const SIDEBAR_WIDTH = 260;

export const Container = styled.div<{ whiteBg?: boolean }>`
  background-color: ${color.black};
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;

  ${(props) =>
    props.whiteBg &&
    css`
      background-color: ${color.white} !important;
    `}
`;

export const SideNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 ${SIDEBAR_WIDTH}px;
  padding: 1rem 10px;
  position: relative;
`;

export const SideNavIcon = styled.div<{ icon: string }>`
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  background-size: cover;
  background-image: url(${(props) => (props.icon ? props.icon : '')});
  display: inline-block;
`;

export const StyledNavLink = styled(NavLink)<{ activeIcon: string }>`
  min-height: 40px;
  &.nav-link {
    display: flex;
    background-color: ${color.neutral11};
    color: ${color.white};
    align-items: center;
    font-size: 1rem;
    padding: 0.5rem 0.3rem;
    text-decoration: none;
    overflow: hidden;
    font-weight: bold;
    border-radius: 10px;
    margin-bottom: 1rem;
    &.active {
      ${SideNavIcon} {
        background-image: url(${(props) =>
          props.activeIcon ? props.activeIcon : ''});
      }
    }
  }
  &:hover {
    transform: scale(1.02);
    background-color: ${color.white};
    color: ${color.black};
  }

  &:hover ${SideNavIcon} {
    background-image: url(${(props) =>
      props.activeIcon ? props.activeIcon : ''});
  }
`;

export const SideNavHeader = styled.div`
  color: ${color.white};
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  min-height: 140px;
`;

const ScaleKeyframes = keyframes`
  0%   {transform: scale(.9);}
  100% {transform: scale(1);}
`;

const PanelHideKeyframes = keyframes`
  from {top: 0px;}
  to {top: -120px;}
`;

export const PanelHideCSS = css`
  top: -140px;
  animation: ${PanelHideKeyframes} 0.3s alternate ease-in;
`;

export const PanelText = styled.p`
  display: flex;
  flex: 1;
`;

export const PanelButton = styled.button`
  margin-right: 16px;
  background-color: ${color.black};
  border-radius: 5px;
  color: ${color.white};
  padding: 4px 16px;
  outline: none;
  width: 100%;
  cursor: pointer;
  border: 1px solid ${color.black};

  &:hover {
    transform: scale(1.02);
  }
`;

export const NetworkPanel = styled.div<{ show?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 140px;
  background-color: ${color.white};
  margin-bottom: 1.5rem;
  border-radius: 0 0 5px 5px;
  position: absolute;
  top: -140px;
  left: 10px;
  right: 10px;
  padding: 8px;
  transition: top 0.3s ease-in-out;

  ${(props) =>
    props.show &&
    css`
      top: 0px;
      transition: top 0.3s ease-in-out;
    `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 1rem;
  justify-content: space-between;
`;

export const Status = styled.div<{ online: boolean }>`
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  margin-right: 0.8rem;
  ${(props) =>
    props.online
      ? css`
          background-color: ${color.fern1};
        `
      : css`
          background-color: red;
        `}
`;

export const StatusText = styled.p`
  font-size: 16px;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NetworkButton = styled.button`
  margin-right: 16px;
  background-color: ${color.black};
  border-radius: 5px;
  color: ${color.white};
  padding: 4px 16px;
  outline: none;
  animation: ${ScaleKeyframes} 1.5s alternate infinite ease-in;

  &:hover {
    transform: scale(1.02);
  }
`;

export const Body = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  background-color: ${color.white};
  max-width: calc(100vw - ${SIDEBAR_WIDTH}px);
  border-radius: 25px 0px 0px 25px;
  padding: 1rem 1.5rem 1rem 1.5rem;
`;

export const NameText = styled.p`
  font-size: 16px;
  margin-left: 1rem;
  margin-right: 0.3rem;
`;

export const StyledReactSelect = styled(ReactSelect)`
  &.react-select__container {
    min-width: 250px;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 1rem;

    .react-select__control {
      border-radius: 10px;
      min-height: 30px;
      border: 1px solid ${color.black};
    }

    .react-select__menu {
      z-index: 1100 !important;
    }
  }
`;

export const SideNavContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
`;
