import * as React from 'react';
import {
  Container,
  Header,
  Row,
  SelectButton,
  DateFilter,
  BarButton,
  ButtonRow,
  Badges,
} from './style';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import Management from './components/Management';
import ActiveBooking from './components/ActiveBooking';
import CompletedBooking from './components/CompletedBooking';
import AddBooking from './components/AddBooking';
import useDialog from 'hooks/useDialog';
import { useStateGetCurrentOutlet } from 'repositories/outlet';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';
import DateInput from 'components/element/DateInput';

export default function ChangePassword(props: any) {
  const [page, setPage] = React.useState(0);
  const [date, setDate] = React.useState(new Date());
  const [activeBadge, setActiveBadge] = React.useState(0);
  const { t } = useTranslation();
  const dialog = useDialog();
  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';

  const { mode } = useNetworkStatus();

  React.useEffect(() => {
    setDate(new Date());
  }, [outletId]);

  React.useEffect(() => {
    if (!mode) {
      setDate(new Date());
    }
  }, [mode]);

  const onDateChange = React.useCallback((date: any) => {
    setDate(date);
  }, []);

  const onHandleAddBooking = React.useCallback(() => {
    dialog.showCustom({
      render(onClose) {
        return <AddBooking date={date} onClose={onClose} />;
      },
    });
  }, [dialog, date]);

  return (
    <Container show>
      <Header>
        <Row>
          <BarButton active={page === 0} onClick={() => setPage(0)}>
            {t('management.management')}
          </BarButton>
          <BarButton active={page === 1} onClick={() => setPage(1)}>
            {t('common.active')}
            {!!activeBadge && (
              <Badges>{activeBadge > 99 ? '99+' : activeBadge}</Badges>
            )}
          </BarButton>
          <BarButton active={page === 2} onClick={() => setPage(2)}>
            {t('common.completed')}
          </BarButton>
        </Row>
        <ButtonRow>
          <DateFilter
            disabled={!mode}
            selected={date}
            onChange={onDateChange}
            dateFormat="yyyy-MM-dd"
            customInput={<DateInput />}
          />
          <SelectButton disabled={!date} onClick={onHandleAddBooking}>
            <span>+</span> {t('common.booking')}
          </SelectButton>
        </ButtonRow>
      </Header>
      {/* todo: remove this workaround when Activebooking rendered only 2 columns show */}
      {page !== 1 && (
        <ActiveBooking
          date={format(date, 'yyyy-MM-dd')}
          show={false}
          setActiveBadge={setActiveBadge}
        />
      )}
      {(() => {
        switch (page) {
          case 0:
            return <Management date={format(date, 'yyyy-MM-dd')} show />;
          case 1:
            return (
              <ActiveBooking
                date={format(date, 'yyyy-MM-dd')}
                show
                setActiveBadge={setActiveBadge}
              />
            );
          case 2:
            return <CompletedBooking date={format(date, 'yyyy-MM-dd')} show />;
        }
      })()}
    </Container>
  );
}
