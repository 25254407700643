import { gql } from '@apollo/client';

export const BOOKINGS_FRAGMENT = gql`
  fragment Bookings on Bookings {
    id
    member {
      id
      phoneNumber
      name
      birthDate
      email
      address
      point
    }
    services {
      id
      name
      description
      price
      point
      timeInMinutes
    }
    products {
      id
      code
      name
      description
      qty
      price
      point
    }
    stylist {
      id
      name
    }
    transactionStatus
    bookingChangeLogs
    isRandomStylist
    startMinute
    timeInMinutes
    type
    status
    createdAt
  }
`;

export const BOOKING_UNSET_FRAGMENT = gql`
  fragment BookingUnset on BookingUnset {
    id
    member {
      id
      phoneNumber
      name
      birthDate
      email
      address
      point
    }
    services {
      id
      name
      description
      price
      point
      timeInMinutes
    }
    products {
      id
      code
      name
      description
      qty
      price
      point
    }
    stylist {
      id
      name
    }
    isRandomStylist
    startMinute
    timeInMinutes
    type
    status
    createdAt
  }
`;
