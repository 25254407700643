import { ApolloClient, gql } from '@apollo/client';
import localForage from 'localforage';
import { format } from 'date-fns';
import {
  TransactionService,
  TransactionProduct,
  GET_TRANSACTIONS_CACHE,
} from 'repositories/transaction';
import { BookableTypeEnum } from 'repositories/booking/booking.model';
import { MasterData } from 'repositories/common/common.model';
import { v4 as uuidv4 } from 'uuid';
import { TRANSACTION_FRAGMENT } from 'repositories/transaction/transaction.fragment';

interface TransactionPayload {
  id: string;
  outletId: string;
  serviceId: any[];
  products: any[];
  bookingId: string;
  stylistId: string;
  paymentId: string;
  phoneNumber: string;
  payAmount: string;
  totalPay: number;
  status: number;
  cancelNote?: string;
  cancelPaymentId?: string;
  customerName: string;
  email: string;
}

interface PrintLog {
  id: string;
  transactionId: string;
  inputBy: number;
  printAt: string;
  createdAt: string;
  updatedAt: string;
}

export async function createTransaction(
  payload: TransactionPayload,
  client: ApolloClient<any>,
) {
  const adminEmail = await localForage.getItem('adminEmail');
  let transactions: any;
  let result: MasterData | string | null = localStorage.getItem('masterData');
  let admins: any[] = [];
  let stylists: any[] = [];
  let payments: any[] = [];

  if (result && result !== 'undefined') {
    result = JSON.parse(result) as MasterData;

    const outlet = result?.outlets.find(
      (outlet: any) => outlet.id === payload.outletId,
    );

    if (outlet) {
      stylists = outlet.stylists;
    }

    if (result) {
      admins = result.admins || [];
      payments = result.payments || [];
    }
  }

  try {
    transactions = client.cache.readQuery({
      query: GET_TRANSACTIONS_CACHE,
      variables: {
        outletId: payload.outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
        statuses: '2',
      },
    });
  } catch (e) {}

  let newTransactions: any[] = [];
  const currentStylist = stylists.find(
    (stylist) => stylist.id === payload.stylistId,
  );
  const currentAdmin = admins.find((admin) => admin.email === adminEmail);
  const currentPayment = payments.find(
    (payment) => payment.id === payload.paymentId,
  );

  if (transactions) {
    newTransactions = JSON.parse(
      JSON.stringify(transactions.transactions.data),
    );
  }

  const currentTransaction = {
    __typename: 'Transactions',
    id: payload.id,
    bookingId: payload.bookingId || '',
    outlet: null,
    status: payload.status,
    totalPrice: payload.totalPay,
    totalPay: payload.totalPay,
    payAmount: payload.payAmount,
    stylist: {
      id: currentStylist ? currentStylist.id : '',
      name: currentStylist ? currentStylist.name : '',
    },
    services: payload.serviceId
      ? payload.serviceId.map((service: any) => ({
          id: service.service.id,
          name: service.service.name,
          description: '',
          imagePath: '',
          price: service.service.price,
          timeInMinutes: service.service.timeInMinutes,
          point: service.service.point || 0,
        }))
      : [],
    products: payload.products
      ? payload.products.map((product: any) => ({
          id: product.product.id,
          name: product.product.name,
          description: '',
          code: product.product.code || '',
          price: product.product.price,
          timeInMinutes: 0,
          qty: product.qty,
          point: product.product.point || 0,
        }))
      : [],
    member: {
      id: uuidv4(),
      name: payload.customerName || '',
      phoneNumber: payload.phoneNumber || '',
      email: payload.email || '',
      description: '',
      address: null,
      birthDate: null,
      point: null,
      imagePath: '',
    },
    voucher: null,
    discount: 0,
    inputBy: {
      name: currentAdmin.name || '',
      email: currentAdmin.email || '',
    },
    updatedBy: {
      name: currentAdmin.name || '',
      email: currentAdmin.email || '',
    },
    transactionPayment: {
      paymentName: currentPayment.name || '',
    },
    transactionAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };

  newTransactions.push(currentTransaction);

  try {
    client.writeFragment({
      id: `Bookings:${payload.bookingId}`,
      fragment: gql`
        fragment Bookings on Bookings {
          transactionStatus
        }
      `,
      data: {
        transactionStatus: 2,
      },
    });
  } catch (e) {}

  try {
    client.cache.writeQuery({
      query: GET_TRANSACTIONS_CACHE,
      data: {
        transactions: {
          data: newTransactions,
        },
      },
      variables: {
        outletId: payload.outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
        statuses: '2',
      },
    });
  } catch (e) {}
}

export async function removeTransaction(
  transactionId: string,
  outletId: string,
  client: ApolloClient<any>,
) {
  let activeTransactions: any;

  try {
    activeTransactions = client.cache.readQuery({
      query: GET_TRANSACTIONS_CACHE,
      variables: {
        outletId: outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
        statuses: '2',
      },
    });
  } catch (e) {}

  let newActiveTransactions: any[] = [];

  if (activeTransactions) {
    newActiveTransactions = JSON.parse(
      JSON.stringify(activeTransactions.transactions.data),
    );

    newActiveTransactions = newActiveTransactions.filter(
      (transaction) => transaction.id !== transactionId,
    );

    try {
      client.cache.writeQuery({
        query: GET_TRANSACTIONS_CACHE,
        data: {
          transactions: {
            data: newActiveTransactions,
          },
        },
        variables: {
          outletId: outletId,
          date: format(new Date(), 'yyyy-MM-dd'),
          statuses: '2',
        },
      });
    } catch (e) {
      const err = e as any;
      throw new Error(err);
    }
  }
}

export async function addOfflineTransactionCache(
  payload: TransactionPayload,
  printlog?: PrintLog[],
) {
  const transactions: any = await localForage.getItem('transactions');
  const adminEmail = await localForage.getItem('adminEmail');
  let newTransactions: any[] = [];
  let result: MasterData | string | null = localStorage.getItem('masterData');
  let admins: any[] = [];
  let services: any = [];
  let products: any = [];

  if (result && result !== 'undefined') {
    result = JSON.parse(result) as MasterData;

    if (result) {
      admins = result.admins || [];
      services =
        (await result?.outlets?.find(
          (outlet) => outlet?.id === payload?.outletId,
        )?.services) || [];
      products =
        (await result?.outlets?.find(
          (outlet) => outlet?.id === payload?.outletId,
        )?.products) || [];
    }
  }

  if (transactions && transactions !== 'undefined') {
    newTransactions = transactions;
  }

  const currentAdmin = admins.find((admin) => admin.email === adminEmail);

  const details: any[] = [];

  if (payload.serviceId) {
    await payload.serviceId.forEach(async (service: TransactionService) => {
      details.push({
        id: uuidv4(),
        transactionId: payload.id,
        transactionableId: service.service.id,
        transactionableType: BookableTypeEnum[0],
        price: service.service.price,
        point: service.service.point,
        cost:
          (await services?.find(
            (current: any) => current?.id === service?.service?.id,
          )?.cost) || 0,
        timeInMinutes: service.service.timeInMinutes,
        qty: 0,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      });
    });
  }

  if (payload.products) {
    await payload.products.forEach(async (product: TransactionProduct) => {
      details.push({
        id: uuidv4(),
        transactionId: payload.id,
        transactionableId: product.product.id,
        transactionableType: BookableTypeEnum[1],
        price: product.product.price,
        point: product.product.point,
        cost: products?.find(
          (current: any) => current?.id === product?.product?.id,
        )?.cost,
        timeInMinutes: 0,
        qty: product.qty,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      });
    });
  }

  newTransactions.push({
    id: payload.id,
    ...(payload.phoneNumber
      ? {
          phoneNumber: payload.phoneNumber,
        }
      : {}),
    customerName: payload.customerName,
    email: payload.email,
    outletId: payload.outletId,
    bookingId: payload.bookingId,
    cancelNote: payload.cancelNote,
    cancelPaymentId: payload.cancelPaymentId,
    inputBy: currentAdmin.id || 0,
    updateBy: currentAdmin.id || 0,
    status: payload.status,
    transactionAt: new Date().toISOString(),
    paymentId: payload.paymentId,
    payAmount: payload.payAmount,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    details,
    printlogs: printlog ? printlog : [],
  });

  await localForage.setItem('transactions', newTransactions);
}

export async function addCancelTransaction(
  outletId: string,
  transaction: any,
  status: number,
  client: ApolloClient<any>,
) {
  let cancelledTransactions: any;
  let newCancelledTransactions: any[] = [];

  try {
    cancelledTransactions = client.cache.readQuery({
      query: GET_TRANSACTIONS_CACHE,
      variables: {
        outletId: outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
        statuses: '8,9',
      },
    });
  } catch (e) {}

  if (cancelledTransactions) {
    newCancelledTransactions = JSON.parse(
      JSON.stringify(cancelledTransactions.transactions.data),
    );
  }

  if (transaction) {
    const newTransaction = {
      ...transaction,
      status,
    };

    newCancelledTransactions.push(newTransaction);

    try {
      client.cache.writeQuery({
        query: GET_TRANSACTIONS_CACHE,
        data: {
          transactions: {
            data: newCancelledTransactions,
          },
        },
        variables: {
          outletId: outletId,
          date: format(new Date(), 'yyyy-MM-dd'),
          statuses: '8,9',
        },
      });
    } catch (e) {}
  }
}

export async function cancelTransaction(
  transactionId: string,
  outletId: string,
  client: ApolloClient<any>,
  cancelNote: string,
  status: number,
) {
  const adminEmail = await localForage.getItem('adminEmail');
  const transactions: any = await localForage.getItem('transactions');

  let newTransactions: any[] = [];
  let transaction: any;
  let result: MasterData | string | null = localStorage.getItem('masterData');
  let payments: any[] = [];
  let admins: any[] = [];

  if (result && result !== 'undefined') {
    result = JSON.parse(result) as MasterData;

    if (result) {
      payments = result.payments || [];
      admins = result.admins || [];
    }
  }

  if (transactions && transactions !== 'undefined') {
    newTransactions = JSON.parse(JSON.stringify(transactions));
  }

  let transactionIndex;
  const currentAdmin = admins.find((admin) => admin.email === adminEmail);

  if (newTransactions.length) {
    transactionIndex = newTransactions.findIndex(
      (transaction: any) => transaction.id === transactionId,
    );
  }

  try {
    transaction = client.readFragment({
      id: `Transactions:${transactionId}`,
      fragment: TRANSACTION_FRAGMENT,
    });
  } catch (e) {}

  if (transaction) {
    await addCancelTransaction(outletId, transaction, status, client);
    await removeTransaction(transactionId, outletId, client);
  }

  if (transactionIndex !== undefined && transactionIndex > -1) {
    newTransactions[transactionIndex].status = 9;
    newTransactions[transactionIndex].updateBy = currentAdmin.id || 0;
    newTransactions[transactionIndex].updatedAt = new Date().toISOString();
    if (cancelNote) {
      newTransactions[transactionIndex].cancelNote = cancelNote;
      newTransactions[transactionIndex].status = status;
    }

    await localForage.setItem('transactions', newTransactions);
  } else {
    if (transaction) {
      let payment;

      if (transaction.transactionPayment) {
        payment = payments.find(
          (payment) =>
            payment.name ===
            (transaction?.transactionPayment.paymentName as any),
        );
      }

      const newPayload = {
        id: transactionId,
        bookingId: transaction.bookingId,
        cancelNote,
        status,
        date: format(new Date(), 'yyyy-MM-dd'),
        outletId,
        stylistId: transaction.stylist ? transaction.stylist.id : '',
        payAmount: transaction.payAmount,
        totalPay: transaction.totalPay,
        paymentId: payment.id || '',
        phoneNumber: transaction.member.phoneNumber || '',
        customerName: transaction.member.name || '',
        email: transaction.member.email || '',
        serviceId: transaction.services
          ? transaction.services.map((service: any) => ({
              price: service.price,
              totalPrice: service.price,
              qty: 1,
              timeInMinutes: service.timeInMinutes,
              service: {
                id: service.id,
                name: service.name,
                imagePath: '',
                price: service.price,
                point: service.point,
                timeInMinutes: service.timeInMinutes,
              },
            }))
          : [],
        products: transaction.products
          ? transaction.products.map((product: any) => ({
              price: product.price,
              totalPrice: product.price * product.qty,
              qty: product.qty,
              product: {
                id: product.id,
                name: product.name,
                point: product.point,
                imagePath: '',
                price: product.price,
              },
            }))
          : [],
      };

      await addOfflineTransactionCache(newPayload);
    }
  }

  try {
    // client.writeFragment({
    //   id: `Transactions:${transactionId}`,
    //   fragment: gql`
    //     fragment Transactions on Transactions {
    //       status
    //     }
    //   `,
    //   data: {
    //     status,
    //   },
    // });

    client.writeFragment({
      id: `TransactionDetail:${transactionId}`,
      fragment: gql`
        fragment TransactionDetail on TransactionDetail {
          status
        }
      `,
      data: {
        status,
      },
    });

    client.writeFragment({
      id: `Bookings:${transaction.bookingId}`,
      fragment: gql`
        fragment Bookings on Bookings {
          transactionStatus
        }
      `,
      data: {
        transactionStatus: status,
      },
    });
  } catch (e) {
    const err = e as any;
    throw new Error(err);
  }
}

export const printTransaction = async function (payload: TransactionPayload) {
  const transactions: any = await localForage.getItem('transactions');
  const adminEmail = await localForage.getItem('adminEmail');
  let result: MasterData | string | null = localStorage.getItem('masterData');
  let admins: any[] = [];

  let newTransactions: any[] = [];

  if (result && result !== 'undefined') {
    result = JSON.parse(result) as MasterData;

    if (result) {
      admins = result.admins || [];
    }
  }

  const currentAdmin = admins.find((admin) => admin.email === adminEmail);

  if (transactions && transactions !== 'undefined') {
    newTransactions = JSON.parse(JSON.stringify(transactions));
  }

  let transactionIndex;

  if (newTransactions.length) {
    transactionIndex = newTransactions.findIndex(
      (transaction: any) => transaction.id === payload.id,
    );
  }

  const printlog = {
    id: uuidv4(),
    printAt: new Date().toISOString(),
    transactionId: payload.id,
    inputBy: currentAdmin.id || 0,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };

  if (transactionIndex !== undefined && transactionIndex > -1) {
    newTransactions[transactionIndex].printlogs
      ? newTransactions[transactionIndex].printlogs.push(printlog)
      : (newTransactions[transactionIndex].printlogs = printlog);

    await localForage.setItem('transactions', newTransactions);
  } else {
    const newPrintlog = [
      {
        id: uuidv4(),
        printAt: new Date().toISOString(),
        transactionId: payload.id,
        inputBy: currentAdmin.id || 0,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      },
    ];

    await addOfflineTransactionCache(payload, newPrintlog);
  }
};
