export default {
  appName: process.env.REACT_APP_APP_NAME || 'Sir Salon',
  outlet: 'Outlet',
  number: 'No',
  save: 'Simpan',
  active: 'Aktif',
  guest: 'Tamu',
  not_completed: 'Belum',
  completed: 'Selesai',
  online: 'Online',
  offline: 'Offline',
  sure: 'Yakin',
  hour: 'Jam',
  no: 'Tidak',
  username: 'Username',
  quantity: 'Jumlah',
  password: 'Kata Sandi',
  old_password: 'Kata Sandi Lama',
  password_new: 'Kata Sandi Baru',
  password_confirmation_new: 'Konfirmasi Kata Sandi Baru',
  signIn: 'Sign In',
  date: 'Tanggal',
  booking: 'Pesanan',
  service: 'Treatment',
  product: 'Produk',
  print: 'Cetak',
  total: 'Total',
  stylist: 'Stylist',
  random_stylist: 'Stylist Acak',
  stylist_name: 'Nama Stylist',
  transaction: 'Transaksi',
  daily: 'Harian',
  monthly: 'Bulanan',
  minute: 'Menit',
  check: 'Check',
  cancel: 'Batal',
  pending: 'Pending',
  confirmation: 'Konfirmasi',
  choose_time: 'Pilih Waktu',
  commission: 'Komisi',
  start_date: 'Tanggal Mulai',
  end_date: 'Tanggal Selesai',
  cashier: 'Kasir',
  normal_point: 'Poin',
  point: 'Poin: {{point}}',
  point_gained: 'Poin yang diperoleh: {{point}}',
  previous_point: 'Poin Sebelumnya: {{point}}',
  new_convertion_point: 'Konversi Poin Baru',
  total_money: 'Jumlah Uang',
  changes: 'Kembalian',
  transfer_point: 'Transfer Poin',
  stock_balance: 'Stok',
  process: 'Proses',
  verification_code_incorrect: 'Kode verifikasi salah',

  confirm_cancel: 'Konfirmasi Batal',
  cancel_reason: 'Alasan Batal',
  write_reason: 'Tuliskan Alasan',
  confirmation_change_text: 'Apakah anda yakin dengan perubahan ?',
  confirmation_booking_cancel_text:
    'Apakah anda yakin ingin membatalkan pesanan ini ?',
  confirmation_transaction_cancel_text:
    'Apakah anda yakin ingin membatalkan transaksi ini ?',

  name: 'Nama',
  phone_number: 'No Hp',
  email: 'Email',
  payment_method: 'Metode Pembayaran',
  voucher_code: 'Kode Promo',

  booking_detail: 'Detail Pesanan',
  new_booking: 'Pesanan Baru',

  new_transaction: 'Transaksi Baru',
  cancel_transaction: 'Batalkan Transaksi',

  operational_time: 'Jam Operasional',
  not_operational_time: '24 Jam',
  logout: 'Log Out',

  booking_management: 'Manajemen Pemesanan',
  transaction_management: 'Manajemen Transaksi',
  recap_transaction: 'Rekap Transaksi',
  change_password: 'Ganti Kata Sandi',
  stylist_commission: 'Komisi Stylist',

  remaining_quota: 'Sisa Kuota',

  collapse_all: 'Collapse All',
  expand_all: 'Expand All',

  please_enter_pin:
    'Silahkan masukkan kode pin dari supervisor untuk melakukan aksi ini.',
  wrong_pin: 'Pin anda salah atau sudah expired, silahkan minta pin terbaru.',
  pin: 'PIN',

  transaction_time: 'Waktu Transaksi',

  input_otp: 'Masukkan Kode OTP',
  otp_code_sent: 'Kode OTP telah dikirim ke nomor {{phoneNumber}}',

  error_text: 'Terjadi kesalahan tekan untuk memuat ulang halaman',
  reload: 'Muat Ulang',

  switch_network_text: 'Ganti ke mode {{mode}}',
  switch_to_online_text: 'Internet anda sudah berfungsi, pindah ke mode online',
  switch_to_offline_text:
    'Internet anda sedang bermasalah, pindah ke mode offline',
  check_voucher_again: 'Cek Voucher Kembali',

  show_haircut: 'Lihat Haircut',
  customer: 'Customer',

  member_code: 'Kode Member',
  use_member_code: 'Menggunakan kode member',
};
