import {
  gql,
  useMutation,
  MutationFunctionOptions,
  useApolloClient,
} from '@apollo/client';
import { Booking, BookingSheet, BookingTypeEnum } from './booking.model';
import { ApiResult } from 'repositories';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';
import { camelizeKeys } from 'humps';
import {
  addBookingSheet,
  addActiveBooking,
  addOfflineBookingCache,
  addBookingUnset,
  cancelBookingSheet,
  updateBookingCache,
  cancelBookingUnset,
  updateBookingSheet,
  updateBookingStatus,
  Changelog,
  removeActiveBooking,
  addFinishedBooking,
  removeFinishedBooking,
} from 'helpers/booking';
import { v4 as uuidv4 } from 'uuid';
import { useStateGetCurrentOutlet } from 'repositories/outlet';
import { BOOKINGS_FRAGMENT, BOOKING_UNSET_FRAGMENT } from './booking.fragment';
import { format } from 'date-fns';
import { MasterData } from 'repositories/common/common.model';
import localForage from 'localforage';
import { useTranslation } from 'react-i18next';
import { GET_TRANSACTIONS_CACHE } from 'repositories/transaction';

const POST_BOOKING = gql`
  fragment BookingInput on REST {
    phone_number: String
    date: String
    outlet_id: String
    service_id: any
    products: any
    stylist_id: String
    is_random_stylist: Int
    customer_name: String
    start_minute: Int
    is_pending: Int
  }

  mutation Booking($payload: BookingInput!) {
    booking(payload: $payload)
      @rest(
        type: "BookingPayload"
        path: "/bookings"
        method: "POST"
        bodyKey: "payload"
      ) {
      data @type(name: "Booking") {
        id
        date
        outlet {
          id
          name
          phoneNumber
          description
          address
          longitude
          latitude
          cityId
          rate
          rateCount
          outletImages @type(name: "OutletImage") {
            id
            index
            filePath
          }
        }
        stylist {
          id
          name
          email
          phoneNumber
          rate
          rateCount
          imagePath
        }
        startMinute
        timeInMinutes
        isRandomStylist
        type
        status
      }
      message
    }
  }
`;

const POST_BOOKING_STATUS = gql`
  fragment BookingStatusInput on REST {
    status: Int
    cancel_note: String
  }

  mutation BookingStatus($bookingId: String!, $payload: BookingStatusInput!) {
    bookingStatus(bookingId: $bookingId, payload: $payload)
      @rest(
        type: "BookingStatusPayload"
        path: "/bookings/{args.bookingId}"
        method: "POST"
        bodyKey: "payload"
      ) {
      data @type(name: "Booking") {
        id
        date
        outlet {
          id
          name
          phoneNumber
          description
          address
          longitude
          latitude
          cityId
          rate
          rateCount
          outletImages @type(name: "OutletImage") {
            id
            index
            filePath
          }
        }
        stylist {
          id
          name
          email
          phoneNumber
          rate
          rateCount
          imagePath
        }
        startMinute
        timeInMinutes
        isRandomStylist
        type
        status
      }
      message
    }
  }
`;

const POST_SET_BOOKING_SHEET = gql`
  fragment BookingSheetInput on REST {
    booking_id: String
    start_minute: Int
    stylist_id: String
    is_random_stylist: Boolean
  }

  mutation SetBookingSheet($payload: BookingSheetInput!) {
    bookingSheet(payload: $payload)
      @rest(
        type: "BookingSheetPayload"
        path: "/booking-sheets"
        method: "POST"
        bodyKey: "payload"
      ) {
      data @type(name: "BookingSheet") {
        date
        minute
        isOperationalHour
        bookings @type(name: "Booking") {
          id
          date
          outlet {
            id
            name
            phoneNumber
            description
            address
            longitude
            latitude
            cityId
            rate
            rateCount
            outletImages @type(name: "OutletImage") {
              id
              index
              filePath
            }
          }
          stylist {
            id
            name
            email
            phoneNumber
            rate
            rateCount
            imagePath
          }
          startMinute
          timeInMinutes
          isRandomStylist
          type
          status
        }
      }
    }
  }
`;

const POST_CANCEL_BOOKING_SHEET = gql`
  fragment CancelBookingSheetInput on REST {
    booking_id: String
  }

  mutation SetBookingSheet($payload: CanceBookingSheetInput!) {
    bookingSheet(payload: $payload)
      @rest(
        type: "BookingSheetPayload"
        path: "/booking-sheets/cancel"
        method: "POST"
        bodyKey: "payload"
      ) {
      data @type(name: "BookingSheet") {
        date
        minute
        isOperationalHour
        bookings @type(name: "Booking") {
          id
          date
          outlet {
            id
            name
            phoneNumber
            description
            address
            longitude
            latitude
            cityId
            rate
            rateCount
            outletImages @type(name: "OutletImage") {
              id
              index
              filePath
            }
          }
          stylist {
            id
            name
            email
            phoneNumber
            rate
            rateCount
            imagePath
          }
          startMinute
          timeInMinutes
          isRandomStylist
          type
          status
        }
      }
    }
  }
`;

// Didn't use @type because it will break cache since this API
// doesn't return bookings
const POST_UPDATE_BOOKING_SHEET = gql`
  fragment UpdateBookingSheetInput on REST {
    booking_id: String
    time_in_minutes: String
  }

  mutation UpdateBookingSheet($payload: UpdateBookingSheetInput!) {
    bookingSheets(payload: $payload)
      @rest(
        path: "/booking-sheets/update"
        method: "POST"
        bodyKey: "payload"
      ) {
      data {
        date
        minute
        startMinute
        endMinute
        isOperationalHour
      }
      message
    }
  }
`;

export function useMutationUpdateBookingSheet() {
  const { mode } = useNetworkStatus();
  const client = useApolloClient();

  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';

  const mutation = useMutation<
    { bookingSheets: ApiResult<BookingSheet[]> },
    { payload: { booking_id: string; time_in_minutes: string } }
  >(POST_UPDATE_BOOKING_SHEET);

  if (!mode) {
    const updateBookingSheetFunction = async function (
      options?: MutationFunctionOptions<any, Record<string, any>>,
    ) {
      if (options?.variables?.payload) {
        const payload: any = camelizeKeys(options?.variables.payload);
        let booking;

        try {
          booking = client.readFragment({
            id: `Bookings:${payload.bookingId}`,
            fragment: BOOKINGS_FRAGMENT,
          });
        } catch (e) {}

        const newPayload = {
          bookingId: payload.bookingId,
          date: format(new Date(), 'yyyy-MM-dd'),
          outletId,
          status: booking.status,
          phoneNumber: booking.member.phoneNumber || '',
          customerName: booking.member.name || '',
          email: booking.member.email || '',
          startMinute: booking.startMinute,
          type: BookingTypeEnum[booking.type],
          timeInMinutes: parseFloat(payload.timeInMinutes),
          isRandomStylist: booking.isRandomStylist,
          stylistId: booking.stylist.id,
          serviceId: booking.services
            ? booking.services.map((service: any) => ({
                service: {
                  id: service.id,
                  name: service.name,
                  price: service.price,
                  timeInMinutes: service.timeInMinutes,
                  point: service.point || 0,
                },
              }))
            : [],
          products: booking.products
            ? booking.products.map((product: any) => ({
                product: {
                  id: product.id,
                  name: product.name,
                  code: product.code || '',
                  price: product.price,
                  timeInMinutes: 0,
                  point: product.point || 0,
                },
                qty: product.qty,
              }))
            : [],
        };

        await updateBookingSheet(
          payload.bookingId,
          parseFloat(payload.timeInMinutes),
          outletId,
          client,
        );
        await updateBookingCache(newPayload);
      }

      return { data: undefined };
    };

    return [updateBookingSheetFunction];
  }

  return mutation;
}

export function useMutationCreateBooking() {
  const { mode } = useNetworkStatus();
  const client = useApolloClient();

  const mutation = useMutation<{ booking: ApiResult<Booking> }>(POST_BOOKING);

  if (!mode) {
    const createBooking = async function (
      options?: MutationFunctionOptions<any, Record<string, any>>,
    ) {
      if (options?.variables?.payload) {
        const bookingId = uuidv4();
        let payload: any = camelizeKeys(options?.variables.payload);
        const adminEmail = await localForage.getItem('adminEmail');
        let result: MasterData | string | null = localStorage.getItem(
          'masterData',
        );
        let admins: any[] = [];

        if (result && result !== 'undefined') {
          result = JSON.parse(result) as MasterData;

          if (result) {
            admins = result.admins || [];
          }
        }

        payload = {
          ...payload,
          bookingId,
          status: 1,
        };

        const currentAdmin = admins.find((admin) => admin.email === adminEmail);

        const changelogs: Changelog[] = [
          {
            id: uuidv4(),
            bookingId: payload.bookingId,
            statusFrom: 1,
            statusTo: 1,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            inputableId: currentAdmin.id || 0,
            inputableType: 'Springkraf\\AdminACL\\Models\\Admin',
          },
        ];

        try {
          if (!payload.isPending) {
            await addBookingSheet(payload, client);
          } else {
            await addBookingUnset(payload, client);
          }
          await addFinishedBooking(payload, client);
          await addActiveBooking(payload, client);
          await addOfflineBookingCache(payload, changelogs);
        } catch (e) {
          throw new Error(e as any);
        }
      }
    };

    return [createBooking];
  }

  return mutation;
}

export function useMutationChangeBookingStatus() {
  const mutation = useMutation<{ bookingStatus: ApiResult<Booking> }>(
    POST_BOOKING_STATUS,
  );

  const { mode } = useNetworkStatus();
  const client = useApolloClient();
  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';

  if (!mode) {
    const changeBookingStatusFunction = async function (
      options?: MutationFunctionOptions<any, Record<string, any>>,
    ) {
      if (options?.variables?.payload) {
        const bookingId: string = options?.variables?.bookingId;
        const payload: any = camelizeKeys(options?.variables.payload);
        let transactions: any;
        let haveTransaction = false;

        try {
          transactions = client.cache.readQuery({
            query: GET_TRANSACTIONS_CACHE,
            variables: {
              outletId,
              date: format(new Date(), 'yyyy-MM-dd'),
            },
          });
        } catch (e) {}

        if (transactions) {
          haveTransaction = !transactions.transactions.data.every(
            (cur: any) => cur.bookingId !== bookingId,
          );
        }

        await updateBookingStatus(
          bookingId,
          outletId,
          payload.status,
          client,
          payload.cancelNote,
        );
      }
    };

    return [changeBookingStatusFunction as any];
  }

  return mutation;
}

export function useMutationSetBookingSheet() {
  const { mode } = useNetworkStatus();
  const client = useApolloClient();
  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';

  let result: MasterData | string | null = localStorage.getItem('masterData');
  let stylists: any[] = [];

  if (result && result !== 'undefined') {
    result = JSON.parse(result) as MasterData;

    const outlet = result?.outlets.find(
      (outlet: any) => outlet.id === outletId,
    );

    if (outlet) {
      stylists = outlet.stylists;
    }
  }

  const mutation = useMutation<{ bookingSheet: { data: BookingSheet[] } }>(
    POST_SET_BOOKING_SHEET,
  );

  if (!mode) {
    const setBookingSheetFunction = async function (
      options?: MutationFunctionOptions<any, Record<string, any>>,
    ) {
      if (options?.variables?.payload) {
        const payload: any = camelizeKeys(options?.variables.payload);

        const currentStylist = stylists.find(
          (stylist) => stylist.id === payload.stylistId,
        );

        if (payload.bookingId) {
          let bookingUnset;

          try {
            bookingUnset = client.readFragment({
              id: `BookingUnset:${payload.bookingId}`,
              fragment: BOOKING_UNSET_FRAGMENT,
            });
          } catch (e) {}

          if (bookingUnset) {
            const newPayload = {
              bookingId: payload.bookingId,
              date: format(new Date(), 'yyyy-MM-dd'),
              outletId,
              status: bookingUnset.status,
              phoneNumber: bookingUnset.member.phoneNumber || '',
              customerName: bookingUnset.member.name || '',
              email: bookingUnset.member.email || '',
              startMinute: payload.startMinute,
              type: BookingTypeEnum[bookingUnset.type],
              timeInMinutes: bookingUnset.timeInMinutes,
              isRandomStylist: payload.isRandomStylist,
              stylistId: payload.stylistId,
              serviceId: bookingUnset.services
                ? bookingUnset.services.map((service: any) => ({
                    service: {
                      id: service.id,
                      name: service.name,
                      price: service.price,
                      timeInMinutes: service.timeInMinutes,
                      point: service.point || 0,
                    },
                  }))
                : [],
              products: bookingUnset.products
                ? bookingUnset.products.map((product: any) => ({
                    product: {
                      id: product.id,
                      name: product.name,
                      code: product.code || '',
                      price: product.price,
                      timeInMinutes: 0,
                      point: product.point || 0,
                    },
                    qty: product.qty,
                  }))
                : [],
            };

            try {
              client.writeFragment({
                id: `Booking:${payload.bookingId}`,
                fragment: gql`
                  fragment Booking on Booking {
                    stylist
                    isRandomStylist
                  }
                `,
                data: {
                  stylist: {
                    id: payload.stylistId,
                    name: currentStylist.name || '',
                  },
                  isRandomStylist: payload.isRandomStylist,
                },
              });
            } catch (e) {}

            await cancelBookingUnset(payload.bookingId, outletId, client);
            // await addActiveBooking({ ...payload, outletId }, client);
            // await addFinishedBooking({ ...payload, outletId }, client);
            await addBookingSheet(newPayload, client);
            await updateBookingCache(newPayload);
          }
        }
      }
    };

    return [setBookingSheetFunction];
  }

  return mutation;
}

export function useMutationCancelBookingSheet() {
  const { mode } = useNetworkStatus();
  const client = useApolloClient();
  const { t } = useTranslation();

  const mutation = useMutation<{ bookingSheet: { data: BookingSheet[] } }>(
    POST_CANCEL_BOOKING_SHEET,
  );

  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';

  if (!mode) {
    const cancelBookingSheetFunction = async function (
      options?: MutationFunctionOptions<any, Record<string, any>>,
    ) {
      if (options?.variables?.payload) {
        const payload: any = camelizeKeys(options?.variables.payload);

        if (payload.bookingId) {
          let booking;

          try {
            booking = client.readFragment({
              id: `Bookings:${payload.bookingId}`,
              fragment: BOOKINGS_FRAGMENT,
            });
          } catch (e) {}

          if (booking.status !== 1) {
            throw new Error(t('error.cancel_booking_sheet_error'));
          }

          await cancelBookingSheet(payload.bookingId, outletId, client);

          if (booking) {
            const newPayload = {
              bookingId: payload.bookingId,
              date: format(new Date(), 'yyyy-MM-dd'),
              outletId,
              phoneNumber: booking.member.phoneNumber || '',
              customerName: booking.member.name || '',
              email: booking.member.email || '',
              startMinute: null,
              status: booking.status,
              isRandomStylist: booking.isRandomStylist,
              type: BookingTypeEnum[booking.type],
              timeInMinutes: booking.timeInMinutes,
              stylistId: booking.stylist.id,
              serviceId: booking.services
                ? booking.services.map((service: any) => ({
                    service: {
                      id: service.id,
                      name: service.name,
                      price: service.price,
                      timeInMinutes: service.timeInMinutes,
                      point: service.point || 0,
                    },
                  }))
                : [],
              products: booking.products
                ? booking.products.map((product: any) => ({
                    product: {
                      id: product.id,
                      name: product.name,
                      code: product.code || '',
                      price: product.price,
                      timeInMinutes: 0,
                      point: product.point || 0,
                    },
                    qty: product.qty,
                  }))
                : [],
            };

            try {
              client.writeFragment({
                id: `Bookings:${payload.bookingId}`,
                fragment: gql`
                  fragment Bookings on Bookings {
                    startMinute
                  }
                `,
                data: {
                  startMinute: null,
                },
              });
            } catch (e) {}

            await addBookingUnset(newPayload, client);
            await removeFinishedBooking(payload.bookingId, outletId, client);
            await removeActiveBooking(payload.bookingId, outletId, client);
            await updateBookingCache(newPayload);
          }
        }
      }
    };

    return [cancelBookingSheetFunction];
  }

  return mutation;
}
