import * as React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from 'pages/login';
import Layout from 'pages/layout';
import {
  useIsAuthenticated,
  useStateGetLoginToken,
  useStateSetLoginToken,
} from 'repositories/auth';
import ChangePassword from 'pages/changePassword';
import BookingManagement from 'pages/bookingManagement';
import TransactionRecap from 'pages/transactionRecap';
import TransactionManagement from 'pages/transactionManagement';
import TransferPoint from 'pages/transferPoint';
import StockBalance from 'pages/stockBalance';
import StylistCommission from 'pages/stylistCommission';
import ChoiceIcon from 'assets/images/png/choices-white.png';
import ChoiceBlackIcon from 'assets/images/png/choices.png';
import TransferIcon from 'assets/images/png/transfer-white.png';
import TransferBlackIcon from 'assets/images/png/transfer.png';
import TransferPointIcon from 'assets/images/png/transfer-point-white.png';
import TransferPointBlackIcon from 'assets/images/png/transfer-point.png';
import ChecklistIcon from 'assets/images/png/checklist-white.png';
import ChecklistBlackIcon from 'assets/images/png/checklist.png';
import PasswordIcon from 'assets/images/png/password-white.png';
import PasswordBlackIcon from 'assets/images/png/password.png';
import CommissionIcon from 'assets/images/png/commission-white.png';
import CommissionBlackIcon from 'assets/images/png/commission.png';
import WarehouseIcon from 'assets/images/png/warehouse-white.png';
import WarehouseBlackIcon from 'assets/images/png/warehouse.png';
import { useNetworkStatus } from './NetworkStatusContainer';
import { useQueryGetMasterData } from 'repositories/common';
import { getAuthenticationToken } from 'utils/storage';

export interface RouteNode {
  path: string;
  name: string;
  exact?: boolean;
  icon?: any;
  needOnline?: boolean;
  activeIcon?: any;
  component: React.ComponentType<any>;
}

const routes: RouteNode[] = [
  {
    path: '/dashboard/transaction',
    component: TransactionManagement,
    name: 'common.transaction_management',
    icon: TransferIcon,
    activeIcon: TransferBlackIcon,
  },
  {
    path: '/dashboard/booking',
    component: BookingManagement,
    name: 'common.booking_management',
    icon: ChoiceIcon,
    activeIcon: ChoiceBlackIcon,
  },
  {
    path: '/dashboard/recap-transaction',
    component: TransactionRecap,
    name: 'common.recap_transaction',
    icon: ChecklistIcon,
    activeIcon: ChecklistBlackIcon,
    needOnline: true,
  },
  {
    path: '/dashboard/stylist-commission',
    component: StylistCommission,
    name: 'common.stylist_commission',
    icon: CommissionIcon,
    activeIcon: CommissionBlackIcon,
    needOnline: true,
  },
  {
    path: '/dashboard/change-password',
    component: ChangePassword,
    name: 'common.change_password',
    icon: PasswordIcon,
    activeIcon: PasswordBlackIcon,
    needOnline: true,
  },
  {
    path: '/dashboard/transfer/point',
    component: TransferPoint,
    name: 'common.transfer_point',
    icon: TransferPointIcon,
    activeIcon: TransferPointBlackIcon,
    needOnline: true,
  },
  {
    path: '/dashboard/stock-balance',
    component: StockBalance,
    name: 'common.stock_balance',
    icon: WarehouseIcon,
    activeIcon: WarehouseBlackIcon,
    needOnline: true,
  },
];

export default function Router() {
  const { data } = useStateGetLoginToken();
  const rawIsAuthenticated = useIsAuthenticated();
  const [setLoginToken] = useStateSetLoginToken();
  const { mode } = useNetworkStatus();
  const [loadMasterData, masterData] = useQueryGetMasterData();
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(
    null,
  );

  React.useEffect(() => {
    (async () => {
      const prevToken = await getAuthenticationToken();
      if (!rawIsAuthenticated && data?.loginToken === null && !!prevToken) {
        setLoginToken(prevToken);
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(rawIsAuthenticated);
      }
    })();
  }, [data, rawIsAuthenticated]);

  React.useEffect(() => {
    if (isAuthenticated && mode) {
      loadMasterData();
    }
  }, [isAuthenticated, loadMasterData, mode]);

  React.useEffect(() => {
    if (masterData.data) {
      localStorage.setItem(
        'masterData',
        JSON.stringify(masterData.data.getMasterData),
      );
    }
  }, [masterData.data]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route
          path="/login"
          element={
            isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login />
          }
        />
        <Route
          path="/dashboard"
          element={
            isAuthenticated ? (
              <Layout routeNodes={routes} />
            ) : (
              <Navigate to="/login" replace />
            )
          }
          children={
            <>
              <Route
                path="/dashboard"
                element={<Navigate to="/dashboard/transaction" replace />}
              />
              {routes.map(({ component, path, exact, needOnline }) => {
                if (needOnline && !mode) {
                  return null;
                }

                return (
                  <Route
                    key={path}
                    Component={component}
                    path={path}
                    {...(isAuthenticated
                      ? {}
                      : { element: <Navigate to="/login" replace /> })}
                  />
                );
              })}
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
