import { ApolloClient } from '@apollo/client';
import { setAuthenticationToken, setOutletALiasKey } from 'utils/storage';

export * from './auth.model';
export * from './auth.query';
export * from './auth.mutation';

export async function logout(
  apolloClient: ApolloClient<any>,
  clearCache?: boolean,
) {
  await setAuthenticationToken(undefined);
  clearCache && (await setOutletALiasKey(undefined));
  clearCache && (await apolloClient.clearStore());
}
