import * as React from 'react';
import TextInput, { Props as ITextInputProps } from './TextInput';
import DateTimePicker, {
  DateTimePickerProps as IDatePickerProps,
} from './DateTimePicker';
import SimpleSelect, {
  SimpleSelectProps as ISelectProps,
} from './SimpleSelect';
import { useField } from 'formik';
import FormField from './FormField';
import Switch, { SwitchProps } from './Switch';

interface FieldProps {
  name: string;
}

interface TextInputProps extends FieldProps, Omit<ITextInputProps, 'name'> {
  type: 'text' | 'password' | 'email' | 'number';
}

interface SelectInputProps<T>
  extends FieldProps,
    Omit<
      ISelectProps<T>,
      'isLoading' | 'isDisabled' | 'onBlur' | 'isSearchable' | 'value'
    > {
  type: 'select';
  onAfterChange?: (val: any) => void;
  isDisabled?: boolean;
  onBlur?: unknown;
  isLoading?: boolean;
  isSearchable?: boolean;
  value?: any;
}

interface DatePickerProps
  extends FieldProps,
    Omit<IDatePickerProps, 'name' | 'onChange' | 'value'> {
  value?: Date;
  type: 'datetime' | 'date' | 'time';
  placeholder?: string;
}

function SelectField<T>(props: SelectInputProps<T>) {
  const { onAfterChange, ...rest } = props;
  const [fields, meta, helpers] = useField(props.name);

  const { onChange, onBlur, ...selectRestFields } = fields;

  const _onChange = React.useCallback(
    (value) => {
      helpers.setValue(value || null);
    },
    [helpers],
  );

  const onMenuOpen = React.useCallback(() => {
    helpers.setTouched(true);
  }, [helpers]);

  return (
    <FormField errorText={meta.touched && meta.error}>
      {/**@ts-ignore */}
      <SimpleSelect
        {...selectRestFields}
        {...rest}
        onMenuOpen={onMenuOpen}
        isSearchable={false}
        onChange={_onChange}
        onAfterChange={onAfterChange}
      />
    </FormField>
  );
}

function TextField(props: TextInputProps) {
  const [fields, meta] = useField(props.name);
  const { type, ...restProps } = props;

  return (
    <FormField errorText={meta.touched && meta.error}>
      <TextInput type={type} {...fields} {...restProps} />
    </FormField>
  );
}

function DateTimeField(props: DatePickerProps) {
  const [fields, meta, helpers] = useField(props.name);

  const {
    value: fieldsValue,
    onChange: dateTimeOnChange,
    ...dateTimeRestFields
  } = fields;
  const { value: propsValue, ...restProps } = props;
  return (
    <FormField errorText={meta.touched && meta.error}>
      <DateTimePicker
        showTimeSelectOnly={props.type === 'time'}
        showTimeSelect={['datetime', 'time'].includes(props.type)}
        placeholderText={props.placeholder}
        selected={fieldsValue || propsValue}
        onChange={helpers.setValue as any}
        dateFormat="dd MMMM yyyy HH:mm"
        timeIntervals={1}
        {...dateTimeRestFields}
        {...restProps}
      />
    </FormField>
  );
}

function SwitchField(props: SwitchProps) {
  const [fields] = useField(props.name);

  return (
    <FormField>
      <Switch {...props} {...fields} defaultChecked={fields.value} />
    </FormField>
  );
}

export default function Field<T>(
  props: TextInputProps | SelectInputProps<T> | DatePickerProps | SwitchProps,
) {
  switch (props.type) {
    case 'text':
    case 'password':
    case 'number':
    case 'email':
      return <TextField {...props} />;
    case 'select':
      return <SelectField {...props} />;
    case 'datetime':
    case 'date':
    case 'time':
      return <DateTimeField {...props} />;
    case 'switch':
      return <SwitchField {...props} />;
  }
}
