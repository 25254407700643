export default {
  management: 'Manajemen',
  choice: 'Pilihan',
  ongoing: 'Sedang Berjalan',
  not_processed: 'Belum Diproses',
  name: 'Nama',
  status: 'Status',
  source: 'Sumber',
  service_time: 'Waktu Treatment',
  stylish: 'Stylish',
  booking: 'Pesanan',
  payment: 'Pembayaran',
  time: 'Waktu',
  active_booking: 'Pesanan Aktif',
  input_by: 'Input By',
  update_by: 'Update By',
  input_time: 'Waktu Input',
  booking_log: 'Booking Log',
  transaction_status: 'Status Transaksi',
};
