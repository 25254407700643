import * as React from 'react';
import styled, { css } from 'styled-components';

const CountrySingleValue = ({
  cx,
  getStyles,
  selectProps,
  data,
  isDisabled,
  className,
  ...props
}: any) => {
  return (
    <Container
      className={cx(
        css(getStyles('singleValue', props)),
        {
          'single-value': true,
          'single-value--is-disabled': isDisabled,
        },
        className,
      )}
    >
      <Text>{data.extra.phoneNumber}</Text>
    </Container>
  );
};

const Container = styled.div`
  width: 90%;
  height: 100%;
`;

const Text = styled.p`
  font-size: 16px;
`;

export default CountrySingleValue;
