import {
  GET_BOOKING_SHEETS_CACHE,
  GET_BOOKINGS_CACHE,
  GET_BOOKING_UNSETS_CACHE,
} from 'repositories/booking/booking.query';
import { ApolloClient, gql } from '@apollo/client';
import { format } from 'date-fns';
import { MasterData } from 'repositories/common/common.model';
import {
  BookingTypeEnum,
  BookableTypeEnum,
  BookingStatusTypeEnum,
} from 'repositories/booking/booking.model';
import localForage from 'localforage';
import {
  TransactionService,
  TransactionProduct,
} from 'repositories/transaction';
import { v4 as uuidv4 } from 'uuid';
import { BOOKINGS_FRAGMENT } from 'repositories/booking/booking.fragment';

interface BookingPayload {
  date: string;
  outletId: string;
  bookingId: string;
  status: number;
  serviceId: any[];
  products: any[];
  isRandomStylist: boolean;
  stylistId: string;
  phoneNumber: string;
  customerName: string;
  email: string;
  cancelNote?: string;
  timeInMinutes: number;
  startMinute?: number | null;
  type: any;
}

export interface Changelog {
  id: string;
  bookingId: string;
  statusFrom: number;
  statusTo: number;
  createdAt: string;
  updatedAt: string;
  inputableType: string;
  inputableId: number;
}

export async function addBookingSheet(
  payload: BookingPayload,
  client: ApolloClient<any>,
) {
  let bookingSheets: any;
  let result: MasterData | string | null = localStorage.getItem('masterData');
  let stylists: any[] = [];

  if (result && result !== 'undefined') {
    result = JSON.parse(result) as MasterData;

    const outlet = result?.outlets.find(
      (outlet: any) => outlet.id === payload.outletId,
    );

    if (outlet) {
      stylists = outlet.stylists;
    }
  }

  try {
    bookingSheets = client.cache.readQuery({
      query: GET_BOOKING_SHEETS_CACHE,
      variables: {
        outletId: payload.outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
      },
    });
  } catch (e) {}

  let newBookingSheet: any[] = [];
  const currentStylist = stylists.find(
    (stylist) => stylist.id === payload.stylistId,
  );

  if (bookingSheets) {
    newBookingSheet = JSON.parse(
      JSON.stringify(bookingSheets.bookingSheets.data),
    );
  } else {
    for (let i = 0; i < 145; i++) {
      newBookingSheet.push({
        __typename: 'BookingSheets',
        date: format(new Date(), 'yyyy-MM-dd'),
        minute: i * 10,
        startMinute: i * 10,
        endMinute: i * 10 + 10,
        isOperationalHour: true,
        bookings: [],
      });
    }
  }

  const index = newBookingSheet.findIndex(
    (cur) => cur.startMinute === payload.startMinute,
  );

  if (!index && index !== 0) {
    throw new Error('');
  } else {
    for (let i = 0; i < payload.timeInMinutes / 10; i++) {
      if (newBookingSheet[index + i]) {
        newBookingSheet[index + i].bookings.push({
          __typename: 'Bookings',
          id: payload.bookingId,
          createdAt: new Date().toISOString(),
          isRandomStylist: payload.isRandomStylist,
          member: {
            id: uuidv4(),
            phoneNumber: payload.phoneNumber || null,
            name: payload.customerName,
            birthDate: null,
            email: null,
            address: null,
            point: null,
          },
          services: payload.serviceId
            ? payload.serviceId.map((service: any) => ({
                id: service.service.id,
                name: service.service.name,
                description: '',
                price: service.service.price,
                timeInMinutes: service.service.timeInMinutes,
                point: service.service.point || 0,
              }))
            : [],
          products: payload.products
            ? payload.products.map((product: any) => ({
                id: product.product.id,
                name: product.product.name,
                description: '',
                code: product.product.code || '',
                price: product.product.price,
                timeInMinutes: 0,
                qty: product.qty,
                point: product.product.point || 0,
              }))
            : [],
          status: payload.status,
          startMinute: payload.startMinute,
          type: BookingTypeEnum[payload.type],
          timeInMinutes: payload.timeInMinutes,
          stylist: {
            id: currentStylist ? currentStylist.id : null,
            name: currentStylist ? currentStylist.name : null,
          },
        });
      }
    }

    try {
      client.cache.writeQuery({
        query: GET_BOOKING_SHEETS_CACHE,
        data: {
          __typename: 'BookingSheetPayload',
          bookingSheets: {
            data: newBookingSheet,
          },
        },
        variables: {
          outletId: payload.outletId,
          date: format(new Date(), 'yyyy-MM-dd'),
        },
      });
    } catch (e) {
      const err = e as any;
      throw new Error(err);
    }
  }
}

export async function updateBookingSheet(
  bookingId: string,
  timeInMinutes: number,
  outletId: string,
  client: ApolloClient<any>,
) {
  let bookingSheets: any;

  try {
    bookingSheets = client.cache.readQuery({
      query: GET_BOOKING_SHEETS_CACHE,
      variables: {
        outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
      },
    });
  } catch (e) {}

  let newBookingSheet: any[] = [];

  if (bookingSheets) {
    newBookingSheet = JSON.parse(
      JSON.stringify(bookingSheets.bookingSheets.data),
    );

    let booking: any;

    for (let i = 0; i < 145; i++) {
      if (newBookingSheet[i].bookings.length) {
        // eslint-disable-next-line no-loop-func
        newBookingSheet[i].bookings.forEach((currentBooking: any) => {
          if (currentBooking.id === bookingId) {
            booking = currentBooking;
          }
        });

        if (booking) {
          break;
        }
      }
    }

    if (booking) {
      if (booking.timeInMinutes < timeInMinutes) {
        for (
          let i = booking.startMinute / 10 + booking.timeInMinutes / 10;
          i < booking.startMinute / 10 + timeInMinutes / 10;
          i++
        ) {
          if (newBookingSheet[i]) {
            newBookingSheet[i].bookings.push(booking);
          }
        }
      } else {
        for (
          let i = booking.startMinute / 10 + booking.timeInMinutes / 10 - 1;
          i > booking.startMinute / 10 + timeInMinutes / 10 - 1;
          i--
        ) {
          if (newBookingSheet[i]) {
            newBookingSheet[i].bookings = newBookingSheet[i].bookings.filter(
              // eslint-disable-next-line no-loop-func
              (cur: any) => cur.id !== bookingId,
            );
          }
        }
      }

      try {
        client.cache.writeQuery({
          query: GET_BOOKING_SHEETS_CACHE,
          data: {
            __typename: 'BookingSheetPayload',
            bookingSheets: {
              data: newBookingSheet,
            },
          },
          variables: {
            outletId,
            date: format(new Date(), 'yyyy-MM-dd'),
          },
        });

        client.writeFragment({
          id: `Bookings:${bookingId}`,
          fragment: gql`
            fragment Bookings on Bookings {
              timeInMinutes
            }
          `,
          data: {
            timeInMinutes,
          },
        });

        client.writeFragment({
          id: `Booking:${bookingId}`,
          fragment: gql`
            fragment Booking on Booking {
              timeInMinutes
            }
          `,
          data: {
            timeInMinutes,
          },
        });
      } catch (e) {
        const err = e as any;
        throw new Error(err);
      }
    }
  }
}

export async function addActiveBooking(
  payload: BookingPayload,
  client: ApolloClient<any>,
) {
  let activeBookings: any;
  let result: MasterData | string | null = localStorage.getItem('masterData');
  let stylists: any[] = [];

  if (result && result !== 'undefined') {
    result = JSON.parse(result) as MasterData;

    const outlet = result?.outlets.find(
      (outlet: any) => outlet.id === payload.outletId,
    );

    if (outlet) {
      stylists = outlet.stylists;
    }
  }

  try {
    activeBookings = client.cache.readQuery({
      query: GET_BOOKINGS_CACHE,
      variables: {
        outletId: payload.outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
        statuses: '1,2',
        types: '',
      },
    });
  } catch (e) {}

  let newActiveBookings: any[] = [];
  const currentStylist = stylists.find(
    (stylist) => stylist.id === payload.stylistId,
  );

  if (activeBookings) {
    newActiveBookings = JSON.parse(
      JSON.stringify(activeBookings.bookings.data),
    );
  }

  newActiveBookings = [
    ...newActiveBookings,
    {
      __typename: 'Bookings',
      id: payload.bookingId,
      createdAt: new Date().toISOString(),
      member: {
        id: uuidv4(),
        phoneNumber: payload.phoneNumber || null,
        name: payload.customerName,
        email: payload.email || null,
        birthDate: null,
        address: null,
        point: null,
      },
      services:
        payload?.serviceId?.map((service) => ({
          id: service.service.id,
          name: service.service.name,
          description: service?.service?.description || '',
          price: service.service.price,
          point: service.service.point,
          timeInMinutes: service.service.timeInMinutes,
        })) || [],
      products:
        payload?.products?.map((product) => ({
          id: product.product.id,
          name: product.product.name,
          description: product?.product?.description || '',
          price: product.product.price,
          point: product.product.point,
          timeInMinutes: product.product.timeInMinutes,
        })) || [],
      transactionStatus: null,
      bookingChangeLogs: '',
      status: payload.status,
      startMinute: payload.startMinute || null,
      type: BookingTypeEnum[payload.type],
      timeInMinutes: payload.timeInMinutes,
      stylist: {
        id: currentStylist ? currentStylist.id : null,
        name: currentStylist ? currentStylist.name : null,
      },
    },
  ];

  try {
    client.cache.writeQuery({
      query: GET_BOOKINGS_CACHE,
      data: {
        bookings: {
          __typename: 'BookingsPayload',
          data: newActiveBookings,
        },
      },
      variables: {
        outletId: payload.outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
        statuses: '1,2',
        types: '',
      },
    });
  } catch (e) {
    const err = e as any;
    throw new Error(err);
  }
}

export async function removeActiveBooking(
  bookingId: string,
  outletId: string,
  client: ApolloClient<any>,
) {
  let activeBookings: any;

  try {
    activeBookings = client.cache.readQuery({
      query: GET_BOOKINGS_CACHE,
      variables: {
        outletId: outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
        statuses: '1,2',
        types: '',
      },
    });
  } catch (e) {}

  let newActiveBookings: any[] = [];

  if (activeBookings) {
    newActiveBookings = JSON.parse(
      JSON.stringify(activeBookings.bookings.data),
    );

    newActiveBookings = newActiveBookings.filter(
      (booking) => booking.id !== bookingId,
    );

    try {
      client.cache.writeQuery({
        query: GET_BOOKINGS_CACHE,
        data: {
          bookings: {
            data: newActiveBookings,
          },
        },
        variables: {
          outletId: outletId,
          date: format(new Date(), 'yyyy-MM-dd'),
          statuses: '1,2',
          types: '',
        },
      });
    } catch (e) {
      const err = e as any;
      throw new Error(err);
    }
  }
}

export async function addCompletedBooking(
  payload: BookingPayload,
  client: ApolloClient<any>,
) {
  let completedBookings: any;
  let result: MasterData | string | null = localStorage.getItem('masterData');
  let stylists: any[] = [];

  if (result && result !== 'undefined') {
    result = JSON.parse(result) as MasterData;

    const outlet = result?.outlets.find(
      (outlet: any) => outlet.id === payload.outletId,
    );

    if (outlet) {
      stylists = outlet.stylists;
    }
  }

  try {
    completedBookings = client.cache.readQuery({
      query: GET_BOOKINGS_CACHE,
      variables: {
        outletId: payload.outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
        statuses: '3,9',
        types: '',
        set: 0,
      },
    });
  } catch (e) {}

  let newCompletedBookings: any[] = [];
  const currentStylist = stylists.find(
    (stylist) => stylist.id === payload.stylistId,
  );

  if (completedBookings) {
    newCompletedBookings = JSON.parse(
      JSON.stringify(completedBookings.bookings.data),
    );
  }

  newCompletedBookings = [
    ...newCompletedBookings,
    {
      __typename: 'Bookings',
      id: payload.bookingId,
      createdAt: new Date().toISOString(),
      member: {
        id: uuidv4(),
        phoneNumber: payload.phoneNumber || null,
        name: payload.customerName,
        email: payload.email || null,
        birthDate: null,
        address: null,
        point: null,
      },
      services:
        payload?.serviceId?.map((service) => ({
          id: service.service.id,
          name: service.service.name,
          description: service?.service?.description || '',
          price: service.service.price,
          point: service.service.point,
          timeInMinutes: service.service.timeInMinutes,
        })) || [],
      products:
        payload?.products?.map((product) => ({
          id: product.product.id,
          name: product.product.name,
          description: product?.product?.description || '',
          price: product.product.price,
          point: product.product.point,
          timeInMinutes: product.product.timeInMinutes,
        })) || [],
      bookingChangeLogs: '',

      status: payload.status,
      startMinute: payload.startMinute || undefined,
      type: BookingTypeEnum[payload.type],
      timeInMinutes: payload.timeInMinutes,
      stylist: {
        id: currentStylist ? currentStylist.id : null,
        name: currentStylist ? currentStylist.name : null,
      },
    },
  ];

  try {
    client.cache.writeQuery({
      query: GET_BOOKINGS_CACHE,
      data: {
        bookings: {
          data: newCompletedBookings,
        },
      },
      variables: {
        outletId: payload.outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
        statuses: '3,9',
        set: 0,
        types: '',
      },
    });
  } catch (e) {
    const err = e as any;
    throw new Error(err);
  }
}

export async function removeCompletedBooking(
  bookingId: string,
  outletId: string,
  client: ApolloClient<any>,
) {
  let completedBookings: any;

  try {
    completedBookings = client.cache.readQuery({
      query: GET_BOOKINGS_CACHE,
      variables: {
        outletId: outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
        statuses: '3,9',
        types: '',
        set: 0,
      },
    });
  } catch (e) {}

  let newCompletedBookings: any[] = [];

  if (completedBookings) {
    newCompletedBookings = JSON.parse(
      JSON.stringify(completedBookings.bookings.data),
    );

    newCompletedBookings = newCompletedBookings.filter(
      (booking) => booking.id !== bookingId,
    );

    try {
      client.cache.writeQuery({
        query: GET_BOOKINGS_CACHE,
        data: {
          bookings: {
            data: newCompletedBookings,
          },
        },
        variables: {
          outletId: outletId,
          date: format(new Date(), 'yyyy-MM-dd'),
          statuses: '3,9',
          types: '',
          set: 0,
        },
      });
    } catch (e) {
      const err = e as any;
      throw new Error(err);
    }
  }
}

export async function addFinishedBooking(
  payload: BookingPayload,
  client: ApolloClient<any>,
) {
  let finishedBookings: any;
  let result: MasterData | string | null = localStorage.getItem('masterData');
  let stylists: any[] = [];

  if (result && result !== 'undefined') {
    result = JSON.parse(result) as MasterData;

    const outlet = result?.outlets.find(
      (outlet: any) => outlet.id === payload.outletId,
    );

    if (outlet) {
      stylists = outlet.stylists;
    }
  }

  try {
    finishedBookings = client.cache.readQuery({
      query: GET_BOOKINGS_CACHE,
      variables: {
        outletId: payload.outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
        statuses: '1,2,3',
        withoutTransaction: 1,
        types: '',
      },
    });
  } catch (e) {}

  let newFinishedBookings: any[] = [];
  const currentStylist = stylists.find(
    (stylist) => stylist.id === payload.stylistId,
  );

  if (finishedBookings) {
    newFinishedBookings = JSON.parse(
      JSON.stringify(finishedBookings.bookings.data),
    );
  }

  newFinishedBookings = [
    ...newFinishedBookings,
    {
      __typename: 'Bookings',
      id: payload.bookingId,
      createdAt: new Date().toISOString(),
      member: {
        id: uuidv4(),
        phoneNumber: payload.phoneNumber || null,
        name: payload.customerName,
        email: payload.email || null,
        birthDate: null,
        address: null,
        point: null,
      },
      services:
        payload?.serviceId?.map((service) => ({
          id: service.service.id,
          name: service.service.name,
          description: service?.service?.description || '',
          price: service.service.price,
          point: service.service.point,
          timeInMinutes: service.service.timeInMinutes,
        })) || [],
      products:
        payload?.products?.map((product) => ({
          id: product.product.id,
          name: product.product.name,
          description: product?.product?.description || '',
          price: product.product.price,
          point: product.product.point,
          timeInMinutes: product.product.timeInMinutes,
        })) || [],
      bookingChangeLogs: '',
      status: payload.status,
      startMinute: payload.startMinute || null,
      type: BookingTypeEnum[payload.type],
      timeInMinutes: payload.timeInMinutes,
      stylist: {
        id: currentStylist ? currentStylist.id : null,
        name: currentStylist ? currentStylist.name : null,
      },
    },
  ];

  try {
    client.cache.writeQuery({
      query: GET_BOOKINGS_CACHE,
      data: {
        bookings: {
          data: newFinishedBookings,
        },
      },
      variables: {
        outletId: payload.outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
        statuses: '1,2,3',
        withoutTransaction: 1,
        types: '',
      },
    });
  } catch (e) {
    const err = e as any;
    throw new Error(err);
  }
}

export async function removeFinishedBooking(
  bookingId: string,
  outletId: string,
  client: ApolloClient<any>,
) {
  let finishedBookings: any;

  try {
    finishedBookings = client.cache.readQuery({
      query: GET_BOOKINGS_CACHE,
      variables: {
        outletId: outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
        statuses: '1,2,3',
        withoutTransaction: 1,
        types: '',
      },
    });
  } catch (e) {}

  let newFinishedBookings: any[] = [];

  if (finishedBookings) {
    newFinishedBookings = JSON.parse(
      JSON.stringify(finishedBookings.bookings.data),
    );

    newFinishedBookings = newFinishedBookings.filter(
      (booking) => booking.id !== bookingId,
    );

    try {
      client.cache.writeQuery({
        query: GET_BOOKINGS_CACHE,
        data: {
          bookings: {
            data: newFinishedBookings,
          },
        },
        variables: {
          outletId: outletId,
          date: format(new Date(), 'yyyy-MM-dd'),
          statuses: '1,2,3',
          withoutTransaction: 1,
          types: '',
        },
      });
    } catch (e) {
      const err = e as any;
      throw new Error(err);
    }
  }
}

export async function addBookingUnset(
  payload: BookingPayload,
  client: ApolloClient<any>,
) {
  let bookingUnsets: any;
  let result: MasterData | string | null = localStorage.getItem('masterData');
  let stylists: any[] = [];

  if (result && result !== 'undefined') {
    result = JSON.parse(result) as MasterData;

    const outlet = result?.outlets.find(
      (outlet: any) => outlet.id === payload.outletId,
    );

    if (outlet) {
      stylists = outlet.stylists;
    }
  }

  try {
    bookingUnsets = client.cache.readQuery({
      query: GET_BOOKING_UNSETS_CACHE,
      variables: {
        outletId: payload.outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
      },
    });
  } catch (e) {}

  let newBookingUnsets: any[] = [];
  const currentStylist = stylists.find(
    (stylist) => stylist.id === payload.stylistId,
  );

  if (bookingUnsets) {
    newBookingUnsets = JSON.parse(
      JSON.stringify(bookingUnsets.bookingUnset.data),
    );
  }

  newBookingUnsets.push({
    id: payload.bookingId,
    createdAt: new Date().toISOString(),
    member: {
      id: uuidv4(),
      phoneNumber: payload.phoneNumber || null,
      name: payload.customerName,
      email: payload.email || null,
      birthDate: null,
      address: null,
      point: null,
    },
    services: payload.serviceId
      ? payload.serviceId.map((service: any) => ({
          id: service.service.id,
          name: service.service.name,
          description: '',
          price: service.service.price,
          timeInMinutes: service.service.timeInMinutes,
          point: service.service.point || 0,
        }))
      : [],
    products: payload.products
      ? payload.products.map((product: any) => ({
          id: product.product.id,
          name: product.product.name,
          description: '',
          code: product.product.code || '',
          price: product.product.price,
          timeInMinutes: 0,
          qty: product.qty,
          point: product.product.point || 0,
        }))
      : [],
    isRandomStylist: payload.isRandomStylist,
    status: payload.status,
    startMinute: null,
    type: BookingTypeEnum[payload.type],
    timeInMinutes: payload.timeInMinutes,
    stylist: {
      id: currentStylist ? currentStylist.id : null,
      name: currentStylist ? currentStylist.name : null,
    },
    __typename: 'BookingUnset',
  });

  try {
    client.cache.writeQuery({
      query: GET_BOOKING_UNSETS_CACHE,
      data: {
        bookingUnset: {
          data: newBookingUnsets,
        },
      },
      variables: {
        outletId: payload.outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
      },
    });
  } catch (e) {
    const err = e as any;
    throw new Error(err);
  }
}

export async function addOfflineBookingCache(
  payload: BookingPayload,
  initialChangelog?: Changelog[],
) {
  const bookings: any = await localForage.getItem('bookings');
  let newBookings: any[] = [];

  if (bookings && bookings !== 'undefined') {
    newBookings = bookings;
  }

  const details: any[] = [];

  if (payload.serviceId) {
    payload.serviceId.forEach((service: TransactionService) => {
      details.push({
        id: uuidv4(),
        bookingId: payload.bookingId,
        bookableId: service.service.id,
        bookableType: BookableTypeEnum[0],
        price: service.service.price,
        point: service.service.point,
        timeInMinutes: service.service.timeInMinutes,
        qty: 0,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      });
    });
  }

  if (payload.products) {
    payload.products.forEach((product: TransactionProduct) => {
      details.push({
        id: uuidv4(),
        bookingId: payload.bookingId,
        bookableId: product.product.id,
        bookableType: BookableTypeEnum[1],
        price: product.product.price,
        point: product.product.point,
        timeInMinutes: 0,
        qty: product.qty,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      });
    });
  }

  newBookings.push({
    id: payload.bookingId,
    ...(payload.phoneNumber
      ? {
          phoneNumber: payload.phoneNumber,
        }
      : {}),
    cancelNote: payload.cancelNote!,
    customerName: payload.customerName,
    email: payload.email,
    date: format(new Date(), 'yyyy-MM-dd'),
    outletId: payload.outletId,
    isRandomStylist: payload.isRandomStylist,
    stylistId: payload.stylistId,
    startedAt: payload.startMinute,
    endedAt:
      !payload.startMinute && payload.startMinute !== 0
        ? null
        : payload.startMinute + payload.timeInMinutes,
    timeInMinutes: payload.timeInMinutes,
    type: payload.type,
    status: payload.status,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    details,
    changelogs: initialChangelog ? initialChangelog : [],
  });

  await localForage.setItem('bookings', newBookings);
}

export async function cancelBookingSheet(
  bookingId: string,
  outletId: string,
  client: ApolloClient<any>,
) {
  let bookingSheets: any;

  try {
    bookingSheets = client.cache.readQuery({
      query: GET_BOOKING_SHEETS_CACHE,
      variables: {
        outletId: outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
      },
    });
  } catch (e) {}

  let newBookingSheet: any[] = [];

  if (bookingSheets) {
    newBookingSheet = JSON.parse(
      JSON.stringify(bookingSheets.bookingSheets.data),
    );
  } else {
    for (let i = 0; i < 145; i++) {
      newBookingSheet.push({
        __typename: 'BookingSheets',
        date: format(new Date(), 'yyyy-MM-dd'),
        minute: i * 10,
        startMinute: i * 10,
        endMinute: i * 10 + 10,
        isOperationalHour: true,
        bookings: [],
      });
    }
  }

  let startIndex = 999;
  let endIndex = -1;

  for (let i = 0; i < 145; i++) {
    if (newBookingSheet[i].bookings.length) {
      // eslint-disable-next-line no-loop-func
      newBookingSheet[i].bookings.forEach((booking: any) => {
        if (booking.id === bookingId) {
          startIndex = Math.min(i, startIndex);
          endIndex = Math.max(i, endIndex);
        }
      });
    }
  }

  if ((!startIndex && startIndex !== 0) || startIndex > 145) {
    throw new Error('');
  } else {
    for (let i = startIndex; i < endIndex + 1; i++) {
      if (newBookingSheet[i]) {
        newBookingSheet[i].bookings = newBookingSheet[i].bookings.filter(
          (booking: any) => booking.id !== bookingId,
        );
      }
    }

    try {
      client.cache.writeQuery({
        query: GET_BOOKING_SHEETS_CACHE,
        data: {
          __typename: 'BookingSheetPayload',
          bookingSheets: {
            data: newBookingSheet,
          },
        },
        variables: {
          outletId: outletId,
          date: format(new Date(), 'yyyy-MM-dd'),
        },
      });
    } catch (e) {
      const err = e as any;
      throw new Error(err);
    }
  }
}

export async function cancelBookingUnset(
  bookingId: string,
  outletId: string,
  client: ApolloClient<any>,
) {
  let bookingUnsets: any;

  try {
    bookingUnsets = client.cache.readQuery({
      query: GET_BOOKING_UNSETS_CACHE,
      variables: {
        outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
      },
    });
  } catch (e) {}

  let newBookingUnsets: any[] = [];

  if (bookingUnsets) {
    newBookingUnsets = JSON.parse(
      JSON.stringify(bookingUnsets.bookingUnset.data),
    );
  }

  newBookingUnsets = newBookingUnsets.filter(
    (bookingUnset) => bookingUnset.id !== bookingId,
  );

  try {
    client.cache.writeQuery({
      query: GET_BOOKING_UNSETS_CACHE,
      data: {
        bookingUnset: {
          data: newBookingUnsets,
        },
      },
      variables: {
        outletId,
        date: format(new Date(), 'yyyy-MM-dd'),
      },
    });
  } catch (e) {
    const err = e as any;
    throw new Error(err);
  }
}

export async function updateBookingCache(payload: BookingPayload) {
  const bookings: any = await localForage.getItem('bookings');

  let newBookings: any[] = [];

  if (bookings && bookings !== 'undefined') {
    newBookings = JSON.parse(JSON.stringify(bookings));
  }

  let bookingIndex;

  if (newBookings.length) {
    bookingIndex = newBookings.findIndex(
      (booking: any) => booking.id === payload.bookingId,
    );
  }

  if (bookingIndex !== undefined && bookingIndex > -1) {
    newBookings[bookingIndex].timeInMinutes = payload.timeInMinutes;
    newBookings[bookingIndex].startedAt = payload.startMinute;
    newBookings[bookingIndex].endedAt =
      payload.startMinute! + payload.timeInMinutes;
    newBookings[bookingIndex].stylistId = payload.stylistId;
    newBookings[bookingIndex].updatedAt = new Date().toISOString();
    newBookings[bookingIndex].isRandomStylist = payload.isRandomStylist;

    await localForage.setItem('bookings', newBookings);
  } else {
    await addOfflineBookingCache(payload);
  }
}

export async function updateBookingCacheStatus(
  bookingId: string,
  outletId: string,
  status: number,
  client: ApolloClient<any>,
  cancelNote?: string,
) {
  const adminEmail = await localForage.getItem('adminEmail');
  const bookings: any = await localForage.getItem('bookings');
  let result: MasterData | string | null = localStorage.getItem('masterData');
  let admins: any[] = [];

  if (result && result !== 'undefined') {
    result = JSON.parse(result) as MasterData;

    if (result) {
      admins = result.admins || [];
    }
  }

  let newBookings: any[] = [];
  let booking;
  const currentAdmin = admins.find((admin) => admin.email === adminEmail);

  if (bookings && bookings !== 'undefined') {
    newBookings = JSON.parse(JSON.stringify(bookings));
  }

  let bookingIndex;

  if (newBookings.length) {
    bookingIndex = newBookings.findIndex(
      (booking: any) => booking.id === bookingId,
    );
  }

  try {
    booking = client.readFragment({
      id: `Bookings:${bookingId}`,
      fragment: BOOKINGS_FRAGMENT,
    });
  } catch (e) {}

  if (bookingIndex !== undefined && bookingIndex > -1) {
    newBookings[bookingIndex].status = status;
    newBookings[bookingIndex].updatedAt = new Date().toISOString();
    if (cancelNote) {
      newBookings[bookingIndex].cancelNote = cancelNote;
    }

    const changelogs: Changelog = {
      id: uuidv4(),
      bookingId: bookingId,
      statusFrom: booking.status,
      statusTo: status,
      inputableId: currentAdmin.id,
      inputableType: 'Springkraf\\AdminACL\\Models\\Admin',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    newBookings[bookingIndex].changelogs.push(changelogs);

    await localForage.setItem('bookings', newBookings);
  } else {
    if (booking) {
      const newPayload = {
        bookingId: bookingId,
        date: format(new Date(), 'yyyy-MM-dd'),
        outletId,
        cancelNote,
        phoneNumber: booking.member.phoneNumber || '',
        customerName: booking.member.name || '',
        startMinute: booking.startMinute,
        isRandomStylist: booking.isRandomStylist,
        type: BookingTypeEnum[booking.type],
        status: status,
        email: booking?.member?.email || '',
        timeInMinutes: booking.timeInMinutes,
        stylistId: booking.stylist.id,
        serviceId: booking.services
          ? booking.services.map((service: any) => ({
              service: {
                id: service.id,
                name: service.name,
                price: service.price,
                timeInMinutes: service.timeInMinutes,
                point: 0,
              },
            }))
          : [],
        products: booking.products
          ? booking.products.map((product: any) => ({
              product: {
                id: product.id,
                name: product.name,
                code: product.code || '',
                price: product.price,
                timeInMinutes: 0,
                point: product.point || 0,
              },
              qty: product.qty,
            }))
          : [],
      };

      const changelogs: Changelog[] = [
        {
          id: uuidv4(),
          bookingId: bookingId,
          statusFrom: booking.status,
          statusTo: status,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          inputableId: currentAdmin.id || 0,
          inputableType: 'Springkraf\\AdminACL\\Models\\Admin',
        },
      ];

      await addOfflineBookingCache(newPayload, changelogs);
    }
  }
}

export async function updateBookingStatus(
  bookingId: string,
  outletId: string,
  status: BookingStatusTypeEnum,
  client: ApolloClient<any>,
  cancelNote?: string,
) {
  let booking;

  try {
    booking = client.readFragment({
      id: `Bookings:${bookingId}`,
      fragment: BOOKINGS_FRAGMENT,
    });
  } catch (e) {}

  if (booking) {
    const newPayload = {
      bookingId: bookingId,
      date: format(new Date(), 'yyyy-MM-dd'),
      outletId,
      phoneNumber: booking.member.phoneNumber || '',
      customerName: booking.member.name || '',
      email: booking?.member?.email || '',
      startMinute: booking.startMinute,
      status: booking.status,
      isRandomStylist: booking.isRandomStylist,
      type: BookingTypeEnum[booking.type],
      timeInMinutes: booking.timeInMinutes,
      stylistId: booking.stylist.id,
      serviceId: booking.services
        ? booking.services.map((service: any) => ({
            service: {
              id: service.id,
              name: service.name,
              price: service.price,
              description: service.description || '',
              timeInMinutes: service.timeInMinutes,
              point: 0,
            },
          }))
        : [],
      products: booking.products
        ? booking.products.map((product: any) => ({
            product: {
              id: product.id,
              name: product.name,
              code: product.code || '',
              price: product.price,
              description: product.description || '',
              timeInMinutes: 0,
              point: product.point || 0,
            },
            qty: product.qty,
          }))
        : [],
    };

    if (status === 1 || status === 2) {
      if (booking.status === 3 || booking.status === 9) {
        await addActiveBooking(newPayload, client);
        await removeCompletedBooking(bookingId, outletId, client);

        if (booking.status === 3) {
          await removeFinishedBooking(booking.id, outletId, client);
        }
      }
    } else if (status === 3 || status === 9) {
      if (booking.status === 1 || booking.status === 2) {
        await addCompletedBooking(newPayload, client);
        await removeActiveBooking(bookingId, outletId, client);
      }

      if (booking.status !== 3 && status === 3) {
        // await addFinishedBooking(newPayload, client);
      } else if (status === 9) {
        await removeFinishedBooking(booking.id, outletId, client);
      }
    }

    await updateBookingCacheStatus(
      bookingId,
      outletId,
      status,
      client,
      cancelNote,
    );

    try {
      client.writeFragment({
        id: `Bookings:${bookingId}`,
        fragment: gql`
          fragment Bookings on Bookings {
            status
          }
        `,
        data: {
          status,
        },
      });

      client.writeFragment({
        id: `Booking:${bookingId}`,
        fragment: gql`
          fragment Booking on Booking {
            status
          }
        `,
        data: {
          status,
        },
      });
    } catch (e) {
      const err = e as any;
      throw new Error(err);
    }
  }
}
