import * as React from 'react';
import styled, { css } from 'styled-components';
import color from 'styles/color';
import Color from 'color';
import ReactSelect from 'react-select';
import { H2, Button, TextInput } from '../../components/element';
import size from 'styles/size';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

export const Container = styled.div<{ show: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 2px;
  ${(props) =>
    !props.show &&
    css`
      display: none;
    `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${Color(color.border).fade(0.5).string()};
  margin-bottom: 1rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CenterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Body = styled.div<{ show?: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: stretch;
  overflow: hidden;
  ${(props) =>
    !props.show &&
    css`
      display: none;
    `}
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
  margin-left: 20px;
`;

export const BarButton = styled(Button)<{ active: boolean }>`
  color: ${(props) => (props.active ? color.white : color.black)};
  background-color: ${(props) => (props.active ? color.black : color.white)};
  min-width: 150px;
  min-height: 30px;
  outline: none;
  font-weight: bold;
  font-size: ${size.inputH4};
  border: none;
  border-radius: 0;
  position: relative;
  border-right: 1px solid ${color.charcoal4};
  @media (max-width: 1023px) {
    width: 70px;
    min-width: 0px;
    font-size: 10px;
  }
  &:hover {
    background-color: ${color.black};
    color: ${color.white};
  }
  &:first-child {
    border-top-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
  }
`;

export const StyledReactSelect = styled(ReactSelect)`
  &.react-select__container {
    width: 150px;
    .react-select__control {
      border-radius: 20px;
      min-height: 30px;
      border: 1px solid ${color.black};
    }
  }
`;

export const SelectButton = styled(Button)`
  color: ${color.white};
  background-color: ${color.black};
  width: 170px;
  min-height: 36px;
  outline: none;
  font-weight: bold;
  font-size: ${size.inputH4};
  margin-left: 20px;
  border: none;
  border-radius: 20px;
  display: block;
  overflow-wrap: break-word;
  border-right: 1px solid ${color.charcoal4};
  span {
    font-size: ${size.inputH3};
  }
`;

export const ShowHaircutButton = styled(Button)`
  color: ${color.white};
  background-color: ${color.black};
  outline: none;
  border: none;
  border-radius: 10px;
  display: block;
  height: 30px;
  width: 100px;
  min-height: 30px;
  font-size: 12px;
  overflow-wrap: break-word;
  border-right: 1px solid ${color.charcoal4};
  span {
    font-size: ${size.inputH4};
  }
`;

export const LeftContainer = styled.div`
  overflow: hidden;
  padding: 2px;
  max-width: 56%;
  display: flex;
  flex-direction: column;
`;

export const CenterContainer = styled.div`
  max-width: 1%;
  min-width: 1%;
`;

export const RightContainer = styled.div`
  overflow: hidden;
  padding: 2px;
  max-width: 40%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(H2)`
  text-align: center;
`;

export const DetailButton = styled(Button)`
  position: fixed;
  width: 100px;
  right: 18px;
  bottom: 16px;
`;

export const ButtonPadding = styled.div`
  margin-bottom: 80px;
`;

export const BookingDetailRow = styled.div<{ selected?: boolean }>`
  display: flex;
  cursor: pointer;
  padding: 4px 0;
  margin-left: 32px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 12px;
  ${(props) =>
    props.selected &&
    css`
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    `}
  /* background-color: ${color.white}; */
`;

export const StylistName = styled.p`
  overflow-wrap: break-word;
  font-size: 16px;
`;

export const BookingName = styled.p`
  overflow-wrap: break-word;
  font-size: 16px;
`;

export const TimeText = styled.p`
  overflow-wrap: break-word;
  font-size: 13px;
  margin-left: 40px;
`;

export const UpperContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
  justify-content: space-between;
`;

export const DetailRow = styled.div`
  width: 25%;
  padding: 0 8px;
`;

export const Space = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  justify-content: space-between;
`;

export const OperationalButton = styled(Button)<{ active: boolean }>`
  color: ${(props) => (props.active ? color.white : color.black)};
  background-color: ${(props) => (props.active ? color.black : color.white)};
  min-width: 140px;
  min-height: 36px;
  outline: none;
  font-weight: bold;
  font-size: 14px;
  border-radius: 18px 0 0 18px;
  border: 1px solid ${color.charcoal4};
  border-right: none;

  @media (max-width: 1023px) {
    width: 120px;
    min-width: 0px;
    font-size: 10px;
  }

  &:hover {
    background-color: ${color.black};
    color: ${color.white};
  }
`;

export const NotOperationalButton = styled(Button)<{ active: boolean }>`
  color: ${(props) => (props.active ? color.white : color.black)};
  background-color: ${(props) => (props.active ? color.black : color.white)};
  min-width: 140px;
  min-height: 36px;
  outline: none;
  font-weight: bold;
  font-size: 14px;
  border: none;
  border-radius: 0 18px 18px 0;
  border: 1px solid ${color.charcoal4};

  @media (max-width: 1023px) {
    width: 120px;
    min-width: 0px;
    font-size: 10px;
  }

  &:hover {
    background-color: ${color.black};
    color: ${color.white};
  }
`;

export const LoadingContainer = styled.div`
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
`;

export const CustomInput = styled(TextInput)`
  height: 48px;
  flex-grow: 1;
  flex-shrink: 1;
  border-radius: 5px 0 0 5px;
`;

export const CustomContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 0.3rem;
`;

export const CheckButton = styled(Button)`
  width: 100px;
  border-radius: 0 5px 5px 0;
`;

export const Center = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: center;
`;

export const CheckboxInput = styled.input``;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const Text = styled.p<{ noMargin?: boolean }>`
  margin-left: ${(props) => (props.noMargin ? '0' : '0.5rem')};
`;

export const Pad = styled.div`
  margin-bottom: 1rem;
`;

export const Select = styled(ReactSelect)`
  &.react-select__container {
    width: 100%;
    margin-bottom: 16px;

    .react-select__control {
      border-radius: ${size.inputBorder};
      min-height: ${size.inputMinHeight};
      border: 1px solid ${color.border};

      &--is-focused {
        border-color: ${color.accent};
        box-shadow: none;
      }
    }
  }
  & .react-select__indicator-separator {
    display: none;
  }
  & .react-select__indicator {
    padding-right: ${size.inputPadding};
  }
  & .react-select__value-container {
    font-weight: bold;
    color: ${color.accent};
    padding: 0px ${size.inputPadding};
  }
  & .react-select__option--is-selected {
    background-color: ${color.accent};
  }
  & .react-select__placeholder {
    color: ${color.placeholder};
  }
`;

export const ServiceSelect = styled(ReactSelect)`
  &.react-select__container {
    width: 100%;
    margin-bottom: 16px;

    .react-select__control {
      border-radius: ${size.inputBorder};
      min-height: ${size.inputMinHeight};
      border: 1px solid ${color.border};

      &--is-focused {
        border-color: ${color.accent};
        box-shadow: none;
      }
    }
  }
  & .react-select__indicator-separator {
    display: none;
  }
  & .react-select__indicator {
    padding-right: ${size.inputPadding};
  }
  & .react-select__value-container {
    font-weight: bold;
    color: ${color.accent};
    padding: 0px ${size.inputPadding};
  }
  & .react-select__option--is-selected {
    background-color: ${color.accent};
  }
  & .react-select__placeholder {
    color: ${color.placeholder};
  }
`;

export const ProductSelect = styled(ReactSelect)`
  &.react-select__container {
    width: 100%;
    margin-bottom: 16px;

    .react-select__control {
      border-radius: ${size.inputBorder};
      min-height: ${size.inputMinHeight};
      border: 1px solid ${color.border};

      &--is-focused {
        border-color: ${color.accent};
        box-shadow: none;
      }
    }
  }
  & .react-select__indicator-separator {
    display: none;
  }
  & .react-select__indicator {
    padding-right: ${size.inputPadding};
  }
  & .react-select__value-container {
    font-weight: bold;
    color: ${color.accent};
    padding: 0px ${size.inputPadding};
  }
  & .react-select__option--is-selected {
    background-color: ${color.accent};
  }
  & .react-select__placeholder {
    color: ${color.placeholder};
  }
`;

export const ProductQuantity = styled(TextInput)`
  height: 48px;
  margin-left: 4px;
  width: 120px;
`;

export const ProductRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const AddButton = styled(Button)`
  background-color: ${color.white};
  border: 1px solid ${color.white};
  color: black;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: ${size.inputH4};
  font-weight: bold;
  outline: none;
  padding: 0;
  width: auto;
  border-radius: ${size.inputBorder};
  min-height: 30px;
  white-space: nowrap;
  margin-bottom: 0.5rem;
  text-align: center;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
  &:disabled {
    opacity: 0.4;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
  justify-content: space-between;
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RemoveButton = styled.div`
  padding-bottom: 1px;
  border: 2px solid ${color.black};
  color: ${color.black};
  border-radius: 50%;
  font-size: 20px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
`;

export const QuantityButton = styled.div`
  padding-bottom: 1px;
  border: 2px solid ${color.black};
  color: ${color.black};
  border-radius: 50%;
  font-size: 25px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  margin: 0 0.5rem;
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.5rem;
  align-items: center;
`;

export const Badges = styled.div`
  width: 28px;
  height: 28px;
  font-size: 12px;
  color: white;
  position: absolute;
  top: -0px;
  right: -5px;
  z-index: 1000;
  background-color: red;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

function ComposedDatePicker(props: ReactDatePickerProps) {
  return <DatePicker {...props} />;
}

export const DateFilter = styled(ComposedDatePicker)`
  border-radius: 20px !important;
  width: 170px;
  min-height: 36px;
  border: 1px solid ${color.charcoal4};
`;

export const OutlineButton = styled(Button)`
  background-color: white;
  color: black;
  border-radius: 20px;
  padding-right: 12px;
  font-size: 14px;
  justify-content: center;
  display: flex;
  align-items: center;
  min-height: 30px;
`;

export const TableButtonContainer = styled.div`
  min-width: 200px;
  max-width: 220px;
  min-height: 30px;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const MR8 = styled.div`
  margin-right: 8px;
`;

export const MB35 = styled.div`
  margin-bottom: 42px;
`;

export const BoldText = styled.p`
  font-weight: bold;
`;

export const StylistSelect = styled(ReactSelect)`
  &.react-select__container {
    width: 200px;
    margin-bottom: 16px;

    .react-select__control {
      border-radius: ${size.inputBorder};
      border: 1px solid ${color.border};

      &--is-focused {
        border-color: ${color.accent};
        box-shadow: none;
      }
    }
  }
  & .react-select__indicator-separator {
    display: none;
  }
  & .react-select__indicator {
    padding-right: ${size.inputPadding};
  }
  & .react-select__value-container {
    font-weight: bold;
    color: ${color.accent};
    padding: 0px ${size.inputPadding};
  }
  & .react-select__option--is-selected {
    background-color: ${color.accent};
  }
  & .react-select__placeholder {
    color: ${color.placeholder};
  }

  & .react-select__menu {
    z-index: 1000 !important;
  }
`;

export const ToggleContainer = styled.div`
  margin-bottom: 0.5rem;
`;
