import * as React from 'react';
import ReceiptLayout from './ReceiptLayout';
import { useTranslation } from 'react-i18next';
import { TransactionSummaryDetail } from 'repositories/transaction';
import { DividerRow, Subtitle, UnderscoreTitle, Separator } from './styles';
import { string2money } from 'utils/string';
import { format } from 'date-fns';

interface Props {
  transactionDetail?: TransactionSummaryDetail;
  cashier: string;
  services?: {
    total: number;
    qty: number;
    services: any[];
  };
  products?: {
    total: number;
    qty: number;
    products: any[];
  };
  payments?: any[];
  date: string;
}

const TransactionReceipt = React.forwardRef((props: Props, ref) => {
  const { t } = useTranslation();
  const {
    transactionDetail,
    date,
    services,
    products,
    payments,
    cashier,
  } = props;
  if (!transactionDetail) {
    return null;
  }

  return (
    <ReceiptLayout ref={ref}>
      <Subtitle>{date}</Subtitle>
      <br />
      <table>
        <tbody>
          <tr>
            <td>
              <UnderscoreTitle>{t('common.cashier')}</UnderscoreTitle>
            </td>
            <td>{cashier}</td>
          </tr>
          <tr>
            <td>{t('transaction.total_pending')}</td>
            <td>{transactionDetail?.pending}</td>
          </tr>
          <Separator>
            <td />
          </Separator>
          {!!services && services.services && services.services.length && (
            <tr>
              <td>
                <UnderscoreTitle>{t('common.service')}</UnderscoreTitle>
              </td>
            </tr>
          )}
          {!!services &&
            services.services &&
            services.services.map((service: any) => {
              return (
                <tr key={service.id}>
                  <td>{`(${service.qty || 1}) ${service.name}`}</td>
                  <td>{`Rp ${string2money(service.total)}`}</td>
                </tr>
              );
            })}
          <DividerRow />
          {!!products && products.products && products.products.length && (
            <tr>
              <td>
                <UnderscoreTitle>{t('common.product')}</UnderscoreTitle>
              </td>
            </tr>
          )}
          {!!products &&
            products.products &&
            products.products.map((product: any) => {
              return (
                <tr key={product.id}>
                  <td>{`(${product.qty || 1}) ${product.name}`}</td>
                  <td>{`Rp ${string2money(product.total)}`}</td>
                </tr>
              );
            })}
          <DividerRow />
          {!!services && services.qty && (
            <tr>
              <td>{t('transaction.service_total_quantity')}</td>
              <td>{`${string2money(services.qty)} ${t(
                'transaction.service',
              )}`}</td>
            </tr>
          )}
          {!!products && products.qty && (
            <tr>
              <td>{t('transaction.product_total_quantity')}</td>
              <td>{`${string2money(products.qty)} ${t(
                'transaction.pieces',
              )}`}</td>
            </tr>
          )}
          {!!services && services.total && (
            <tr>
              <td>{t('transaction.total_service')}</td>
              <td>{`Rp ${string2money(services.total)}`}</td>
            </tr>
          )}
          {!!products && products.total && (
            <tr>
              <td>{t('transaction.total_product')}</td>
              <td>{`Rp ${string2money(products.total)}`}</td>
            </tr>
          )}
          <DividerRow />
          {!!transactionDetail.totalPrice && (
            <tr>
              <td>{t('transaction.total_sale')}</td>
              <td>{`Rp ${string2money(transactionDetail.totalPrice)}`}</td>
            </tr>
          )}
          {!!transactionDetail.totalPrice && (
            <tr>
              <td>{t('transaction.total_discount')}</td>
              <td>{`Rp ${string2money(transactionDetail.totalDiscount)}`}</td>
            </tr>
          )}
          <DividerRow />
          {!!payments &&
            payments.map((payment: { name: string; total: number }) => {
              return (
                <tr>
                  <td>{`${t('transaction.total_payment_1')} ${
                    payment.name
                  }`}</td>
                  <td>{`Rp ${string2money(payment.total)}`}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <Subtitle>
        {t('transaction.print_at', {
          date: format(new Date(), 'dd-MM-yyyy, HH:mm:ss'),
        })}
      </Subtitle>
    </ReceiptLayout>
  );
});
export default TransactionReceipt;
