import * as React from 'react';
import {
  CheckboxContainer,
  CheckboxInput,
  Text,
  CustomContainer,
  CustomInput,
  StatusText,
  CheckButton,
} from 'pages/transactionManagement/style';
import { FormField, Field } from 'components/element';
import { useTranslation } from 'react-i18next';
import { authorizeWithTotp } from 'helpers/authorization';
import { toast } from 'react-toastify';
import { CheckUserResultModel, useMutationCheckUser } from 'repositories/auth';
import { FormikResult } from '../../../typings/formik';
import { MemberCheckResult } from 'hooks/useTransactionForm';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';
import { FormikValues, useField, useFormikContext } from 'formik';
import useEmailAutocomplete from 'hooks/useEmailAutocomplete';

interface CustomerFormField {
  phoneNumber?: string;
  customerName: string;
  isGuest: boolean;
  email: string;
  memberCheckResult?: MemberCheckResult;
  memberCode?: string;
}

interface CustomerInputProps<T extends FormikValues> {
  formik: FormikResult<T>;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

function EmailField() {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const emailRef = React.useRef((values as any)?.email);
  const emailAutocomplete = useEmailAutocomplete();
  return (
    <Field
      type="text"
      name="email"
      textPrediction={emailAutocomplete}
      placeholder={t('common.email')}
      disabled={!!emailRef.current}
    />
  );
}

function UseMemberCodeField() {
  const { t } = useTranslation();
  const { mode } = useNetworkStatus();
  const [, , helperPhone] = useField('phoneNumber');
  const [, , helperCustomer] = useField('customerName');
  const [, , helperEmail] = useField('email');
  const [, , helperMemberCheckResult] = useField('memberCheckResult');
  const [, , helperMemberCode] = useField('memberCode');

  const onChangeUseMemberCode = React.useCallback((val: boolean) => {
    helperCustomer.setValue('');
    helperPhone.setValue('');
    helperEmail.setValue('');
    helperMemberCode.setValue('');
    helperMemberCheckResult.setValue(undefined);
  }, []);
  if (!mode) {
    return null;
  }

  return (
    <Field
      type="switch"
      name="isMemberCode"
      text={t('common.use_member_code')}
      onAfterChange={onChangeUseMemberCode}
    />
  );
}

export default function CustomerInput<T extends CustomerFormField>(
  props: CustomerInputProps<T>,
) {
  const { formik, isLoading, setIsLoading } = props;
  const { t } = useTranslation();
  const { mode } = useNetworkStatus();
  const [checkUser] = useMutationCheckUser();
  const isMemberCode = formik.values['isMemberCode'];

  const onClickGuest = React.useCallback(() => {
    async function exec() {
      if (!formik.values.isGuest && !(await authorizeWithTotp())) {
        toast.error(t('common.wrong_pin'));
        return;
      }
      formik.setValues({
        ...formik.values,
        isGuest: !formik.values.isGuest,
        phoneNumber: '',
        email: '',
        memberCheckResult: undefined,
      });
    }
    exec();
  }, [formik, t]);

  const onChangePhoneNumber = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.setValues({
        ...formik.values,
        phoneNumber: e.target.value,
        customerName: '',
        email: '',
        memberCheckResult: undefined,
        memberCode: '',
      });
    },
    [formik],
  );

  const onChangeMemberCode = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.setValues({
        ...formik.values,
        phoneNumber: '',
        customerName: '',
        email: '',
        memberCheckResult: undefined,
        memberCode: e.target.value,
      });
    },
    [formik],
  );

  const onChangeMemberResult = (data: CheckUserResultModel, newValues: any) => {
    newValues.memberCheckResult = {
      exist: data.checkUser.data.exist,
      data: data.checkUser.data.member,
    };

    if (data.checkUser.data.exist) {
      newValues.customerName = data.checkUser.data.member.name
        ? `${data.checkUser.data.member.name} (${
            data.checkUser.data.member.point || 0
          } P)`
        : '-';

      newValues.email = data.checkUser.data.member.email || '';
    }
  };

  const onCheckPhoneNumber = React.useCallback(async () => {
    if (formik.values.phoneNumber) {
      try {
        setIsLoading(true);
        const response = await checkUser({
          variables: {
            payload: {
              phone_number: formik.values.phoneNumber,
            },
          },
        });
        let newValues = {
          ...formik.values,
        };

        newValues.phoneNumber = formik.values.phoneNumber;
        if (response.data) onChangeMemberResult(response.data, newValues);

        formik.setValues(newValues);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    }
  }, [checkUser, formik, setIsLoading]);

  const onCheckMemberCode = React.useCallback(async () => {
    const memberCode = formik.values['memberCode'];
    if (memberCode) {
      try {
        setIsLoading(true);
        const response = await checkUser({
          variables: {
            payload: {
              member_code: memberCode,
            },
          },
        });
        let newValues = {
          ...formik.values,
        };

        newValues.memberCode = memberCode;
        if (response.data) onChangeMemberResult(response.data, newValues);

        formik.setValues(newValues);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    }
  }, [checkUser, formik, setIsLoading]);

  return (
    <>
      <UseMemberCodeField />
      {isMemberCode && mode ? (
        <FormField errorText={formik.errors['memberCode'] as string} noMargin>
          <CustomContainer>
            <CustomInput
              mode={mode}
              name="memberCode"
              value={formik.values['memberCode']}
              onChange={onChangeMemberCode}
              onBlur={formik.handleBlur}
              placeholder={t('common.member_code')}
            />
            {mode && (
              <CheckButton
                isLoading={isLoading}
                type="button"
                onClick={onCheckMemberCode}
              >
                {t('common.check')}
              </CheckButton>
            )}
          </CustomContainer>
        </FormField>
      ) : (
        <>
          <CheckboxContainer>
            <CheckboxInput
              type="checkbox"
              checked={formik.values.isGuest}
              onClick={onClickGuest}
            />
            <Text>{t('common.guest')}</Text>
          </CheckboxContainer>
          {!formik.values.isGuest && (
            <FormField errorText={formik.errors.phoneNumber as string} noMargin>
              <CustomContainer>
                <CustomInput
                  mode={mode}
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={onChangePhoneNumber}
                  onBlur={formik.handleBlur}
                  placeholder={t('common.phone_number')}
                />
                {mode && (
                  <CheckButton
                    isLoading={isLoading}
                    type="button"
                    onClick={onCheckPhoneNumber}
                  >
                    {t('common.check')}
                  </CheckButton>
                )}
              </CustomContainer>
            </FormField>
          )}
        </>
      )}
      <StatusText available>
        {formik.values.memberCheckResult
          ? !formik.values.memberCheckResult.exist
            ? t('status.new_customer')
            : ''
          : ''}
      </StatusText>
      {(!!formik.values.memberCheckResult ||
        formik.values.isGuest ||
        !mode) && (
        <>
          <Field
            type="text"
            name="customerName"
            placeholder={t('common.name')}
            disabled={
              formik.values.memberCheckResult && mode
                ? formik.values.memberCheckResult.exist
                : false
            }
          />
          <EmailField />
        </>
      )}
    </>
  );
}
