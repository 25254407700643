import { createGlobalStyle } from 'styled-components';

const CssReset = createGlobalStyle`
  html {
    font-family: Nunito, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 16px;
  }

  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default padding */
  ul[class],
  ol[class] {
    padding: 0;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  figure,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
  }

  /* Remove list styles on ul, ol elements with a class attribute */
  ul[class],
  ol[class] {
    list-style: none;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }

  /* Natural flow and rhythm in articles by default */
  article > * + * {
    margin-top: 1em;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Blur images when they have no alt attribute */
  img:not([alt]) {
    filter: blur(10px);
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  /* input number button size */
  input[type=number]::-webkit-inner-spin-button {
	  cursor: pointer;
	  display: block;
    width: 30px;
    height: 45px;
	  text-align: center;
	  position: relative;
	  background: transparent;
	}
	input[type=number]::-webkit-inner-spin-button::before,
	input[type=number]::-webkit-inner-spin-button::after {
	  content: "";
	  position: absolute;
	  right: 0;
	  width: 0;
	  height: 0;
	  border-left: 7px solid transparent;
	  border-right: 7px solid transparent;
	  border-bottom: 10px solid #777;
	}
	input[type=number]::-webkit-inner-spin-button::before {
	  top: 7px;
	}
	input[type=number]::-webkit-inner-spin-button::after {
	  bottom: 7px;
	  transform: rotate(180deg);
	}

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

export default CssReset;
