import { gql, useQuery } from '@apollo/client';
import { StockBalanceResult } from './item.model';

const GET_STOCK_BALANCES = gql`
  query getStockBalances($outletId: String!, $page: Integer, $limit: Integer) {
    getStockBalances(outlet_id: $outletId, page: $page, limit: $limit)
      @rest(type: "StockBalancePayload", path: "/stock-balance?{args}") {
      data @type(name: "StockBalance") {
        id
        name
        description
        qty
      }
      meta @type(name: "PaginationMeta") {
        currentPage
        lastPage
        total
        perPage
      }
    }
  }
`;

type StockBalanceResultData = { getStockBalances: StockBalanceResult };
export function useQueryGetStockBalances(
  outletId: string,
  page?: number,
  limit?: number,
) {
  return useQuery<StockBalanceResultData>(GET_STOCK_BALANCES, {
    variables: {
      outletId,
      page,
      limit,
    },
  });
}
