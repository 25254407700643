import i18n from 'locales';
import { camelizeKeys } from 'humps';
import { HTTPError, TimeoutError } from 'ky';

export class ApiError extends Error {
  public code?: number;
  public errors?: { [key: string]: string };

  constructor(
    message: string,
    code?: number,
    errors?: { [key: string]: string },
  ) {
    super(message);
    this.code = code;
    this.errors = errors || {};
  }
}

export async function toApiError(error: Error): Promise<ApiError> {
  if (error instanceof HTTPError) {
    const body = await error.response.json();
    return new ApiError(
      body.message,
      body.status_code || error.response.status,
      camelizeKeys(body.errors) as any,
    );
  } else if (error instanceof TimeoutError) {
    return new ApiError(i18n.t('error.timeout'));
  } else {
    if (error.message === 'Network request failed') {
      return new ApiError(i18n.t('error.network'));
    }
    return new ApiError(error.message);
  }
}

// export const AuthenticationError = 'UNAUTHENTICATED';
// export const UserInputError = 'BAD_USER_INPUT';
// export const ForbiddenError = 'FORBIDDEN';
// export const InternalServerError = 'INTERNAL_SERVER_ERROR';
// export const NotFoundError = 'NOT_FOUND_ERROR';
// export const TimeoutError = 'TIMEOUT_ERROR';

// type ErrorCode =
//   | typeof AuthenticationError
//   | typeof UserInputError
//   | typeof ForbiddenError
//   | typeof NotFoundError
//   | typeof InternalServerError
//   | typeof TimeoutError;

// export async function attachApolloGraphQLErrors(
//   apolloError: ApolloError,
//   ApiError: ky.ApiError,
// ) {
//   try {
//     const body = await ApiError.response.json();
//     const graphQLError = new GraphQLError(
//       body.message,
//       undefined,
//       undefined,
//       [],
//       [],
//       ApiError,
//       {},
//     );
//     switch (ApiError.response.status) {
//       case 401:
//         graphQLError.extensions!.code = ApolloAuthenticationError;
//         break;
//       case 403:
//         graphQLError.extensions!.code = ApolloForbiddenError;
//         break;
//       case 400:
//         graphQLError.extensions!.code = ApolloUserInputError;
//         break;
//       case 422:
//         graphQLError.extensions!.code = ApolloUserInputError;
//         graphQLError.extensions!.exception = {
//           validationErrors: body.errors,
//         };
//         break;
//       case 404:
//         graphQLError.extensions!.code = ApolloNotFoundError;
//         break;
//       default:
//         graphQLError.extensions!.code = ApolloInternalServerError;
//         break;
//     }
//     apolloError.graphQLErrors = [graphQLError];
//   } catch (err) {
//     apolloError.message = err.message;
//   }
// }
