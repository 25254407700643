import { Resolver, gql, useMutation } from '@apollo/client';

import { GET_CURRENT_OUTLET } from './outlet.query';

const SET_CURRENT_OUTLET = gql`
  mutation setCurrentOutlet($id: String!) {
    setCurrentOutlet(id: $id) @client
  }
`;

const SET_REMEMBERED__OUTLET = gql`
  mutation setRememberedOutlet($id: String!) {
    setRememberedOutlet(id: $id) @client
  }
`;

export function useStateSetCurrentOutlet() {
  return useMutation<null, { id?: string }>(SET_CURRENT_OUTLET);
}

export function useStateSetRememberedOutlet() {
  return useMutation<null, { id?: string }>(SET_REMEMBERED__OUTLET);
}

export const outletLocalResolvers: { [key: string]: Resolver } = {
  setCurrentOutlet(_, variables, { cache }) {
    cache.writeQuery({
      query: GET_CURRENT_OUTLET,
      data: {
        loggedInOutletId: variables.id,
      },
    });
    return null;
  },
};
