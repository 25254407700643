import { gql } from '@apollo/client';

export const SERVICE_FRAGMENT = gql`
  fragment Service on Service {
    id
    name
    description
    imagePath
    price
    point
    timeInMinutes
    isAvailable
    updatedAt
    createdAt
    pivot {
      outletId
      serviceId
      isAvailable
    }
  }
`;
