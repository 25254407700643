import * as React from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import {
  Booking,
  BookingSheets,
  BookingSheetAvailableTime,
} from './booking.model';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';

export const GET_BOOKINGS = gql`
  query getBookings(
    $outletId: String!
    $date: String!
    $statuses: String!
    $set: String!
    $types: String
    $withoutTransaction: String
  ) {
    bookings(
      outlet_id: $outletId
      date: $date
      statuses: $statuses
      without_transaction: $withoutTransaction
      set: $set
      types: $types
    ) @rest(type: "BookingsPayload", path: "/bookings?{args}") {
      data @type(name: "Bookings") {
        id
        # date
        # outlet {
        #   id
        #   name
        #   phoneNumber
        #   description
        #   address
        #   longitude
        #   latitude
        #   cityId
        #   rate
        #   rateCount
        #   outletImages @type(name: "OutletImage") {
        #     id
        #     index
        #     filePath
        #   }
        # }
        services {
          id
          name
          description
          price
          point
          timeInMinutes
        }
        products {
          id
          code
          name
          description
          qty
          price
          point
        }
        member {
          id
          phoneNumber
          name
          birthDate
          email
          address
          point
        }
        stylist {
          id
          name
          # email
          # phoneNumber
          # rate
          # rateCount
          # imagePath
        }
        transactionStatus
        bookingChangeLogs
        startMinute
        timeInMinutes
        thresholdTime
        # isRandomStylist
        type
        status
        createdAt
        # updatedAt
      }
    }
  }
`;

const GET_BOOKING = gql`
  query getBooking($bookingId: String!) {
    booking(bookingId: $bookingId)
      @rest(type: "BookingPayload", path: "/bookings/{args.bookingId}") {
      data @type(name: "Booking") {
        id
        date
        member {
          id
          phoneNumber
          name
          birthDate
          email
          address
          point
        }
        services {
          price
          timeInMinutes
          qty
          service {
            id
            name
            imagePath
            price
            point
            timeInMinutes
          }
        }
        products {
          price
          qty
          product {
            id
            name
            imagePath
            price
            point
          }
        }
        stylist {
          id
          name
          # email
          # phoneNumber
          # rate
          # rateCount
          # imagePath
        }
        startMinute
        timeInMinutes
        thresholdTime
        isRandomStylist
        type
        status
      }
    }
  }
`;

const GET_BOOKING_SHEETS = gql`
  query getBookingSheets($outletId: String!, $date: String!) {
    bookingSheets(outletId: $outletId, date: $date)
      @rest(
        type: "BookingSheetPayload"
        path: "/booking-sheets?outlet_id={args.outletId}&date={args.date}"
      ) {
      data @type(name: "BookingSheets") {
        date
        minute
        startMinute
        endMinute
        isOperationalHour
        bookings @type(name: "Bookings") {
          id
          member {
            id
            phoneNumber
            name
            birthDate
            email
            address
            point
          }
          services {
            id
            name
            description
            price
            point
            timeInMinutes
          }
          products {
            id
            code
            name
            description
            qty
            price
            point
          }
          stylist {
            id
            name
          }
          isRandomStylist
          startMinute
          timeInMinutes
          type
          status
          createdAt
        }
      }
    }
  }
`;

export const GET_BOOKING_SHEETS_CACHE = gql`
  query getBookingSheets($outletId: String!, $date: String!) {
    bookingSheets(outletId: $outletId, date: $date) {
      data @type(name: "BookingSheets") {
        date
        minute
        startMinute
        endMinute
        isOperationalHour
        bookings @type(name: "Bookings") {
          id
          member {
            id
            phoneNumber
            name
            birthDate
            email
            address
            point
          }
          services {
            id
            name
            description
            price
            point
            timeInMinutes
          }
          products {
            id
            code
            name
            description
            qty
            price
            point
          }
          stylist {
            id
            name
          }
          isRandomStylist
          startMinute
          timeInMinutes
          type
          status
          createdAt
        }
      }
    }
  }
`;

export const GET_BOOKING_UNSETS_CACHE = gql`
  query getBookingUnsets($outletId: String!, $date: String!) {
    bookingUnset(outletId: $outletId, date: $date) {
      data @type(name: "BookingUnset") {
        id
        member {
          id
          phoneNumber
          name
          birthDate
          email
          address
          point
        }
        services {
          id
          name
          description
          price
          point
          timeInMinutes
        }
        products {
          id
          code
          name
          description
          qty
          price
          point
        }
        stylist {
          id
          name
        }
        isRandomStylist
        startMinute
        timeInMinutes
        type
        status
        createdAt
      }
    }
  }
`;

export const GET_BOOKINGS_CACHE = gql`
  query getBookings(
    $outletId: String!
    $date: String!
    $statuses: String!
    $set: String!
    $types: String
    $withoutTransaction: String
  ) {
    bookings(
      outlet_id: $outletId
      date: $date
      statuses: $statuses
      without_transaction: $withoutTransaction
      set: $set
      types: $types
    ) {
      data @type(name: "Bookings") {
        id
        # date
        # outlet {
        #   id
        #   name
        #   phoneNumber
        #   description
        #   address
        #   longitude
        #   latitude
        #   cityId
        #   rate
        #   rateCount
        #   outletImages @type(name: "OutletImage") {
        #     id
        #     index
        #     filePath
        #   }
        # }
        services {
          id
          name
          description
          price
          point
          timeInMinutes
        }
        products {
          id
          code
          name
          description
          qty
          price
          point
        }
        member {
          id
          phoneNumber
          name
          birthDate
          email
          address
          point
        }
        stylist {
          id
          name
          # email
          # phoneNumber
          # rate
          # rateCount
          # imagePath
        }
        transactionStatus
        bookingChangeLogs
        startMinute
        timeInMinutes
        # isRandomStylist
        type
        status
        createdAt
        # updatedAt
      }
    }
  }
`;

const GET_BOOKING_UNSET = gql`
  query getBookingUnset($outletId: String!, $date: String!) {
    bookingUnset(outletId: $outletId, date: $date)
      @rest(
        type: "BookingUnsetPayload"
        path: "/bookings/unset?outlet_id={args.outletId}&date={args.date}"
      ) {
      data @type(name: "BookingUnset") {
        id
        services {
          id
          name
          description
          price
          point
          timeInMinutes
        }
        products {
          id
          code
          name
          description
          qty
          price
          point
        }
        member {
          id
          phoneNumber
          name
          birthDate
          email
          address
          point
        }
        stylist {
          id
          name
        }
        isRandomStylist
        startMinute
        timeInMinutes
        type
        status
        createdAt
      }
    }
  }
`;

const GET_BOOKING_SHEET_AVAILABLE_TIME = gql`
  query getBookingSheetAvailableTime(
    $outletId: String!
    $date: String!
    $timeInMinutes: Integer!
    $stylistId: String!
    $isAllTime: Any!
  ) {
    bookingSheetAvailableTime(
      outletId: $outletId
      date: $date
      timeInMinutes: $timeInMinutes
      stylistId: $stylistId
      isAllTime: $isAllTime
    )
      @rest(
        type: "BookingSheetAvailableTimePayload"
        path: "/booking-sheets/available?outlet_id={args.outletId}&date={args.date}&time_in_minutes={args.timeInMinutes}&stylist_id={args.stylistId}&is_all_time={args.isAllTime}"
      ) {
      data @type(name: "BookingSheetAvailableTime") {
        date
        minute
        isOperationalHour
      }
    }
  }
`;

export function useQueryGetBookingSheets(outletId: string, date: string) {
  const queryResult = useLazyQuery<{
    bookingSheets: { data: BookingSheets[] };
  }>(GET_BOOKING_SHEETS, {
    variables: {
      outletId,
      date,
    },
  });

  const { mode } = useNetworkStatus();
  const [, { startPolling, stopPolling }] = queryResult;

  React.useEffect(() => {
    if (mode) {
      startPolling && startPolling(15000);
    }
    return () => {
      stopPolling && stopPolling();
    };
  }, [startPolling, stopPolling, date, outletId, mode]);

  return queryResult;
}

export function useQueryGetBookings(
  outletId: string,
  date: string,
  statuses: string,
  types: string,
  set?: number,
  withoutTransaction?: number,
) {
  const queryResult = useLazyQuery<{ bookings: { data: Booking[] } }>(
    GET_BOOKINGS,
    {
      variables: {
        outletId,
        date,
        statuses,
        withoutTransaction,
        set,
        types,
      },
    },
  );


  const { mode } = useNetworkStatus();
  const [, { startPolling, stopPolling }] = queryResult;

  React.useEffect(() => {
    if (mode) {
      startPolling && startPolling(15000);
    }

    return () => {
      stopPolling && stopPolling();
    };
  }, [startPolling, stopPolling, date, outletId, mode]);

  return queryResult;
}

type BookingResultData = { booking: { data: Booking } };
export function useQueryGetBooking(
  bookingId?: string,
  onCompleted?: (data: BookingResultData) => void,
) {
  const [load, result] = useLazyQuery<BookingResultData>(GET_BOOKING, {
    onCompleted,
  });


  React.useEffect(() => {
    if (bookingId) {
      load({
        variables: {
          bookingId,
        },
      });
    }
  }, [bookingId, load]);

  return result;
}

export function useLazyQueryGetBooking() {
  return useLazyQuery<BookingResultData>(GET_BOOKING);
}

export function useLazyQueryGetBookings() {
  return useLazyQuery<{ bookings: { data: Booking[] } }>(GET_BOOKINGS);
}

export function useQueryGetBookingUnset(outletId: string, date: string) {
  const queryResult = useLazyQuery<{ bookingUnset: { data: Booking[] } }>(
    GET_BOOKING_UNSET,
    {
      variables: {
        outletId: outletId,
        date: date,
      },
    },
  );

  const { mode } = useNetworkStatus();
  const [, { startPolling, stopPolling }] = queryResult;

  React.useEffect(() => {
    if (mode) {
      startPolling && startPolling(15000);
    }

    return () => {
      stopPolling && stopPolling();
    };
  }, [startPolling, stopPolling, date, outletId, mode]);

  return queryResult;
}

export function useQueryGetBookingSheetAvailableTime(
  outletId: string,
  date: string,
  timeInMinutes: number,
  stylistId: string,
  isAllTime: number,
) {
  return useLazyQuery<{
    bookingSheetAvailableTime: { data: BookingSheetAvailableTime[] };
  }>(GET_BOOKING_SHEET_AVAILABLE_TIME, {
    variables: {
      outletId: outletId,
      date: date,
      timeInMinutes: timeInMinutes,
      stylistId: stylistId,
      isAllTime: isAllTime,
    },
  });
}

function GetOfflineAvailableTime(): any {
  const availableTime: any[] = [];

  for (let i = 0; i <= 1430; i += 10) {
    availableTime.push({
      minute: i,
      isOperationalHour: true,
    });
  }

  return [
    //@ts-nocheck
    (payload: any) => {},
    {
      data: {
        bookingSheetAvailableTime: {
          data: availableTime,
        },
      },
      loading: false,
      called: false,
    },
  ];
}

export function useQueryGetBookingSheetAvailableTimeWithoutParams() {
  const { mode } = useNetworkStatus();

  const queryResult = useLazyQuery<{
    bookingSheetAvailableTime: { data: BookingSheetAvailableTime[] };
  }>(GET_BOOKING_SHEET_AVAILABLE_TIME);

  return mode ? queryResult : GetOfflineAvailableTime();
}
