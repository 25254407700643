import * as React from 'react';
import {
  H3Title,
  ItemRow,
  AddButton,
  Text,
  ItemContainer,
  RemoveButton,
  MarginB8,
} from 'pages/transactionManagement/style';
import PlusIcon from 'assets/images/plus';
import { useTranslation } from 'react-i18next';
import { ServiceSingleValue } from 'components/SingleValue';
import useAppState from 'hooks/useAppState';
import { TransactionService } from 'repositories/transaction';
import { useQueryGetOutletServices, OutletService } from 'repositories/outlet';
import Select, { onSelect } from 'components/element/Select';
import { NewSelectDetail } from 'components/SelectDetail';
import PriceComponent from 'components/PriceComponent';

interface Props {
  values: TransactionService[];
  onChange?: (transactionServices: TransactionService[]) => void;
}

export interface ServiceOption {
  extra: OutletService;
}

export default function OutletServiceInput(props: Props) {
  const { values, onChange } = props;
  const { t } = useTranslation();
  const { currentOutlet } = useAppState();
  const outletId = currentOutlet?.id || '';
  const [options, setOptions] = React.useState<ServiceOption[]>([]);
  const [
    selectedService,
    setSelectedService,
  ] = React.useState<ServiceOption | null>(null);

  useQueryGetOutletServices(
    outletId,
    React.useCallback((data) => {
      setOptions(
        data.outletServices.data.map((service: any) => ({
          label: service.name,
          value: service.id,
          extra: service,
        })),
      );
    }, []),
  );

  function onServiceAdded() {
    if (!!selectedService) {
      onChange &&
        onChange(
          values.concat({
            price: selectedService.extra.price,
            timeInMinutes: selectedService.extra.timeInMinutes,
            qty: 1,
            totalPrice: selectedService.extra.price,
            service: {
              id: selectedService.extra.id,
              name: selectedService.extra.name,
              imagePath: selectedService.extra.imagePath,
              price: selectedService.extra.price,
              timeInMinutes: selectedService.extra.timeInMinutes,
              point: selectedService.extra.point,
              isPriceList: selectedService.extra.isPriceList,
              regularPrice: selectedService.extra.regularPrice,
            },
          }),
        );
      setSelectedService(null);
    }
  }

  function onServiceRemoved(index: number) {
    onChange &&
      onChange(values.slice(0, index).concat(values.slice(index + 1)));
  }

  return (
    <>
      <H3Title>{t('common.service')}</H3Title>
      <>
        {values &&
          values.map((service, index) => {
            return (
              <ItemContainer>
                <ItemRow>
                  <RemoveButton onClick={() => onServiceRemoved(index)}>
                    -
                  </RemoveButton>
                  <Text>{service.service.name}</Text>
                </ItemRow>
                <PriceComponent
                  {...{
                    price: service.price,
                    isPriceList: service.service.isPriceList,
                    regularPrice: service.service.regularPrice,
                    isSelected: false,
                  }}
                />
              </ItemContainer>
            );
          })}
      </>
      <Select<ServiceOption>
        isMulti={false}
        options={options}
        components={{ SingleValue: ServiceSingleValue }}
        formatOptionLabel={NewSelectDetail}
        value={selectedService}
        onChange={onSelect(({ singleValue }) => {
          setSelectedService(singleValue || null);
        })}
        placeholder={t('booking.service_type')}
      />
      <MarginB8 />
      <AddButton type="button" onClick={onServiceAdded}>
        <PlusIcon width={25} height={25} />
        <Text>{t('common.service')}</Text>
      </AddButton>
    </>
  );
}
