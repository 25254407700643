export default {
  timeout:
    'Sepertinya server memberikan respon terlalu lama, kemungkinan ada masalah di koneksi internet atau dari server kita. Silahkan coba beberapa saat lagi.',
  network:
    'Kemungkinan ada masalah di koneksi internet atau dari server kita. Silahkan coba beberapa saat lagi.',

  eitherProductOrServiceIsRequired: 'Produk atau service wajib ada',

  something_went_wrong:
    'Terjadi kesalahan dari sisi kita. Team kita akan segera mengatasi masalah ini. Silahkan tekan F5 terlebih dahulu.',

  cancel_booking_sheet_error: 'Booking status tidak tersedia untuk status ini.',
  have_transaction_error: 'Booking sudah memiliki transaksi.',
};
