import * as React from 'react';
import { Container, Body } from './style';
import { Header, SideNav } from './component';
import { RouteNode } from 'containers/Router';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';
import ChooseOutletDialog from 'components/dialog/chooseOutletDialog';
import useDialog from 'hooks/useDialog';
import { useStateGetCurrentOutlet } from 'repositories/outlet';
import SyncDataComponent from 'components/SyncDataComponent';
import localForage from 'localforage';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';
import { useLocation } from 'react-router-dom';

interface Props {
  // children: React.ReactNode;
  routeNodes: RouteNode[];
}

const DefaultSyncData = (props: any) => null;

type SyncState = {
  isLoading: boolean;
  needSync?: boolean;
};

export default function Layout(props: Props) {
  const [syncState, setSyncState] = React.useState<SyncState>({
    isLoading: true,
  });
  const { t } = useTranslation();
  const dialog = useDialog();
  const location = useLocation();

  const { mode } = useNetworkStatus();

  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';

  let SyncData: any = DefaultSyncData;

  React.useEffect(() => {
    async function exec() {
      const bookings: any = await localForage.getItem('bookings');
      const transactions: any = await localForage.getItem('transactions');

      if (
        (!bookings && !transactions) ||
        (bookings &&
          bookings === 'undefined' &&
          transactions &&
          transactions === 'undefined')
      ) {
        setSyncState({
          needSync: false,
          isLoading: false,
        });
      } else {
        setSyncState({
          needSync: mode ? true : false,
          isLoading: false,
        });
      }
    }

    exec();
  }, [mode]);

  if (!syncState.isLoading) {
    SyncData = SyncDataComponent;
  }

  React.useEffect(() => {
    if (!outletId && !syncState.isLoading) {
      dialog.showCustom({
        render(onClose) {
          return <ChooseOutletDialog onClose={onClose} />;
        },
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const route = props.routeNodes.find(
    (route) => route.path === location.pathname,
  );

  return (
    <Container whiteBg={syncState.isLoading}>
      <SyncData needSync={syncState.needSync}>
        <SideNav navigation={props.routeNodes} />
        <Body>
          {route && <Header title={t(route.name)} />}
          <Outlet />
        </Body>
      </SyncData>
    </Container>
  );
}
