import styled from 'styled-components';
import color from 'styles/color';
import AsyncSelect from 'react-select/async';
import { Button, H3 } from 'components/element';

export const Container = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Separator = styled.div`
  margin-bottom: 48px;
  width: 100%;
  border-top: 2px solid ${color.neutralSpindle};
`;

export const StyledAsyncSelect = styled(AsyncSelect)`
  &.react-select__container {
    width: 350px;
    .react-select__control {
      border-radius: 10px;
      min-height: 50px;
      border: 1px solid ${color.black};
    }
  }
`;

export const CustomButton = styled(Button)`
  width: 170px;
  background-color: ${color.white};
  color: ${color.black};
`;

export const StyledH3 = styled(H3)`
`;

export const Divider = styled.div`
  height: 16px;
`;
