import styled from 'styled-components';
import size from 'styles/size';
export { default as Select } from './SimpleSelect';
export { default as TextInput } from './TextInput';
export { default as Button } from './Button';
export { default as FormField } from './FormField';
export { default as TableComponent } from './Table';
export { default as Field } from './Field';
export { default as Form } from './Form';

export const H1 = styled.h1`
  font-size: ${size.inputH1};
  font-weight: bold;
`;

export const H2 = styled.h2`
  font-size: ${size.inputH2};
  font-weight: bold;
`;

export const H3 = styled.h3`
  font-size: ${size.inputH3};
  font-weight: bold;
`;

export const H4 = styled.h4`
  font-size: ${size.inputH4};
  font-weight: bold;
`;

export const Div = styled.div``;

export const P = styled.p``;
