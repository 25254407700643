import styled from 'styled-components';
import { P } from './element';
import color from 'styles/color';

export const Price = styled(P)<{
  isSelected: boolean;
  fontSize?: 'default' | 'small';
}>`
  color: ${(props) =>
    props.isSelected ? color.neutralSpindle : `${color.black}90`};
  font-size: ${(props) => (props.fontSize === 'default' ? '14px' : '8px')};
`;

export const StrikePrice = styled(P)<{
  fontSize?: 'default' | 'small';
}>`
  color: ${color.neutralSpindle};
  font-size: ${(props) => (props.fontSize === 'default' ? '12px' : '8px')};
  text-decoration: line-through;
  margin-left: 8px;
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ProductPrice = styled(P)`
  color: ${color.neutralSpindle};
  font-size: 8px;
`;
