import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as yup from 'yup';
import id from './id';
import idValidation from './id/validation.yup';

yup.setLocale(idValidation);

i18n.use(initReactI18next).init({
  resources: {
    id: {
      translation: { ...id },
    },
  },
  lng: 'id',
  fallbackLng: 'id',
  interpolation: {
    escapeValue: false,
  },
  ns: ['translation'],
  defaultNS: 'translation',
});

export default i18n;
