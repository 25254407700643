import styled, { css } from 'styled-components';
import color from 'styles/color';
import ReactSelect from 'react-select';
import { Button, H4, H3, TextInput, H1 } from '../../components/element';
import size from 'styles/size';
import { Paper } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${color.border};
  margin-bottom: 1rem;
`;

export const StyledReactSelect = styled(ReactSelect)`
  &.react-select__container {
    width: 150px;
    .react-select__control {
      border-radius: 20px;
      min-height: 30px;
      border: 1px solid ${color.black};
    }
  }
`;

export const DetailContainer = styled.div`
  width: 50%;
  margin-right: 8px;
`;

export const SelectButton = styled(Button)`
  color: ${color.white};
  background-color: ${color.black};
  width: 160px;
  min-height: 30px;
  outline: none;
  font-weight: bold;
  font-size: 20px;
  margin-left: 20px;
  border: none;
  border-radius: 20px;
  display: block;
  overflow-wrap: break-word;
  border-right: 1px solid ${color.charcoal4};
`;

export const HeaderRightContainer = styled.div`
  min-height: 30px;
  padding-bottom: 1rem;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
`;

export const Body = styled.div`
  padding: 5px 6px 5px 2px;
  display: flex;
  width: 100%;
  max-width: 100%;
  flex: 1;
  flex-direction: row;
  overflow: hidden;
`;

// workaround to handle virtualized table not shrinking
export const LeftContainer = styled.div`
  min-width: 1px;
  flex: 1;
`;

export const RightContainer = styled.div`
  display: flex;
  margin-left: 20px;
  max-width: 400px;
`;

export const TransactionContainer = styled(Paper)`
  flex: 1;
  padding: 16px;
  min-width: 400px;
  position: relative;
  border-radius: 5px;
  overflow: scroll;
`;

export const TransactionRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

export const TransactionContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const H4Title = styled(H4)`
  margin-bottom: 0.3rem;
`;

export const H3Title = styled(H3)`
  margin-bottom: 1rem;
  padding-bottom: 0.3rem;
  display: flex;
  border-bottom: 1px solid ${color.black};
`;

export const Separator = styled.div`
  border-bottom: 1px solid ${color.border};
  width: 100%;
  margin: 1rem 0;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 0.3rem;
`;

export const PriceContainer = styled.div`
  width: 100%;
  margin-bottom: 0.7rem;
`;

export const AddButton = styled(Button)`
  background-color: ${color.white};
  border: 1px solid ${color.white};
  color: black;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: ${size.inputH4};
  font-weight: bold;
  outline: none;
  padding: 0;
  width: auto;
  border-radius: ${size.inputBorder};
  min-height: 30px;
  white-space: nowrap;
  margin-bottom: 0.5rem;
  text-align: center;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
  &:disabled {
    opacity: 0.4;
  }
`;

export const Text = styled.p`
  margin-left: 0.5rem;
`;

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Space = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.5rem;
  align-items: center;
`;

export const CancelButton = styled(Button)`
  background-color: white;
  color: ${color.redOrange1};
  border-color: ${color.redOrange1};
`;

export const MB16 = styled.div`
  margin-bottom: 1rem;
`;

export const ServiceSelect = styled(ReactSelect)`
  &.react-select__container {
    width: 100%;
    margin-bottom: 16px;

    .react-select__control {
      border-radius: ${size.inputBorder};
      min-height: ${size.inputMinHeight};
      border: 1px solid ${color.border};

      &--is-focused {
        border-color: ${color.accent};
        box-shadow: none;
      }
    }
  }
  & .react-select__indicator-separator {
    display: none;
  }
  & .react-select__indicator {
    padding-right: ${size.inputPadding};
  }
  & .react-select__value-container {
    font-weight: bold;
    color: ${color.accent};
    padding: 0px ${size.inputPadding};
  }
  & .react-select__option--is-selected {
    background-color: ${color.accent};
  }
  & .react-select__placeholder {
    color: ${color.placeholder};
  }
`;

export const PaymentSelect = styled(ReactSelect)`
  &.react-select__container {
    width: 100%;
    margin-bottom: 16px;

    .react-select__control {
      border-radius: ${size.inputBorder};
      min-height: ${size.inputMinHeight};
      border: 1px solid ${color.border};

      &--is-focused {
        border-color: ${color.accent};
        box-shadow: none;
      }
    }
  }
  & .react-select__indicator-separator {
    display: none;
  }
  & .react-select__indicator {
    padding-right: ${size.inputPadding};
  }
  & .react-select__value-container {
    font-weight: bold;
    color: ${color.accent};
    padding: 0px ${size.inputPadding};
  }
  & .react-select__option--is-selected {
    background-color: ${color.accent};
  }
  & .react-select__placeholder {
    color: ${color.placeholder};
  }
`;

export const ProductSelect = styled(ReactSelect)`
  &.react-select__container {
    width: 100%;
    margin-bottom: 16px;

    .react-select__control {
      border-radius: ${size.inputBorder};
      min-height: ${size.inputMinHeight};
      border: 1px solid ${color.border};

      &--is-focused {
        border-color: ${color.accent};
        box-shadow: none;
      }
    }
  }
  & .react-select__indicator-separator {
    display: none;
  }
  & .react-select__indicator {
    padding-right: ${size.inputPadding};
  }
  & .react-select__value-container {
    font-weight: bold;
    color: ${color.accent};
    padding: 0px ${size.inputPadding};
  }
  & .react-select__option--is-selected {
    background-color: ${color.accent};
  }
  & .react-select__placeholder {
    color: ${color.placeholder};
  }
`;

export const ProductQuantity = styled(TextInput)`
  height: 48px;
  margin-left: 4px;
  width: 120px;
`;

export const CustomInput = styled(TextInput)<{ mode?: boolean }>`
  height: 48px;
  flex-grow: 1;
  flex-shrink: 1;
  border-radius: 5px 0 0 5px;

  ${(props) =>
    !props.mode &&
    css`
      border-radius: 5px;
    `}
`;

export const ProductRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
  justify-content: space-between;
`;

export const RemoveButton = styled.div`
  padding-bottom: 1px;
  border: 2px solid ${color.black};
  color: ${color.black};
  border-radius: 50%;
  font-size: 20px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const QuantityButton = styled.div`
  padding-bottom: 1px;
  border: 2px solid ${color.black};
  color: ${color.black};
  border-radius: 50%;
  font-size: 25px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  margin: 0 0.5rem;
`;

export const CustomContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 0.3rem;
`;

export const CheckButton = styled(Button)`
  width: 100px;
  border-radius: 0 5px 5px 0;
`;

export const StatusText = styled.p<{ available: boolean }>`
  color: ${(props) =>
    props.available ? `${color.fern1}` : `${color.redOrange1}`};
  margin-bottom: 0.7rem;
`;

export const H1Title = styled(H1)`
  text-align: center;
  margin-bottom: 1rem;
`;

export const Divider = styled.div`
  margin-bottom: 1rem;
`;

export const CheckboxInput = styled.input``;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const MarginB8 = styled.div`
  margin-bottom: 0.5rem;
`;

export const CancelNoteTextArea = styled.textarea`
  margin-top: 0.5rem;
  padding: 0.5rem;
  min-height: 60px;
  min-width: 100%;
  max-width: 100%;
`;

export const ConfirmCancelButton = styled(Button)`
  background-color: ${color.redOrange1};
  border: 1px solid ${color.redOrange1};
`;

export const ButtonPad = styled.div`
  margin-bottom: 1rem;
`;

export const Label = styled.span`
  margin-bottom: 4px;
  font-weight: 'bold';
`;
