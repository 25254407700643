import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { OldMember } from './member.model';

const GET_OLD_MEMBERS = gql`
  mutation getOldMembers($phoneNumber: String!) {
    oldMembers(phoneNumber: $phoneNumber)
      @rest(
        type: "OldMembersPayload"
        method: "GET"
        path: "/old-members?phone_number={args.phoneNumber}"
      ) {
      data {
        userId
        name
        phoneNumber
        email
      }
    }
  }
`;

const GET_OLD_MEMBER = gql`
  query getOldMember($id: String!) {
    oldMember(id: $id)
      @rest(type: "OldMembersPayload", path: "/old-members/{args.id}") {
      data {
        userId
        point
        pointRate
        name
        phoneNumber
        email
      }
    }
  }
`;

export function useQueryGetOldMembers() {
  return useMutation<{
    oldMembers: {
      data: {
        userId: string;
        name: string;
        phoneNumber: string;
        email: string;
      }[];
    };
  }>(GET_OLD_MEMBERS);
}

export function useQueryGetOldMember() {
  return useLazyQuery<{
    oldMember: {
      data: OldMember;
    };
  }>(GET_OLD_MEMBER);
}
