import i18n from 'locales';
import { getOutletAliasKey } from 'utils/storage';
import * as Sentry from '@sentry/browser';
import * as OTPAuth from 'otpauth';

function verifyToken(options: {
  token: string;
  secretKey: string;
  window: number;
  step?: number;
}): boolean {
  const { token, secretKey, window, step = 30 } = options;
  const currentTime = new Date().getTime();

  const totp = new OTPAuth.TOTP({
    issuer: 'sirsalon.co.id',
    label: 'Sir Salon',
    algorithm: 'SHA1',
    digits: 6,
    period: 30,
    secret: secretKey,
  });

  const verify = totp.validate({ token, window, timestamp: currentTime });

  if (verify !== null) {
    return true;
  }

  return false;
}

export async function authorizeWithTotp(): Promise<boolean> {
  const pin = prompt(i18n.t('common.please_enter_pin'));
  try {
    const outletAliasKey = await getOutletAliasKey();
    if (!outletAliasKey) {
      return false;
    }
    return verifyToken({
      token: pin || '',
      secretKey: outletAliasKey || '',
      window: 10,
    });
  } catch (e) {
    Sentry.captureException(e);
    return false;
  }
}
