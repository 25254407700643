import * as React from 'react';
import firebase from 'firebase';

export interface RecaptchaHandler {
  recaptchaVerifier: firebase.auth.RecaptchaVerifier | undefined;
}

function RecaptchaContainer(props: any, ref: React.Ref<RecaptchaHandler>) {
  const verifier = React.useRef<firebase.auth.RecaptchaVerifier>();

  React.useLayoutEffect(() => {
    verifier.current = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      },
    );
  }, []);

  React.useImperativeHandle(
    ref,
    () => ({
      recaptchaVerifier: verifier.current,
    }),
    [verifier],
  );

  return <div id="recaptcha-container" />;
}

export default React.forwardRef(RecaptchaContainer);
