import { gql } from '@apollo/client';

export const OUTLET_FRAGMENT = gql`
  fragment outletFragment on Outlet {
    id
    name
    phoneNumber
    description
    address
    timezone
    longtitude
    latitude
    rate
    rateCount
    totpSecret
    kioskUsername
    outletImages @type(name: "OutletImage") {
      id
      index
      filePath
    }
  }
`;

export const ADMIN_OUTLET_FRAGMENT = gql`
  fragment outletFragment on AdminOutlet {
    id
    name
    phoneNumber
    description
    address
    timezone
    longtitude
    latitude
    rate
    rateCount
    outletAlias
    outletImages @type(name: "OutletImage") {
      id
      index
      filePath
    }
  }
`;
