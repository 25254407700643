import { gql, useQuery } from '@apollo/client';

export const GET_LOGIN_TOKEN = gql`
  {
    loginToken @client
  }
`;

const GET_ME = gql`
  query getMe {
    user @rest(type: "UserPayload", path: "/admins/me") {
      data {
        name
        email
      }
    }
  }
`;

export function useQueryGetMe() {
  return useQuery<{
    user: {
      data: {
        name: string;
        email: string;
      };
    };
  }>(GET_ME);
}

export function useStateGetLoginToken() {
  return useQuery<{ loginToken: string | null }>(GET_LOGIN_TOKEN);
}

export function useIsAuthenticated(): boolean {
  const { data } = useStateGetLoginToken();
  return !!data?.loginToken;
}
