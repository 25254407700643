import { gql, useMutation } from '@apollo/client';
import { Voucher } from './voucher.model';

const POST_CHECK_VOUCHER = gql`
  fragment CheckVoucherInput on REST {
    code: String
    member_id: String
    outlet_id: String
    service_id: any
    products: any
    transaction_at: String
  }

  mutation voucher($payload: CheckVoucherInput!) {
    voucher(payload: $payload)
      @rest(
        type: "VoucherPayload"
        path: "/vouchers/check"
        method: "POST"
        bodyKey: "payload"
      ) {
      data @type(name: "Voucher") {
        id
        code
        startAt
        validUntil
        calculatedDiscount
        description
        discountType
        discountValue
        maxDiscountAmount
        limit
        restLimit
        category
        isAvailable
        createdAt
        updatedAt
        transactionCount
      }
    }
  }
`;

export function useMutationCheckVoucher() {
  return useMutation<
    { voucher: { data: Voucher } },
    {
      payload: {
        code: string;
        member_id?: string;
        outlet_id: string;
        transaction_at?: string;
      };
    }
  >(POST_CHECK_VOUCHER);
}
