import * as React from 'react';
import { Transaction } from 'repositories/transaction/transaction.model';
import DialogBackdrop from 'components/dialog/dialogBackdrop';
import {
  CloseButton,
  Panel,
} from 'pages/bookingManagement/components/AddBooking';
import TransactionSuccessPreview from 'pages/transactionManagement/components/TransactionSuccessPreview';

interface Props {
  transaction: Transaction;
  onClose: () => void;
}

export default function TransactionSuccessDialog(props: Props) {
  return (
    <DialogBackdrop onClose={props.onClose} cancelable={false}>
      <Panel>
        <TransactionSuccessPreview
          transaction={props.transaction}
          onBeforePrint={props.onClose}
        />
        <CloseButton onClick={props.onClose}>x</CloseButton>
      </Panel>
    </DialogBackdrop>
  );
}
