import { gql } from '@apollo/client';

export const PRODUCT_FRAGMENT = gql`
  fragment Product on Product {
    id
    code
    name
    description
    imagePath
    price
    point
    isAvailable
    updatedAt
    createdAt
    pivot {
      outletId
      productId
      qty
      isAvailable
      price
      point
    }
  }
`;
