export default {
  // Panel
  panelBorder: '1.5rem',

  // Form Field
  formFieldSpace: '1rem',

  // Input
  inputBorder: '5px',
  inputMinHeight: '48px',
  inputPadding: '1rem',

  inputH1: '2rem',
  inputH2: '1.5em',
  inputH3: '1.25rem',
  inputH4: '1rem',

  body: '1rem',
};
