import { gql, useMutation } from '@apollo/client';
import { ApiResult } from 'repositories';

const POST_MIGRATE_POINT = gql`
  fragment migratePointPayload on REST {
    verification_token: String
  }

  mutation postMigratePoint($payload: migratePointPayload!, $userId: string!) {
    migratePoint(payload: $payload, userId: $userId)
      @rest(
        type: "MigratePointPayload"
        method: "POST"
        path: "/old-members/{args.userId}/migrate"
        bodyKey: "payload"
      ) {
      data
      message
    }
  }
`;

export function useMutationMigratePoint() {
  return useMutation<{
    migratePoint: ApiResult<any>;
  }>(POST_MIGRATE_POINT);
}
