import * as React from 'react';
import styled from 'styled-components';

//@ts-ignore
import Zoom from 'react-reveal/Zoom';

interface Props {
  cancelable: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export default function DialogBackdrop(props: Props) {
  const { children, onClose, cancelable } = props;

  const onCancel = React.useCallback(() => {
    if (cancelable) {
      onClose();
    }
  }, [cancelable, onClose]);

  return (
    <BackdropContainer onClick={onCancel}>
      <Zoom duration={300} big>
        {children}
      </Zoom>
    </BackdropContainer>
  );
}

const BackdropContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  z-index: 1000;
  justify-content: center;
  align-items: center;
`;
