import * as React from 'react';
import { Container, HeaderRightContainer, SwitchContainer } from './style';
import {
  Header,
  BarButton,
  DateFilter,
  SelectButton,
} from 'pages/bookingManagement/style';
import { useTranslation } from 'react-i18next';
import { TableComponent, TextInput } from 'components/element';
import { LeftContainer, Body } from './style';
import TransactionComponent from './components/transaction';
import TransactionDetailComponent from './components/transactionDetail';
import NewTransaction from './components/newTransaction';
import { useQueryGetBookings } from 'repositories/booking/booking.query';
import { useStateGetCurrentOutlet } from 'repositories/outlet/outlet.query';
import { format } from 'date-fns';
import { useQueryGetTransactions, Transaction } from 'repositories/transaction';
import { Booking } from 'repositories/booking/booking.model';
import { getBookingStatus } from 'helpers/getStatus';
import Status from 'components/Status';
import useDialog from 'hooks/useDialog';
import { getSource } from 'helpers/getSource';
import TransactionSuccessDialog from './components/TransactionSuccessDialog';
import { useDebounce } from 'use-debounce';
import DateInput from 'components/element/DateInput';

export default function TransactionManagement(props: any) {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [date, setDate] = React.useState(new Date());
  const [selection, setSelection] = React.useState<any[]>([]);
  const [completedSelection, setCompletedSelection] = React.useState<any[]>([]);
  const [cancelledSelection, setCancelledSelection] = React.useState<any[]>([]);
  const dialog = useDialog();

  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';

  const [customerFilter, setCustomerFilter] = React.useState<
    string | undefined
  >();

  const [debouncedCustomer] = useDebounce(customerFilter, 1000);

  React.useEffect(() => {
    setDate(new Date());
  }, [outletId]);

  const [loadBookings, { called, data }] = useQueryGetBookings(
    outletId,
    format(date, 'yyyy-MM-dd'),
    '1,2,3',
    '',
    undefined,
    1,
  );

  const [
    loadCompletedTranscations,
    completedTransaction,
  ] = useQueryGetTransactions(outletId, format(date, 'yyyy-MM-dd'), '2');

  const [
    loadCancelledTranscations,
    cancelledTransaction,
  ] = useQueryGetTransactions(outletId, format(date, 'yyyy-MM-dd'), '8,9');

  React.useEffect(() => {
    if (outletId && !completedTransaction.called && !called) {
      loadBookings();
      loadCompletedTranscations();
      loadCancelledTranscations();
    }
  }, [
    called,
    completedTransaction.called,
    loadBookings,
    loadCancelledTranscations,
    loadCompletedTranscations,
    outletId,
  ]);

  const completedTransactionData = React.useMemo(() => {
    return completedTransaction.data
      ? completedTransaction.data.transactions.data
      : [];
  }, [completedTransaction.data]);

  const cancelledTransactionData = React.useMemo(() => {
    return cancelledTransaction.data
      ? cancelledTransaction.data.transactions.data
      : [];
  }, [cancelledTransaction.data]);

  // const afterResponse = React.useCallback(() => {
  //   setContent(undefined);
  // }, []);

  const completedBookingData = React.useMemo(() => {
    if (debouncedCustomer) {
      if (data) {
        let options = data.bookings.data;

        if (debouncedCustomer) {
          options = options.filter(
            (cur) =>
              `${cur?.member?.name}`
                .toLowerCase()
                .includes(debouncedCustomer.toLowerCase()) ||
              `${cur?.member?.name}`
                .toLowerCase()
                .includes(debouncedCustomer.toLowerCase()),
          );
        }

        return options;
      } else {
        return [];
      }
    } else {
      return data ? data.bookings.data : [];
    }
  }, [data, debouncedCustomer]);

  const completedTransactionDataFiltered = React.useMemo(() => {
    if (debouncedCustomer) {
      if (data) {
        let options = completedTransactionData;

        if (debouncedCustomer) {
          options = options.filter(
            (cur) =>
              `${cur?.member?.name}`
                .toLowerCase()
                .includes(debouncedCustomer.toLowerCase()) ||
              `${cur?.member?.name}`
                .toLowerCase()
                .includes(debouncedCustomer.toLowerCase()),
          );
        }

        return options;
      } else {
        return [];
      }
    } else {
      return completedTransactionData;
    }
  }, [completedTransactionData, data, debouncedCustomer]);

  const cancelledTransactionDataFiltered = React.useMemo(() => {
    if (debouncedCustomer) {
      if (data) {
        let options = cancelledTransactionData;

        if (debouncedCustomer) {
          options = options.filter(
            (cur) =>
              `${cur?.member?.name}`
                .toLowerCase()
                .includes(debouncedCustomer.toLowerCase()) ||
              `${cur?.member?.name}`
                .toLowerCase()
                .includes(debouncedCustomer.toLowerCase()),
          );
        }

        return options;
      } else {
        return [];
      }
    } else {
      return cancelledTransactionData;
    }
  }, [cancelledTransactionData, data, debouncedCustomer]);

  React.useEffect(() => {
    setCompletedSelection([]);
    setSelection([]);
  }, [page]);

  const onCompletedSelectionChange = (params: React.ReactText[]) => {
    if (params[params.length - 1] !== undefined) {
      const transaction =
        completedTransactionDataFiltered &&
        completedTransactionDataFiltered[params[params.length - 1] as any];
      const transactionId =
        completedTransactionDataFiltered &&
        completedTransactionDataFiltered[params[params.length - 1] as any].id;

      dialog.showCustom({
        render(onClose) {
          return (
            <TransactionDetailComponent
              transactionData={transaction}
              transactionId={transactionId || ''}
              onClose={() => {
                onClose();
                setCompletedSelection([]);
              }}
            />
          );
        },
      });

      setCompletedSelection([params[params.length - 1]]);
    }
  };

  const onCancelledSelectionChange = (params: React.ReactText[]) => {
    if (params[params.length - 1] !== undefined) {
      const transaction =
        cancelledTransactionDataFiltered &&
        cancelledTransactionDataFiltered[params[params.length - 1] as any];
      const transactionId =
        cancelledTransactionDataFiltered &&
        cancelledTransactionDataFiltered[params[params.length - 1] as any].id;

      dialog.showCustom({
        render(onClose) {
          return (
            <TransactionDetailComponent
              transactionData={transaction}
              transactionId={transactionId || ''}
              onClose={() => {
                onClose();
                setCompletedSelection([]);
              }}
            />
          );
        },
      });

      setCompletedSelection([params[params.length - 1]]);
    }
  };

  const afterResponse = React.useCallback(
    (onClose: () => void, transaction: Transaction) => {
      onClose();
      dialog.showCustom({
        render(onClose) {
          return (
            <TransactionSuccessDialog
              transaction={transaction}
              onClose={onClose}
            />
          );
        },
      });
    },
    [dialog],
  );

  const onSelectionChange = (params: React.ReactText[]) => {
    if (params[params.length - 1] !== undefined) {
      const booking =
        completedBookingData &&
        completedBookingData[params[params.length - 1] as any];

      if (booking) {
        dialog.showCustom({
          render(onClose) {
            return (
              <TransactionComponent
                bookingData={booking}
                bookingId={booking!.id || ''}
                date={date}
                afterResponse={afterResponse}
                onClose={() => {
                  onClose();
                  setSelection([]);
                }}
              />
            );
          },
        });
      }

      setSelection([params[params.length - 1]]);
    }
  };

  const onDateChange = React.useCallback((date: any) => {
    setDate(date);
  }, []);

  const unProcessedColumn = React.useMemo(
    () => [
      {
        name: 'date',
        title: t('management.name'),
        getCellValue: (row: Booking) => (row.member ? row.member.name : ''),
      },
      {
        name: 'totalService',
        title: t('management.status'),
        getCellValue: (row: Booking) => {
          const bookingStatus = getBookingStatus(row.status, t);
          return (
            <Status text={bookingStatus.text} color={bookingStatus.color} />
          );
        },
      },
      {
        name: 'totalProduct',
        title: t('management.source'),
        getCellValue: (row: Booking) => {
          const bookingType = getSource(row.type, t);
          return <Status text={bookingType.text} color={bookingType.color} />;
        },
      },
    ],
    [t],
  );

  const completedTransactionColumn = React.useMemo(
    () => [
      {
        name: 'number',
        title: t('common.number'),
        getCellValue: (row: Transaction) => {
          const index =
            page === 1
              ? completedTransactionData.indexOf(row)
              : cancelledTransactionData.indexOf(row);

          return index + 1;
        },
      },
      {
        name: 'name',
        title: t('management.name'),
        getCellValue: (row: Transaction) => (row.member ? row.member.name : ''),
      },
      {
        name: 'hour',
        title: t('common.hour'),
        getCellValue: (row: Transaction) => {
          const date = new Date(row.transactionAt);
          return `${('0' + date.getHours()).slice(-2)} : ${(
            '0' + date.getMinutes()
          ).slice(-2)}`;
        },
      },
      {
        name: 'paymentMethod',
        title: t('common.payment_method'),
        getCellValue: (row: Transaction) =>
          row.transactionPayment.paymentName || '-',
      },
      {
        name: 'totalTransaction',
        title: t('transaction.total_payment'),
        getCellValue: (row: Transaction) => row.totalPay,
      },
      {
        name: 'voucher',
        title: t('common.voucher_code'),
        getCellValue: (row: Transaction) =>
          row.voucher ? row.voucher.code : '-',
      },
      {
        name: 'status',
        title: t('management.status'),
        getCellValue: (row: Transaction) => {
          if (row.status === 1) {
            return t('status.created');
          } else if (row.status === 2) {
            return t('status.paid');
          } else if (row.status === 8) {
            return t('status.refund');
          } else if (row.status === 9) {
            return t('status.cancelled');
          }
        },
      },
      {
        name: 'inputBy',
        title: t('management.input_by'),
        getCellValue: (row: Transaction) => row.inputBy.email,
      },
      {
        name: 'updateBy',
        title: t('management.update_by'),
        getCellValue: (row: Transaction) =>
          row.updateBy ? row.updateBy.email : '',
      },
    ],
    [cancelledTransactionData, completedTransactionData, page, t],
  );

  const onClickPlusTransaction = React.useCallback(() => {
    dialog.showCustom({
      render(onClose) {
        return <NewTransaction onClose={onClose} date={date} />;
      },
    });
    setSelection([]);
  }, [dialog, date]);

  const onPageChange = React.useCallback((page: number) => {
    setPage(page);
    setCustomerFilter(undefined);
  }, []);

  return (
    <Container>
      <Header>
        <SwitchContainer>
          <BarButton active={page === 0} onClick={() => onPageChange(0)}>
            {t('common.not_completed')}
          </BarButton>
          <BarButton active={page === 1} onClick={() => onPageChange(1)}>
            {t('common.completed')}
          </BarButton>
          <BarButton active={page === 2} onClick={() => onPageChange(2)}>
            {t('common.cancel')}
          </BarButton>
        </SwitchContainer>
        <HeaderRightContainer>
          <DateFilter
            selected={date}
            onChange={onDateChange}
            dateFormat="yyyy-MM-dd"
            customInput={<DateInput />}
            // onFocus={(e) => (e.target.readOnly = true)}
            // onBlur={(e) => (e.target.readOnly = false)}
          />
          <SelectButton disabled={!date} onClick={onClickPlusTransaction}>
            <span>+</span> {t('common.transaction')}
          </SelectButton>
        </HeaderRightContainer>
      </Header>
      <TextInput
        clearable
        placeholder={t('common.customer')}
        value={customerFilter || ''}
        style={{
          width: 200,
          minHeight: 'unset',
          height: 38,
          marginBottom: 16,
        }}
        onChange={(e: any) => setCustomerFilter(e.currentTarget.value)}
      />
      <Body>
        <LeftContainer>
          {page === 0 && (
            <TableComponent
              rows={completedBookingData || []}
              columns={unProcessedColumn}
              selection={selection}
              onSelectionChange={onSelectionChange}
              enableSelection
            />
          )}
          {page === 1 && (
            <TableComponent
              rows={completedTransactionDataFiltered}
              columns={completedTransactionColumn}
              selection={completedSelection}
              onSelectionChange={onCompletedSelectionChange}
              enableSelection
            />
          )}
          {page === 2 && (
            <TableComponent
              rows={cancelledTransactionDataFiltered}
              columns={completedTransactionColumn}
              selection={cancelledSelection}
              onSelectionChange={onCancelledSelectionChange}
              enableSelection
            />
          )}
        </LeftContainer>
      </Body>
    </Container>
  );
}
