export default {
  total_service: 'Total Treatment',
  total_product: 'Total Produk',
  total_sale: 'Total Penjualan',
  total_discount: 'Total Discount',
  total_payment: 'Total Bayar',
  total_pending: 'Total Pending',
  voucher: 'Voucher',
  booking_id: 'Id Pemesanan',
  total_pay: 'Total Harga',
  sub_total: 'Sub Total',
  stylist: 'Stylist',
  discount: 'Discount',
  change_amount: 'Kembalian',
  add_transaction: 'Tambah Transaksi',
  choose_payment_method: 'Pilih metode pembayaran',
  input_member_otp: 'Masukkan OTP Member',
  optional_promo_code: 'Kode Promo (Optional)',
  transaction_created_successfully: 'Transaksi berhasil dibuat',
  receipt_footnote:
    'Terima kasih telah berkunjung<br>Kami berharap dapat bertemu<br>dengan anda kembali',

  service: 'treatment',
  pieces: 'pcs',
  transaction_recap: 'Rekap Transaksi',
  service_total_quantity: 'Total Kuantitas Treatment',
  product_total_quantity: 'Total Kuantitas Produk',
  total_payment_1: 'Total Pembayaran',
  print_at: 'Waktu Print: {{date}}',

  status: 'Status',
  refund_status: 'Refund',
  cancel_status: 'Cancel',
  cancel_success: 'Berhasil update status transaksi',
  total_refund: 'Total Refund',
};
