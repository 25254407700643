import * as React from 'react';
import DialogBackdrop from 'components/dialog/dialogBackdrop';
import { HeaderPadding } from 'components/dialog/styles';
import {
  HaircutContainer,
  PreviousContainer,
  ImageContainer,
  ImageRatioContainer,
  PreviousImage,
  ImagePreviewContainer,
  LabelText,
  ValueText,
  ModelText,
  DateText,
  Separator8,
} from './styles';
import { CloseButton } from 'pages/bookingManagement/components/AddBooking';
import { useQueryGetTransactionResult } from 'repositories/transaction/transaction.query';
import { useThrottledCallback } from 'use-debounce';
import styled from 'styled-components';
import LoadingView from 'components/LoadingView';
import { Button } from 'components/element';

interface Props {
  memberId: string;
  onClose: () => void;
}

interface PreviousProps {
  transactionAt: string;
  booking: any;
}

function concatNewData(prevData: any, newData: any) {
  return {
    transactionResult: {
      data: prevData.transactionResult.data.concat(
        newData.transactionResult.data,
      ),
      meta: newData.transactionResult.meta,
      filters: newData.transactionResult.filters,
      sorts: newData.transactionResult.sorts,
    },
  };
}

function PreviousHairCut(props: PreviousProps) {
  const { booking, transactionAt } = props;

  const SidebackOptions = React.useMemo(() => {
    if (booking) {
      return booking?.haircut?.sideback?.reduce(
        (prev: any, cur: any) => {
          return (prev || []).concat(...cur.items);
        },
        [] as {
          id: string;
          value: string;
        }[],
      );
    }

    return [];
  }, [booking]);

  const TopOptions = React.useMemo(() => {
    if (booking) {
      return booking?.haircut?.top?.reduce(
        (prev: any, cur: any) => {
          return (prev || []).concat(cur.items);
        },
        [] as {
          id: string;
          value: string;
        }[],
      );
    }

    return [];
  }, [booking]);

  const currentSide = React.useMemo(() => {
    if (SidebackOptions) {
      return (
        SidebackOptions.find(
          (side: any) => side.id === booking.sideHaircutModel,
        )?.value || ''
      );
    }

    return '';
  }, [SidebackOptions, booking.sideHaircutModel]);

  const currentTop = React.useMemo(() => {
    if (SidebackOptions) {
      return (
        TopOptions.find((top: any) => top.id === booking.topHaircutModel)
          ?.value || ''
      );
    }

    return '';
  }, [SidebackOptions, TopOptions, booking.topHaircutModel]);

  const currentHairOil = React.useMemo(() => {
    if (booking.hairOil) {
      return (
        booking?.haircut?.hairoil?.find(
          (top: any) => top.id === booking.hairOil,
        )?.value || ''
      );
    }

    return '-';
  }, [booking]);

  const photoAngleObj = React.useMemo(() => {
    return booking?.photoAngles?.reduce((prev: any, next: any) => {
      return {
        ...prev,
        [next.id]: [next.value],
      };
    }, {});
  }, [booking]);

  return (
    <>
      <DateText>{transactionAt}</DateText>
      <Separator8 />
      <PreviousContainer>
        <DetailInformationContainer>
          <DetailTopInformationContainer>
            <ValueText>{`Stylist: ${booking?.stylist?.name || '-'}`}</ValueText>
            <ValueText>{`Outlet: ${booking?.outlet?.name}`}</ValueText>
          </DetailTopInformationContainer>
          <InfoSectionContainer>
            <Section>
              <InfoSection>
                <InfoLeftSection>
                  <LabelText>Treatment :</LabelText>
                </InfoLeftSection>
                <InfoRightSection>
                  <LabelText>
                    {booking?.services
                      ?.map((service: any) => `(x1) ${service?.service?.name}`)
                      .join(', ')}
                  </LabelText>
                </InfoRightSection>
              </InfoSection>
            </Section>
          </InfoSectionContainer>
          <InfoSectionContainer>
            <InfoSection>
              <InfoLeftSection>
                <LabelText>Sideback / Back Haircut Model :</LabelText>
              </InfoLeftSection>
              <InfoRightSection>
                <LabelText>{currentSide}</LabelText>
              </InfoRightSection>
            </InfoSection>
          </InfoSectionContainer>
          <InfoSectionContainer>
            <InfoSection>
              <InfoLeftSection>
                <LabelText>Top Haircut Model :</LabelText>
              </InfoLeftSection>
              <InfoRightSection>
                <LabelText>{currentTop}</LabelText>
              </InfoRightSection>
            </InfoSection>
          </InfoSectionContainer>
          <InfoSectionContainer>
            <InfoSection>
              <InfoLeftSection>
                <LabelText>Hair Oil :</LabelText>
              </InfoLeftSection>
              <InfoRightSection>
                <LabelText>{currentHairOil}</LabelText>
              </InfoRightSection>
            </InfoSection>
          </InfoSectionContainer>
        </DetailInformationContainer>
        <ImagePreviewContainer>
          {booking.files.map((file: any) => (
            <ImageContainer onClick={() => window.open(file?.fileUrl)}>
              <ImageRatioContainer>
                <PreviousImage src={file?.fileUrl} alt="haircut" />
              </ImageRatioContainer>
              <PhotoText>{photoAngleObj[file.type]}</PhotoText>
            </ImageContainer>
          ))}
        </ImagePreviewContainer>
      </PreviousContainer>
    </>
  );
}

export default function ShowHaircutDialog(props: Props) {
  const { onClose, memberId } = props;

  const {
    loading,
    data,
    fetchMore,
    refetch,
    error,
  } = useQueryGetTransactionResult(memberId, 1);

  const pagination = data && data?.transactionResult?.meta;

  const debouncedRefetch = useThrottledCallback(() => {
    fetchMore({
      variables: {
        page: pagination.currentPage + 1,
        memberId,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        // setOptions((prevOptions) =>
        //   prevOptions.concat(listTransformer(fetchMoreResult)),
        // );
        return concatNewData(prev, fetchMoreResult);
      },
    });
  }, 1000);

  const onHandleScroll = React.useCallback(
    (e) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

      if (
        bottom &&
        pagination &&
        pagination.currentPage < pagination.lastPage
      ) {
        debouncedRefetch();
      }
    },
    [debouncedRefetch, pagination],
  );

  const currentData = data?.transactionResult.data;

  return (
    <DialogBackdrop onClose={props.onClose} cancelable={false}>
      <Panel>
        <HeaderPadding />
        {!data && !!error && <Button onClick={refetch}>Retry</Button>}
        {!data?.transactionResult?.data?.length && !error && !loading && (
          <ModelText>Tidak ada data</ModelText>
        )}
        <HaircutContainer onScroll={onHandleScroll}>
          {currentData?.map((transaction) => (
            <PreviousHairCut
              transactionAt={transaction.date}
              booking={transaction}
            />
          ))}
          {loading && !!data && <p>Loading........</p>}
        </HaircutContainer>
        <CloseButton onClick={onClose}>x</CloseButton>
        <LoadingView block isOpen={!data && loading} />
      </Panel>
    </DialogBackdrop>
  );
}

export const Panel = styled.div`
  width: 60%;
  background-color: white;
  border-radius: 5px;
  position: relative;
  min-height: 300px;
  padding: 1rem;
`;

const Section = styled.div`
  width: 48.5%;
`;

const PhotoText = styled.span`
  font-size: 12px;
  text-align: center;
  margin-top: 4px;
`;

const InfoSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
`;

const DetailInformationContainer = styled.div`
  border-radius: 10px;
  margin-bottom: 24px;
`;

const DetailTopInformationContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

const InfoSection = styled.div`
  display: flex;
`;

const InfoLeftSection = styled.div`
  display: flex;
`;

const InfoRightSection = styled.div`
  display: flex;
  margin-left: 16px;
`;
