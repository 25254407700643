import * as React from 'react';
import styled from 'styled-components';
import ReactSelect, { ActionMetaBase, Props } from 'react-select';
import color from 'styles/color';
import size from 'styles/size';

export interface SelectProps<T> extends Props<T> {}

export default function Select<T>(props: SelectProps<T>) {
  return (
    <StyledReactSelect
      className="react-select__container"
      classNamePrefix="react-select"
      {...props}
      isSearchable={false}
    />
  );
}

export function onSelect<T>(
  onChange: (onChangeValue: {
    singleValue?: T | null;
    multipleValue?: ReadonlyArray<T> | null;
    action: ActionMetaBase<T>;
  }) => void,
) {
  return function (value: any, action: ActionMetaBase<T>) {
    if (!value) {
      onChange({ singleValue: value, multipleValue: value as null, action });
    } else if ('length' in value) {
      onChange({ singleValue: undefined, multipleValue: value as any, action });
    } else {
      onChange({ singleValue: value, multipleValue: undefined, action });
    }
  };
}

const StyledReactSelect = styled(ReactSelect)`
  &.react-select__container {
    width: 100%;

    .react-select__control {
      border-radius: ${size.inputBorder};
      min-height: ${size.inputMinHeight};
      border: 1px solid ${color.border};

      &--is-focused {
        border-color: ${color.accent};
        box-shadow: none;
      }
    }
  }
  & .react-select__indicator-separator {
    display: none;
  }
  & .react-select__indicator {
    padding-right: ${size.inputPadding};
  }
  & .react-select__value-container {
    font-weight: bold;
    color: ${color.accent};
    padding: 0px ${size.inputPadding};
  }
  & .react-select__option--is-selected {
    background-color: ${color.accent};
  }
  & .react-select__placeholder {
    color: ${color.placeholder};
  }

  & .react-select__menu {
    margin-bottom: 100px;
  }
`;
