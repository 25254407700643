import React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Column,
  TableColumnWidthInfo,
  SelectionState,
  PagingState,
  CustomPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  VirtualTable,
  TableSelection,
  TableRowDetail,
  PagingPanel,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import LoadingSpinner from 'components/LoadingSpinner';
import styled from 'styled-components';
import { RowDetailState } from '@devexpress/dx-react-grid';

interface Props {
  rows: any[];
  columns: ReadonlyArray<Column>;
  columnWidths?: TableColumnWidthInfo[];
  loading?: boolean;
  enableSelection?: boolean;
  selection?: any[];
  onSelectionChange?: (props: React.ReactText[]) => any;
  enableDetailRow?: boolean;
  enablePagination?: boolean;
  totalCount?: number;
  page?: number;
  onPageChange?: (page: number) => void;
  pageSize?: number;
  expandedRowIds?: React.ReactText[];
  setExpandedRowIds?: (arr: React.ReactText[]) => void;
  rowDetail?:
    | React.ComponentClass<TableRowDetail.ContentProps, any>
    | React.FunctionComponent<TableRowDetail.ContentProps>;
}

function TableComponent(props: Props, ref: any) {
  const {
    rows,
    columns,
    columnWidths,
    loading = false,
    selection = [],
    enableSelection = false,
    enablePagination = false,
    totalCount,
    pageSize,
    page,
    onPageChange,
    onSelectionChange = undefined,
    enableDetailRow = false,
    rowDetail = undefined,
    expandedRowIds = undefined,
    setExpandedRowIds = undefined,
  } = props;

  return (
    <Paper style={{ flex: 1, overflow: 'scroll' }}>
      <Grid rows={rows} columns={columns}>
        <VirtualTable ref={ref} />
        {!!columnWidths && (
          <TableColumnResizing defaultColumnWidths={columnWidths} />
        )}
        <TableHeaderRow />
        {enableSelection && (
          <SelectionState
            selection={selection}
            onSelectionChange={onSelectionChange}
          />
        )}
        {enableSelection && (
          <TableSelection
            selectByRowClick
            highlightRow
            showSelectionColumn={false}
          />
        )}
        {enableDetailRow && (
          <RowDetailState
            defaultExpandedRowIds={[]}
            expandedRowIds={expandedRowIds}
            onExpandedRowIdsChange={setExpandedRowIds}
          />
        )}
        {enableDetailRow && <TableRowDetail contentComponent={rowDetail} />}
        {enablePagination && (
          <PagingState
            defaultCurrentPage={0}
            defaultPageSize={pageSize}
            currentPage={page! - 1}
            onCurrentPageChange={onPageChange}
          />
        )}
        {enablePagination && <CustomPaging totalCount={totalCount} />}
        {enablePagination && <PagingPanel />}
      </Grid>
      {loading && (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      )}
    </Paper>
  );
}

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
`;

export default React.forwardRef(TableComponent);
