import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  const { width = 40, height = 40, ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 512 512"
      fill="none"
      {...rest}
    >
      <path
        d="M361.95 228.693c6.918 0 12.561 2.366 16.93 7.1 4.733 4.369 7.1 10.012 7.1 16.93 0 6.917-2.367 12.743-7.1 17.476-4.369 4.369-10.012 6.554-16.93 6.554h-85.197v84.65c0 6.918-2.366 12.743-7.1 17.476-4.369 4.734-10.012 7.1-16.93 7.1-6.917 0-12.743-2.366-17.476-7.1-4.369-4.733-6.554-10.558-6.554-17.476v-84.65h-85.196c-6.918 0-12.744-2.185-17.477-6.554-4.369-4.733-6.553-10.559-6.553-17.476 0-6.918 2.184-12.561 6.553-16.93 4.733-4.734 10.559-7.1 17.477-7.1h85.196v-85.197c0-6.918 2.185-12.561 6.554-16.93 4.733-4.733 10.559-7.1 17.476-7.1 6.918 0 12.561 2.367 16.93 7.1 4.734 4.369 7.1 10.012 7.1 16.93v85.197h85.197z"
        fill="#000"
      />
      <rect
        x={20}
        y={20}
        width={472}
        height={472}
        rx={236}
        stroke="#000"
        strokeWidth={40}
      />
    </svg>
  );
}

export default SvgComponent;
