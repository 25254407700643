import * as React from 'react';
import DialogBackdrop from 'components/dialog/dialogBackdrop';
import styled from 'styled-components';
import { H3, P, H2, Button } from 'components/element';
import { useTranslation } from 'react-i18next';
import color from 'styles/color';
import { useQueryGetTransactionSummaryDetail } from 'repositories/transaction';
import { useStateGetCurrentOutlet } from 'repositories/outlet';
import LoadingView from 'components/LoadingView';
import { format, parseISO } from 'date-fns';
import { string2money } from 'utils/string';
import ReactToPrint from 'react-to-print';
import RecapTransactionReceipt from 'components/Receipt/RecapTransactionReceipt';

interface Props {
  onClose: () => void;
  date?: string;
  month?: string;
  year?: string;
  adminId: number;
  adminEmail: string;
}

export default function RecapTransactionDetail(props: Props) {
  const { t } = useTranslation();
  const { onClose, date, month, year, adminId, adminEmail } = props;
  const receiptRef = React.useRef<any>();

  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';

  const { data, loading } = useQueryGetTransactionSummaryDetail(
    outletId,
    adminId,
    date || '',
    month,
    year,
  );

  const transactionDetail = data ? data?.transactionSummaryDetail : undefined;

  const currentTime = React.useMemo(() => {
    if (transactionDetail) {
      if (date) {
        return format(parseISO(transactionDetail.time), 'dd MMM yyyy');
      } else {
        return transactionDetail.time;
      }
    }

    return '-';
  }, [date, transactionDetail]);

  const services = React.useMemo(() => {
    if (transactionDetail) {
      const obj = transactionDetail.services;
      const keys = Object.keys(obj);
      let totalService = 0;
      let totalQuantity = 0;

      const currentServices: any[] = [];

      for (let i = 0; i < keys.length; i++) {
        const cur = obj[keys[i]];
        totalService += Number(cur.total);
        totalQuantity += Number(cur.qty);
        currentServices.push(cur);
      }

      return {
        total: totalService,
        qty: totalQuantity,
        services: currentServices,
      };
    }

    return {
      total: 0,
      qty: 0,
      services: [],
    };
  }, [transactionDetail]);

  const products = React.useMemo(() => {
    if (transactionDetail) {
      const obj = transactionDetail.products;
      const keys = Object.keys(obj);
      let totalProduct = 0;
      let totalQuantity = 0;

      const currentProducts: any[] = [];

      for (let i = 0; i < keys.length; i++) {
        const cur = obj[keys[i]];
        totalProduct += Number(cur.total);
        totalQuantity += Number(cur.qty);

        currentProducts.push(cur);
      }

      return {
        total: totalProduct,
        qty: totalQuantity,
        products: currentProducts,
      };
    }

    return {
      total: 0,
      qty: 0,
      products: [],
    };
  }, [transactionDetail]);

  const payments = React.useMemo(() => {
    if (transactionDetail) {
      const obj = transactionDetail.payments;
      const keys = Object.keys(obj);

      const currentPayments: any[] = [];

      for (let i = 0; i < keys.length; i++) {
        const cur = obj[keys[i]];
        currentPayments.push(cur);
      }

      return currentPayments;
    }

    return [];
  }, [transactionDetail]);

  return (
    <DialogBackdrop onClose={onClose} cancelable={false}>
      <Panel>
        {transactionDetail && (
          <>
            <Container>
              <H3Title>{t('common.outlet')}</H3Title>
              <P>{transactionDetail.outletName}</P>
            </Container>
            <Container>
              <H3Title>{t('common.cashier')}</H3Title>
              <P>{adminEmail}</P>
            </Container>
            <Container>
              <Row>
                <P>{t('transaction.total_pending')}</P>
                <P>{transactionDetail?.pending}</P>
              </Row>
            </Container>
            <Container>
              <H3Title>{t('transaction.transaction_recap')}</H3Title>
              <P>{currentTime}</P>
            </Container>
            <Separator />
            <Container>
              <H2Title>{t('common.service')}</H2Title>
              {services.services.map((service) => {
                return (
                  <Row>
                    <P>{`( x${service.qty} ) ${service.name}`}</P>
                    <P>{`Rp ${string2money(service.total)}`}</P>
                  </Row>
                );
              })}
            </Container>
            <Container>
              <H2Title>{t('common.product')}</H2Title>
              {products.products.map((product) => {
                return (
                  <Row>
                    <P>{`( x${product.qty} ) ${product.name}`}</P>
                    <P>{`Rp ${string2money(product.total)}`}</P>
                  </Row>
                );
              })}
            </Container>
            <Container>
              <Row>
                <P>{t('transaction.service_total_quantity')}</P>
                <P>{`${string2money(services.qty)} ${t(
                  'transaction.service',
                )}`}</P>
              </Row>
              <Row>
                <P>{t('transaction.product_total_quantity')}</P>
                <P>{`${string2money(products.qty)} ${t(
                  'transaction.pieces',
                )}`}</P>
              </Row>
              <Row>
                <P>{t('transaction.total_service')}</P>
                <P>{`Rp ${string2money(services.total)}`}</P>
              </Row>
              <Row>
                <P>{t('transaction.total_product')}</P>
                <P>{`Rp ${string2money(products.total)}`}</P>
              </Row>
            </Container>
            <Container>
              <Row>
                <P>{t('transaction.total_sale')}</P>
                <P>{`Rp ${string2money(transactionDetail.totalPrice)}`}</P>
              </Row>
              <Row>
                <P>{t('transaction.total_discount')}</P>
                <P>{`Rp ${string2money(transactionDetail.totalDiscount)}`}</P>
              </Row>
            </Container>
            <Container>
              {payments.map((payment: { name: string; total: number }) => {
                return (
                  <Row>
                    <P>{`${t('transaction.total_payment_1')} ${
                      payment.name
                    }`}</P>
                    <P>{`Rp ${string2money(payment.total)}`}</P>
                  </Row>
                );
              })}
            </Container>
            <ReactToPrint
              trigger={() => <PrintButton>{t('common.print')}</PrintButton>}
              content={() => receiptRef.current}
            />
          </>
        )}
        <CloseButton onClick={onClose}>x</CloseButton>
        <LoadingView isOpen={loading} block />
      </Panel>
      <div style={{ display: 'none' }}>
        <RecapTransactionReceipt
          transactionDetail={transactionDetail}
          cashier={adminEmail}
          date={currentTime}
          ref={receiptRef}
          services={services}
          products={products}
          payments={payments}
        />
      </div>
    </DialogBackdrop>
  );
}

const Container = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.3rem;
  width: 100%;
  justify-content: space-between;
`;

const H3Title = styled(H3)`
  margin-bottom: 0.3rem;
`;

const Panel = styled.div`
  width: 60%;
  background-color: white;
  border-radius: 5px;
  min-height: 300px;
  max-height: 600px;
  position: relative;
  padding: 1rem;
  overflow-y: scroll;

  @media (max-width: 1023px) {
    width: 70%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding-bottom: 1px;
  color: ${color.black};
  line-height: 40px;
  font-size: 36px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  outline: none;
  border: none;
  appearance: none;
`;

const Separator = styled.div`
  border-bottom: 1px solid ${color.border};
  width: 100%;
  margin: 1rem 0;
`;

const H2Title = styled(H2)`
  margin-bottom: 1rem;
  padding-bottom: 0.3rem;
  display: flex;
  border-bottom: 1px solid ${color.black};
`;

const PrintButton = styled(Button)`
  width: 180px;
  float: right;
  margin-top: 1rem;
`;
