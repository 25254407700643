import * as React from 'react';
import {
  SideNavContainer,
  SideNavIcon,
  SideNavHeader,
  StyledNavLink,
  NetworkPanel,
  PanelText,
  PanelButton,
  SideNavContent,
} from '../style';
import color from 'styles/color';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { logout, useStateSetLoginToken } from 'repositories/auth';
import { RouteNode } from 'containers/Router';
import LogoutIcon from 'assets/images/png/log-out-white.png';
import LogoutBlackIcon from 'assets/images/png/log-out.png';
import useAppState from 'hooks/useAppState';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router';

interface Props {
  navigation: RouteNode[];
}

export default function SideNav(props: Props) {
  const { navigation } = props;
  const client = useApolloClient();
  const { t } = useTranslation();
  const { mode, online, setMode } = useNetworkStatus();
  const [debouncedOnline] = useDebounce(online, 2500);
  const [setLoginToken] = useStateSetLoginToken();
  const navigate = useNavigate();

  const { currentOutlet }: any = useAppState();

  const PanelData = React.useMemo(() => {
    if (debouncedOnline === false && mode === true && mode !== online) {
      return {
        show: true,
        text: t('common.switch_to_offline_text'),
        onPress: () => {
          setMode(false, async () => {
            navigate('/');
            logout(client, !mode);
            await setLoginToken({
              variables: {
                token: '',
              },
            });
          });
        },
      };
    } else if (debouncedOnline === true && mode === false && mode !== online) {
      return {
        show: true,
        text: t('common.switch_to_online_text'),
        onPress: () => {
          navigate('/');
          setMode(true, () => logout(client, !mode));
        },
      };
    }

    return {
      show: false,
      text: '',
      onPress: () => {},
    };
  }, [
    client,
    debouncedOnline,
    mode,
    online,
    setLoginToken,
    setMode,
    t,
    navigate,
  ]);

  return (
    <SideNavContainer>
      <SideNavHeader>{currentOutlet ? currentOutlet.name : ''}</SideNavHeader>
      {/* {initial && ( */}
      <NetworkPanel show={PanelData.show}>
        <PanelText>{PanelData.text}</PanelText>
        <PanelButton onClick={PanelData.onPress}>
          {t('common.switch_network_text', {
            mode: t(`common.${online ? 'online' : 'offline'}`),
          })}
        </PanelButton>
      </NetworkPanel>
      {/* )} */}
      <SideNavContent>
        {navigation.map((nav) => {
          if (nav.needOnline && !mode) {
            return null;
          }

          return (
            <StyledNavLink
              key={nav.path}
              activeIcon={nav.activeIcon}
              className={'nav-link'}
              to={nav.path}
              style={({ isActive }) => (isActive ? styles.active : {})}
              onClick={() => {}}
            >
              <SideNavIcon icon={nav.icon} />
              {t(nav.name)}
            </StyledNavLink>
          );
        })}
        <StyledNavLink
          className="nav-link"
          activeIcon={LogoutBlackIcon}
          to="/login"
          onClick={async (e) => {
            e.preventDefault();
            logout(client, mode);
            navigate('/');
            if (!mode) {
              try {
                await setLoginToken({
                  variables: {
                    token: '',
                  },
                });
              } catch (e) {}
            }
          }}
        >
          <SideNavIcon icon={LogoutIcon} />
          {t('common.logout')}
        </StyledNavLink>
      </SideNavContent>
    </SideNavContainer>
  );
}

const styles = {
  active: {
    backgroundColor: color.white,
    color: color.black,
  },
};
