import * as React from 'react';
import styled from 'styled-components';
import color from 'styles/color';
import ReactSelect from 'react-select';
import { P } from 'components/element';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

export const Container = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${color.border};
  margin-bottom: 1rem;
  padding-bottom: 1rem;
`;

export const StyledReactSelect = styled(ReactSelect)`
  &.react-select__container {
    width: 150px;
    .react-select__control {
      border-radius: 20px;
      min-height: 30px;
      border: 1px solid ${color.black};
    }
  }
`;

export const LoadingContainer = styled.div`
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  align-self: flex-end;
`;

export const MarginHorizontal = styled.div`
  margin: 0.5rem;
`;

export const DateContainer = styled.div``;

export const Label = styled(P)`
  font-size: 14px;
  color: ${color.neutralSpindle};
`;

function ComposedDatePicker(props: ReactDatePickerProps) {
  return <DatePicker {...props} />;
}

export const DateFilter = styled(ComposedDatePicker)`
  border-radius: 10px !important;
  width: 170px;
  min-height: 36px;
  border: 1px solid ${color.charcoal4};
`;
