import * as React from 'react';
import {
  Container,
  StyledAsyncSelect,
  Separator,
  CustomButton,
  StyledH3,
  Divider,
} from './style';
import { useTranslation } from 'react-i18next';
import SelectDetail from 'components/transferPoint/SelectDetail';
import { TFunction } from 'i18next';
import debounce from 'debounce-promise';
import PinInput from 'react-pin-input';
import color from 'styles/color';
import MemberSingleValue from 'components/transferPoint/memberSingleValue';
import firebaseApp from '../../config/firebase';
import RecaptchaContainer, {
  RecaptchaHandler,
} from './component/RecaptchaContainer';
import {
  useQueryGetOldMembers,
  useQueryGetOldMember,
} from 'repositories/member/member.query';
import MemberData from 'components/transferPoint/memberData';
import LoadingView from 'components/LoadingView';
import { toast } from 'react-toastify';
import { useMutationMigratePoint } from 'repositories/member/member.mutation';

export interface TransferPointOption {
  t: TFunction;
  extra: any;
}

export default function TransferPoint() {
  const { t } = useTranslation();
  const [step, setStep] = React.useState(1);
  const [code, setCode] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState();

  const [loadOldMembers] = useQueryGetOldMembers();
  const [loadOldMember, { data }] = useQueryGetOldMember();
  const [migratePoint] = useMutationMigratePoint();

  const RecaptchaRef = React.useRef<RecaptchaHandler>(null);
  const confirmation = React.useRef<any>(null);

  const SendCode = React.useCallback(async () => {
    if (RecaptchaRef.current) {
      const verifier = RecaptchaRef.current.recaptchaVerifier;
      if (verifier && data) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        confirmation.current = await firebaseApp
          .auth()
          .signInWithPhoneNumber(data.oldMember.data.phoneNumber, verifier);
      }
    }
  }, [RecaptchaRef, data]);

  React.useLayoutEffect(() => {
    if (step === 2) {
      SendCode();
    }
  }, [SendCode, step]);

  React.useEffect(() => {
    if (selectedUser) {
      loadOldMember({
        variables: {
          id: selectedUser,
        },
      });
    }
  }, [loadOldMember, selectedUser]);

  const fetchOptions = React.useCallback(
    async (inputValue) => {
      const { data } = await loadOldMembers({
        variables: {
          phoneNumber: inputValue,
        },
      });

      if (data) {
        return data
          ? data.oldMembers.data.map((member) => {
              return {
                t: t,
                value: member.userId,
                extra: member,
              };
            })
          : [];
      }

      return [];
    },
    [loadOldMembers, t],
  );

  const onFinish = React.useCallback(
    async (code: string) => {
      try {
        setIsLoading(true);
        if (confirmation.current && code.length === 6 && data) {
          await confirmation.current.confirm(code);
          const idTokenResult = await (firebaseApp as any)
            .auth()
            .currentUser.getIdTokenResult();
          if (idTokenResult) {
            try {
              const response = await migratePoint({
                variables: {
                  payload: {
                    verification_token: idTokenResult.token,
                  },
                  userId: data.oldMember.data.userId,
                },
              });

              toast.success(response.data?.migratePoint.message);
              setTimeout(() => {
                window.location.reload();
              }, 800);
            } catch (e) {}
          }
        }
      } catch (e) {
        toast.error(t('common.verification_code_incorrect'));
      } finally {
        setIsLoading(false);
      }
    },
    [data, migratePoint, t],
  );

  const loadOptions = debounce(fetchOptions, 1000);

  const onValueChange = React.useCallback(
    (value: any) => {
      if (step >= 1) {
        setStep(1);
      }
      setSelectedUser(value.extra.userId);
    },
    [step],
  );

  const content = React.useMemo(() => {
    switch (step) {
      case 1:
        return (
          <>
            {data && (
              <>
                <MemberData data={data.oldMember.data} />
                <CustomButton
                  onClick={() => {
                    setStep(2);
                  }}
                >
                  {t('common.process')}
                </CustomButton>
              </>
            )}
          </>
        );
      case 2:
        return (
          <>
            {data && (
              <>
                <Divider />
                <StyledH3>
                  {t('common.otp_code_sent', {
                    phoneNumber: data.oldMember.data.phoneNumber,
                  })}
                </StyledH3>
                <Divider />
                <RecaptchaContainer ref={RecaptchaRef} />
                <StyledH3>{t('common.input_otp')}</StyledH3>
                <PinInput
                  length={6}
                  onChange={(value) => {
                    setCode(value);
                  }}
                  initialValue=""
                  type="numeric"
                  style={pinStyles.defaultStyle}
                  inputStyle={pinStyles.inputStyle}
                  inputFocusStyle={pinStyles.inputFocusStyle}
                />
                <Divider />
                <CustomButton
                  onClick={() => onFinish(code)}
                  disabled={code.length < 6}
                >
                  {t('common.process')}
                </CustomButton>
              </>
            )}
          </>
        );
      default:
        return <></>;
    }
  }, [code, data, onFinish, step, t]);

  return (
    <Container>
      <Separator />
      <StyledAsyncSelect
        name="search"
        className="react-select__container"
        classNamePrefix="react-select"
        onChange={onValueChange}
        components={{ SingleValue: MemberSingleValue }}
        formatOptionLabel={SelectDetail}
        placeholder={t('common.phone_number')}
        loadOptions={(inputValue: any) => loadOptions(inputValue)}
      />
      {content}
      <LoadingView isOpen={isLoading} />
    </Container>
  );
}

const pinStyles = {
  defaultStyle: {
    margin: '16px 0',
  },
  inputStyle: {
    borderColor: color.gainsboro7,
    borderRadius: 8,
    marginRight: 8,
  },
  inputFocusStyle: {
    borderColor: color.black,
  },
};
