import * as React from 'react';
import styled from 'styled-components';
import { OldMember } from 'repositories/member/member.model';
import color from 'styles/color';
import { useTranslation } from 'react-i18next';
import { string2money } from 'utils/string';

interface Props {
  data: OldMember;
}

export default function MemberData(props: Props) {
  const { t } = useTranslation();
  const { data } = props;

  return (
    <Container>
      <Space>
        <Label>{t('common.name')}</Label>
        <Text>{data.name}</Text>
      </Space>
      <Space>
        <Label>{t('common.phone_number')}</Label>
        <Text>{data.phoneNumber}</Text>
      </Space>
      <Space>
        <Label>{t('common.normal_point')}</Label>
        <Text>{string2money(data.point)}</Text>
      </Space>
      <Space>
        <Label>{t('common.new_convertion_point')}</Label>
        <Text>{string2money(data.pointRate * data.point)}</Text>
      </Space>
    </Container>
  );
}

const Container = styled.div`
  margin: 24px 0;
  width: 400px;
`;

const Space = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const Label = styled.p`
  font-size: 16px;
  color: ${color.neutralSpindle};
`;

const Text = styled.p`
  font-size: 16px;
  margin-left: 8px;
`;
