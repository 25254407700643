import common from './common';
import management from './management';
import transaction from './transaction';
import date from './date';
import status from './status';
import booking from './booking';
import error from './error';

export default {
  common,
  management,
  transaction,
  date,
  status,
  booking,
  error,
};
