import * as React from 'react';
import styled from 'styled-components';
import color from 'styles/color';
import { H4 } from 'components/element';

export const Container = styled.div`
  width: 76mm;
  padding: 32px 24px 48px;
  font-size: 14px;
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 12px;
    margin-bottom: 24px;
  }
  td {
    font-size: 12px;
    &:first-child {
      padding-right: 6px;
    }
    &:last-child {
      text-align: right;
    }
  }
`;

export const DividerRowTop = styled.tr`
  border-bottom: 1px solid #d3d3d3;
  > td {
    padding-top: 12px;
  }
`;
export const DividerRowBottom = styled.tr`
  > td {
    padding-top: 12px;
  }
`;

export function DividerRow() {
  return (
    <>
      <DividerRowTop>
        <td></td>
        <td></td>
      </DividerRowTop>
      <DividerRowBottom>
        <td></td>
        <td></td>
      </DividerRowBottom>
    </>
  );
}

export const DivCenter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderSection = styled.div`
  margin-bottom: 32px;
`;

export const Brand = styled.h2`
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: normal;
  width: 100%;
  text-align: center;
  text-transform: capitalize;
`;

export const Subtitle = styled.h4`
  font-size: 12px;
  font-weight: normal;
  width: 100%;
  text-align: center;
`;

export const FootNote = styled.p`
  margin-top: 16px;
  margin-bottom: 8px;
  width: 100%;
  text-align: center;
  /* color: ${color.lightGrey}; */
  font-size: 12px;
`;

export const AppUrlQR = styled.img`
  width: 84px;
  height: 84px;
`;

export const UnderscoreTitle = styled(H4)`
  padding-bottom: 0.3rem;
  display: flex;
  font-weight: none;
`;

export const Separator = styled.tr`
  > td {
    padding-top: 6px;
  }
`;
