import { OutletProductLite, OutletServiceLite } from '../outlet/outlet.model';

export enum BookingTypeEnum {
  Online = 1,
  Offline,
  Reserve,
  Stylist,
}

export enum BookingStatusTypeEnum {
  Created = 1,
  Started = 2,
  Finished = 3,
  Cancelled = 9,
}

export enum BookableTypeEnum {
  'App\\Models\\Service',
  'App\\Models\\Product',
}

export interface BookingSheets {
  date: string;
  minute: number;
  startMinute: number;
  endMinute: number;
  isOperationalHour: boolean;
  bookings: [];
}

export interface BookingInput {
  phoneNumber: string;
  date: string;
  outletId: string;
  serviceId: any;
  products: any;
  stylistId: string;
  isRandomStylist: number;
  customerName: string;
  startMinute: number;
  isPending: number;
}
export interface BookingPhotoAngle {
  id: string;
  value: string;
}

export interface BookingFile {
  id: string;
  file: string;
  filePath: string;
  fileUrl: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

export interface BookingHaircut {
  sideback: {
    id: string;
    value: string;
    items: {
      id: string;
      value: string;
    }[];
  }[];
  top: {
    id: string;
    value: string;
    items: {
      id: string;
      value: string;
    }[];
  }[];
}

export interface Booking {
  id: string;
  // date: string;
  // outlet: Outlet;
  // stylist?: OutletStylist;
  stylist?: {
    id: string;
    name: string;
  };
  startMinute: number;
  timeInMinutes: number;
  thresholdTime: number;
  isRandomStylist: boolean;
  type: string;
  status: number;
  member: {
    id: string;
    phoneNumber: string;
    name?: string | null;
    birthDate?: string | null;
    email?: string | null;
    address?: string | null;
    point?: number | null;
  };
  transactionStatus?: number;
  haircut: BookingHaircut;
  photoAngles: BookingPhotoAngle[];
  files: BookingFile[];
  sideHaircutModel?: string;
  topHaircutModel?: string;
  createdAt: string;
  // updatedAt: string;
  services: {
    price: number;
    timeInMinutes: number;
    qty: number;
    service: OutletServiceLite;
  }[];
  products: {
    price: number;
    qty: number;
    product: OutletProductLite;
  }[];
  bookingChangeLogs: string;
}

export interface BookingSheet {
  date: string;
  minute: number;
  startMinute: number;
  endMinute: number;
  isOperationHour: boolean;
  bookings: Booking[];
}

export interface BookingSheetAvailableTime {
  date: string;
  minute: number;
  isOperationalHour: boolean;
}
