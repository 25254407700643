import * as React from 'react';
import ReactToPrint from 'react-to-print';
import { useTranslation } from 'react-i18next';
import TransactionReceipt from 'components/Receipt/TransactionReceipt';
import { Transaction } from 'repositories/transaction/transaction.model';
import { Button } from 'components/element';

interface Props {
  transaction: Transaction;
  onBeforePrint: () => void;
}

export default function TransactionSuccessPreview(props: Props) {
  const receiptRef = React.useRef<any>();
  const { t } = useTranslation();

  return (
    <>
      <TransactionReceipt transaction={props.transaction} ref={receiptRef} />
      <ReactToPrint
        trigger={() => <Button>{t('common.print')}</Button>}
        onBeforeGetContent={props.onBeforePrint}
        content={() => receiptRef.current}
      />
    </>
  );
}
