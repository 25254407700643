import * as React from 'react';
import styled from 'styled-components';
import color from 'styles/color';
import { TransferPointOption } from 'pages/transferPoint/transferPoint';

export default function SelectDetail(option: TransferPointOption) {
  const { t, extra } = option;

  return (
    <Container>
      <RowContainer>
        <Label>{t('common.name')}</Label>
        <Text>{extra.name}</Text>
      </RowContainer>
      <RowContainer>
        <Label>{t('common.phone_number')}</Label>
        <Text>{extra.phoneNumber}</Text>
      </RowContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const Label = styled.p`
  font-size: 16px;
  color: ${color.neutralSpindle};
`;

const Text = styled.p`
  font-size: 16px;
  margin-left: 8px;
`;
