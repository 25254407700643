import * as DateFNS from 'date-fns';
import locale from 'date-fns/locale/id';

const monthOptions = [
  'jan',
  'feb',
  'mar',
  'apr',
  'mei',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];

export function getMonth(month: number, t: any) {
  const stringMonth = monthOptions[month - 1];
  return t(`date.${stringMonth}`);
}

export function pad(n: number) {
  return n < 10 ? '0' + n : n;
}

export function convertTime(minute: number) {
  let hour: string | number = Math.floor(minute / 60);
  hour = pad(hour);
  let minutes: string | number = minute % 60;
  minutes = pad(minutes);

  return hour.toString() + '.' + minutes.toString();
}

export function format(
  value: Date | string,
  dateFormat: string = 'dd LLL yyyy kk:mm',
) {
  if (typeof value === 'string') {
    value = new Date(value);
  }
  return DateFNS.format(value, dateFormat, { locale });
}
