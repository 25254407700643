import * as React from 'react';
import DialogBackdrop from 'components/dialog/dialogBackdrop';
import { CloseButton } from 'pages/bookingManagement/components/AddBooking';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import { useQueryGetAdminOutlets } from 'repositories/outlet';
import {
  useStateSetCurrentOutlet,
  useStateSetRememberedOutlet,
} from 'repositories/outlet/outlet.mutation';
import size from 'styles/size';
import color from 'styles/color';
import { H2 } from 'components/element';
import { useIsAuthenticated } from 'repositories/auth';
import { setOutletALiasKey } from 'utils/storage';

interface Props {
  onClose: () => void;
}

export default function ChooseOutletDialog(props: Props) {
  const [loadOutlets, getOutletsQuery] = useQueryGetAdminOutlets();
  const isAuthenticated = useIsAuthenticated();
  const [setRememberedOutlet] = useStateSetRememberedOutlet();

  const { onClose } = props;

  const [setCurrentOutlet] = useStateSetCurrentOutlet();

  React.useEffect(() => {
    if (!isAuthenticated) {
      onClose();
    } else {
      if (!getOutletsQuery.called) {
        loadOutlets();
      }
    }
  }, [getOutletsQuery.called, isAuthenticated, loadOutlets, onClose]);

  const outlets = React.useMemo(() => {
    if (getOutletsQuery.data?.outlets.data) {
      return getOutletsQuery.data.outlets.data.map((outlet) => ({
        value: outlet.id,
        label: outlet.name,
        outletAlias: outlet.outletAlias,
      }));
    }
    return [];
  }, [getOutletsQuery.data]);

  const onOutletChange = React.useCallback(
    async (value: { label: string; value: string; outletAlias: string }) => {
      try {
        await setCurrentOutlet({
          variables: { id: value.value },
        });
        await setOutletALiasKey(value.outletAlias);
        await setRememberedOutlet({
          variables: { id: value.value },
        });
        onClose();
      } catch (e) {}
    },
    [onClose, setCurrentOutlet, setRememberedOutlet],
  );

  return (
    <DialogBackdrop onClose={props.onClose} cancelable={false}>
      <Panel>
        <Title>Pilih Outlet</Title>
        <OutletReactSelect
          className="react-select__container"
          classNamePrefix="react-select"
          options={outlets}
          value={null}
          //@ts-expect-error
          onChange={onOutletChange}
        />
        <CloseButton onClick={props.onClose}>x</CloseButton>
      </Panel>
    </DialogBackdrop>
  );
}

const Panel = styled.div`
  width: 28%;
  background-color: white;
  border-radius: 5px;
  position: relative;
  padding: 1rem;
  overflow-y: scroll;
  min-height: 30vh;

  @media (max-width: 1023px) {
    width: 70%;
  }
`;

const Title = styled(H2)`
  text-align: center;
  margin-bottom: 1rem;
`;

const OutletReactSelect = styled(ReactSelect)`
  &.react-select__container {
    width: 100%;
    margin-bottom: 16px;

    .react-select__control {
      border-radius: ${size.inputBorder};
      min-height: ${size.inputMinHeight};
      border: 1px solid ${color.border};

      &--is-focused {
        border-color: ${color.accent};
        box-shadow: none;
      }
    }
  }
  & .react-select__indicator-separator {
    display: none;
  }
  & .react-select__indicator {
    padding-right: ${size.inputPadding};
  }
  & .react-select__value-container {
    font-weight: bold;
    color: ${color.accent};
    padding: 0px ${size.inputPadding};
  }
  & .react-select__option--is-selected {
    background-color: ${color.accent};
  }
  & .react-select__placeholder {
    color: ${color.placeholder};
  }
  & .react-select__menu {
    margin-bottom: 100px;
  }
`;
