import * as React from 'react';
import {
  MB16,
  H1Title,
  TransactionContent,
  Separator,
  PriceContainer,
  Row,
  H4Title,
} from '../style';
import { Form, Field, Button, P } from 'components/element';
import { useTranslation } from 'react-i18next';
import { string2money } from 'utils/string';
import { Transaction } from 'repositories/transaction/transaction.model';
import OutletProductsInput from 'components/transaction/OutletProductsInput';
import VoucherInput from 'components/transaction/VoucherInput';
import TransactionSuccessPreview from 'pages/transactionManagement/components/TransactionSuccessPreview';
import DialogBackdrop from 'components/dialog/dialogBackdrop';
import {
  CloseButton,
  Panel,
} from 'pages/bookingManagement/components/AddBooking';
import useTransactionForm from 'hooks/useTransactionForm';
import CustomerInput from 'components/transaction/CustomerInput';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';
import { useDebouncedCallback } from 'use-debounce';
import { PaymentPointEnum } from 'repositories/payment';
import { useField } from 'formik';

interface Props {
  afterResponse?: () => void;
  onClose: () => void;
  date: Date;
}

function PaymentMethod(props: { paymentMethods: any }) {
  const { t } = useTranslation();
  const [, , helpers] = useField('paymentLabel');
  return (
    <Field
      isSearchable={false}
      type="select"
      name="paymentId"
      options={props.paymentMethods}
      placeholder={t('transaction.choose_payment_method')}
      onAfterChange={(val) => {
        if (val) {
          helpers.setValue(val.label);
        }
      }}
    />
  );
}

export default function NewTransaction(props: Props) {
  const { afterResponse, date, onClose } = props;
  const [refetchVoucher, setRefetchVoucher] = React.useState(false);
  const { t } = useTranslation();
  const { mode } = useNetworkStatus();

  const [successTransaction, setSuccessTransaction] = React.useState<
    Transaction | undefined
  >();

  const {
    formik,
    isLoading,
    paymentMethods,
    setIsLoading,
  } = useTransactionForm({
    date,
    onSuccess(transaction) {
      setSuccessTransaction(transaction);
      afterResponse && afterResponse();
    },
  });

  const debouncedRefetchVoucher = useDebouncedCallback(() => {
    if (formik.values.voucher && formik.values.voucherCode) {
      setRefetchVoucher(true);
      formik.setFieldValue('voucher', null);
    }
  }, 800);

  return (
    <DialogBackdrop onClose={props.onClose} cancelable={false}>
      <Panel>
        {successTransaction ? (
          <TransactionSuccessPreview
            transaction={successTransaction}
            onBeforePrint={onClose}
          />
        ) : (
          <>
            <H1Title>{t('common.new_transaction')}</H1Title>
            <Form formik={formik}>
              <TransactionContent>
                <CustomerInput
                  formik={formik}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
                <OutletProductsInput
                  values={formik.values.products}
                  onChange={(products) => {
                    formik.setFieldValue('products', products);
                    debouncedRefetchVoucher();
                  }}
                />
                <Separator />
                <PriceContainer>
                  <Row>
                    <H4Title>{t('transaction.sub_total')}</H4Title>
                    <P>{`Rp ${string2money(formik.values.subTotal || '0')}`}</P>
                  </Row>
                  <Row>
                    <H4Title>{t('transaction.discount')}</H4Title>
                    <P>{`Rp ${string2money(formik.values.discount || '0')}`}</P>
                  </Row>
                  <Row>
                    <H4Title>{t('transaction.total_pay')}</H4Title>
                    <P>{`Rp ${string2money(formik.values.totalPay || '0')}`}</P>
                  </Row>

                  <Row>
                    <H4Title>{t('common.changes')}</H4Title>
                    <P>{`Rp ${string2money(
                      formik.values.payAmount - formik.values.totalPay,
                    )}`}</P>
                  </Row>
                </PriceContainer>

                {/* use any for temp till formik typing issue solved */}
                {mode && (
                  <VoucherInput
                    refetchVoucher={refetchVoucher}
                    setRefetchVoucher={setRefetchVoucher}
                    servicesName="services"
                    productsName="products"
                    voucherCode={formik.values.voucherCode}
                    voucher={formik.values.voucher}
                    onChange={formik.handleChange('voucherCode') as any}
                    onBlur={formik.handleBlur('voucherCode') as any}
                    onApplied={(voucher) => {
                      formik.setFieldValue('voucher', voucher);
                    }}
                    transactionAt={formik.values.transactionAt}
                    memberId={formik.values.memberCheckResult?.data?.id}
                  />
                )}
                <PaymentMethod paymentMethods={paymentMethods} />
                {/* <Field
                  isSearchable={false}
                  type="select"
                  name="paymentId"
                  options={paymentMethods}
                  placeholder={t('transaction.choose_payment_method')}
                  onAfterChange={(val) => {
                    // formik.setFieldValue('paymentLabel', val.label);
                    // formik.setFieldError('paymentId', undefined);
                  }}
                /> */}
                {formik.values.paymentLabel ===
                  PaymentPointEnum.PAYMENT_TYPE_POINT &&
                  process.env.REACT_APP_APP_NAME === 'Sir Salon' && (
                    <Field
                      type="text"
                      name="otpMember"
                      required
                      placeholder={t('transaction.input_member_otp')}
                    />
                  )}
                <Field
                  type="text"
                  name="payAmount"
                  placeholder={t('common.total_money')}
                />
                <MB16 />
                {formik.values.transactionAt && (
                  <>
                    <Field
                      type="time"
                      name="transactionAt"
                      placeholder={t('common.transaction_time')}
                    />
                    <Field
                      type="text"
                      name="otp"
                      placeholder={t('common.pin')}
                    />
                  </>
                )}
                <Button
                  disabled={!formik.isValid}
                  type="submit"
                  isLoading={isLoading}
                >
                  {t('transaction.add_transaction')}
                </Button>
              </TransactionContent>
            </Form>
          </>
        )}
        <CloseButton onClick={onClose}>x</CloseButton>
      </Panel>
    </DialogBackdrop>
  );
}
