import { gql, useLazyQuery } from '@apollo/client';
import { OUTLET_FRAGMENT } from '../outlet/outlet.fragment';
import { SERVICE_FRAGMENT } from '../service/service.fragment';
import { PRODUCT_FRAGMENT } from '../product/product.fragment';
import { STYLIST_FRAGMENT } from '../stylist/stylist.fragment';
import { PAYMENT_FRAGMENT } from '../payment/payment.fragment';
import { MasterData } from './common.model';
import * as React from 'react';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';

const GET_MASTER_DATA = gql`
  query getMasterData {
    getMasterData @rest(type: "MasterDataPayload", path: "/sync-master-data") {
      admins {
        id
        name
        email
        cashierId
        outlets @type(name: "AdminOutlet") {
          id
          name
          pivot {
            adminId
            outletId
          }
        }
      }
      outlets @type(name: "Outlet") {
        ...outletFragment
        services @type(name: "MasterService") {
          id
          name
          description
          imagePath
          price
          point
          cost
          timeInMinutes
          isAvailable
          updatedAt
          createdAt
          pivot {
            outletId
            serviceId
            isAvailable
          }
        }
        products @type(name: "MasterProduct") {
          id
          code
          name
          description
          imagePath
          price
          point
          cost
          isAvailable
          updatedAt
          createdAt
          pivot {
            outletId
            productId
            qty
            isAvailable
            price
            point
          }
        }
        stylists @type(name: "Stylist") {
          ...Stylist
        }
      }
      payments @type(name: "Payment") {
        ...Payment
      }
    }
  }

  ${OUTLET_FRAGMENT}
  ${SERVICE_FRAGMENT}
  ${PRODUCT_FRAGMENT}
  ${STYLIST_FRAGMENT}
  ${PAYMENT_FRAGMENT}
`;

export function useQueryGetMasterData() {
  const queryResult = useLazyQuery<{
    getMasterData: MasterData;
  }>(GET_MASTER_DATA);

  const { mode } = useNetworkStatus();
  const [, { startPolling, stopPolling }] = queryResult;

  React.useEffect(() => {
    if (mode) {
      startPolling && startPolling(18000000);
    }

    return () => {
      stopPolling && stopPolling();
    };
  }, [mode, startPolling, stopPolling]);

  return queryResult;
}
