import * as React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import localForage from 'localforage';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import useAppState from './useAppState';
import { MemberCheckResult } from './useTransactionForm';
import { useMutationCreateBooking } from 'repositories/booking/booking.mutation';
import { useApolloClient } from '@apollo/client';
import {
  TransactionService,
  TransactionProduct,
} from 'repositories/transaction';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';

interface BookingOption {
  date: Date;
  onSuccess?: () => void;
}

export interface ServiceSelected {
  label: string;
  value: string;
}

export interface ProductSelected {
  options: {
    label: string;
    value: string;
  };
  qty: number;
}

const YupSchema = Yup.object().shape({
  isMemberCode: Yup.bool().notRequired(),
  memberCode: Yup.string().when('isMemberCode', {
    is: true,
    then: Yup.string().required(),
  }),
  phoneNumber: Yup.string().when(['isGuest', 'isMemberCode'], {
    is: (isGuest, isMemberCode) => !isGuest && !isMemberCode,
    then: Yup.string().required(),
  }),
  email: Yup.string()
    .email()
    .when(['isGuest', 'memberCheckResult'], {
      is(isGuest, memberCheckResult?: MemberCheckResult) {
        return isGuest || !memberCheckResult?.exist;
      },
      then: Yup.string().email().required(),
    }),
  outletId: Yup.string().required(),
  services: Yup.array().min(1).required(),
  products: Yup.array(),
  customerName: Yup.string().when(['isGuest', 'memberCheckResult'], {
    is(isGuest, memberCheckResult?: MemberCheckResult) {
      return isGuest || !memberCheckResult?.exist;
    },
    then: Yup.string().required(),
  }),
  stylist: Yup.string().when('isPending', {
    is: false,
    then: Yup.string().required(),
  }),
  time: Yup.number().when('isPending', {
    is: false,
    then: Yup.number().required(),
  }),
  timeInMinutes: Yup.number().required(),
  type: Yup.number().required(),
  isGuest: Yup.boolean().required(),
  isRandomStylist: Yup.boolean().required(),
  isStylistFetch: Yup.boolean().required(),
  isOperationalHour: Yup.boolean().required(),
  mode: Yup.boolean(),
  memberCheckResult: Yup.object().when(['isGuest', 'mode'], {
    is: (isGuest, mode) => !isGuest && mode,
    then: Yup.object().required(),
  }),
});

export default function useBookingForm(options: BookingOption) {
  const { t } = useTranslation();
  const { currentOutlet } = useAppState();
  const apolloClient = useApolloClient();

  const { mode } = useNetworkStatus();

  const [isLoading, setIsLoading] = React.useState(false);

  const outletId = currentOutlet?.id || '';

  const [createBooking] = useMutationCreateBooking();

  const bookingTypes = React.useMemo(
    () => [
      {
        label: t('status.offline'),
        value: 2,
      },
      {
        label: t('status.reserve'),
        value: 3,
      },
      {
        label: t('status.stylist'),
        value: 4,
      },
    ],
    [t],
  );

  const formik = useFormik({
    initialValues: {
      outletId,
      mode,
      phoneNumber: '',
      services: [] as TransactionService[],
      products: [] as TransactionProduct[],
      stylist: '',
      time: undefined,
      timeInMinutes: 0,
      customerName: '',
      email: '',
      type: 2,
      isGuest: false,
      isRandomStylist: false,
      isPending: false,
      isStylistFetch: false,
      isOperationalHour: false,
      memberCheckResult: undefined as MemberCheckResult | undefined,
      isMemberCode: false,
      memberCode: '',
    },
    validateOnMount: true,
    validationSchema: YupSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const bookedServices = values.services.map((service) => {
        return service.service.id;
      });
      const bookedProducts = values.products.map((product) => {
        return {
          id: product.product.id,
          qty: product.qty,
        };
      });

      const payload = {
        date: format(options.date, 'yyyy-MM-dd'),
        outlet_id: outletId,
        service_id: mode ? bookedServices : values.services,
        products: mode ? bookedProducts : values.products,
        stylist_id: values.stylist || null,
        is_random_stylist: values.isRandomStylist,
        type: values.type,
        is_pending: values.isPending ? 1 : 0,
        phone_number: values.phoneNumber || undefined,
        customer_name: values.customerName || undefined,
        email: values?.email || undefined,
        ...(!values.isPending
          ? {
              start_minute: values.time,
            }
          : {}),
        ...(!mode
          ? {
              timeInMinutes: values.timeInMinutes || 0,
            }
          : {}),
      };

      try {
        await localForage.setItem('booking', payload);
        await createBooking({
          variables: {
            payload: payload,
          },
        });

        toast.success(t('booking.success_booking_text'));
        apolloClient.reFetchObservableQueries();
      } catch (e) {
        toast.error(t('booking.error_booking_text'));
      } finally {
        options.onSuccess && options.onSuccess();
        setSubmitting(false);
      }
    },
  });

  React.useEffect(() => {
    if (formik.values.services && formik.values.services.length) {
      let total = 0;
      for (let i = 0; i < formik.values.services.length; i++) {
        const currentService = formik.values.services[i];
        total += currentService.timeInMinutes;
      }

      formik.setValues({
        ...formik.values,
        timeInMinutes: total,
      });
    }
  }, [formik.values.services]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    formik,
    isLoading: isLoading || formik.isSubmitting,
    bookingTypes,
    setIsLoading,
  };
}
