import React from 'react';
import i18n from 'locales';
import Router from 'containers/Router';
import CssReset from 'components/CssReset';
import { I18nextProvider } from 'react-i18next';
import ApolloAppProvider from 'containers/ApolloAppProvider';
import ErrorBoundary from 'containers/ErrorBoundary';
import NetworkStatusContainer from 'containers/NetworkStatusContainer';
import { ToastContainer } from 'react-toastify';
import 'styles/app.scss';
import 'react-toggle/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { DialogProvider } from '../hooks/useDialog';

interface FetchModeProps {
  mode?: boolean;
  isLoading: boolean;
}

const DefaultNetworkComponent = (props: any) => null;

function App() {
  const [fetchMode, setFetchMode] = React.useState<FetchModeProps>({
    isLoading: true,
  });

  let NetworkProvider: any = DefaultNetworkComponent;

  React.useEffect(() => {
    async function exec() {
      const currentMode = await localStorage.getItem('mode');

      setFetchMode({
        mode:
          currentMode && currentMode !== 'undefined'
            ? JSON.parse(currentMode)
            : true,
        isLoading: false,
      });
    }

    exec();
  }, []);

  if (!fetchMode.isLoading) {
    NetworkProvider = NetworkStatusContainer;
  }

  return (
    <NetworkProvider mode={fetchMode.mode}>
      <ApolloAppProvider>
        <I18nextProvider i18n={i18n}>
          <ErrorBoundary>
            <DialogProvider>
              <Router />
            </DialogProvider>
          </ErrorBoundary>
          <CssReset />
        </I18nextProvider>
        <ToastContainer autoClose={5000} position="top-center" />
      </ApolloAppProvider>
    </NetworkProvider>
  );
}

export default App;
