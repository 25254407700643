import * as React from 'react';
import styled, { keyframes } from 'styled-components';

interface PropsInterface {
  size?: number;
  isOpen: boolean;
}

const LoadingIndicator = (props: PropsInterface) => {
  const { size = 20, isOpen } = props;

  return !!isOpen ? (
    <LoadingContainer>
      <LoadingRingContainer size={size}>
        <LoadingDiv />
        <LoadingDiv />
        <LoadingDiv />
      </LoadingRingContainer>
    </LoadingContainer>
  ) : null;
};

const RingKeyframe = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const LoadingContainer = styled.div``;
const LoadingDiv = styled.div``;
const LoadingRingContainer = styled.div<{ size: number }>`
  display: inline-block;
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  top: 40%;
  bottom: 0;
  left: 50%;
  right: 0;
  ${LoadingDiv} {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    border: 4px solid #fff;
    border-radius: 50%;
    animation: ${RingKeyframe} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000 transparent #000 #000;
  }
  ${LoadingDiv}:nth-child (1) {
    animation-delay: -0.25s;
  }
  ${LoadingDiv}:nth-child (2) {
    animation-delay: -0.1s;
  }
  ${LoadingDiv}:nth-child (3) {
    animation-delay: -0.05s;
  }
`;

export default LoadingIndicator;
