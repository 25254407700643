import color from 'styles/color';

export const getSource = (type: string, t: any) => {
  switch (type) {
    case 'Online':
      return {
        text: t('status.online'),
        color: color.fern1,
      };
    case 'Offline':
      return {
        text: t('status.offline'),
        color: color.red,
      };
    case 'Reserve':
      return {
        text: t('status.reserve'),
        color: color.orange1,
      };
    case 'Stylist':
      return {
        text: t('status.stylist'),
        color: color.regalBlue1,
      };
    default:
      return {
        text: t('status.online'),
        color: color.fern1,
      };
  }
};
