import * as React from 'react';
import { P, Button, Form, Field } from '../../../components/element';
import {
  TransactionContent,
  H4Title,
  Row,
  Separator,
  DetailContainer,
  TransactionRow,
  PriceContainer,
  Divider,
  MB16,
  Label,
} from '../style';
import { useTranslation } from 'react-i18next';
import { string2money } from 'utils/string';
import {
  CloseButton,
  Panel,
} from 'pages/bookingManagement/components/AddBooking';
import LoadingView from 'components/LoadingView';
import useTransactionForm from 'hooks/useTransactionForm';
import OutletServiceInput from 'components/transaction/OutletServiceInput';
import OutletProductsInput from 'components/transaction/OutletProductsInput';
import VoucherInput from 'components/transaction/VoucherInput';
import DialogBackdrop from 'components/dialog/dialogBackdrop';
import { Transaction as TransactionInterface } from '../../../repositories/transaction';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';
import { useDebouncedCallback } from 'use-debounce';
import { PaymentPointEnum } from 'repositories/payment';

interface Props {
  bookingData: any;
  onClose: () => void;
  date: Date;
  bookingId: string;
  afterResponse?: (
    onClose: () => void,
    transaction: TransactionInterface,
  ) => void;
}

export default function Transaction(props: Props) {
  const { bookingId, bookingData, afterResponse, date, onClose } = props;
  const [refetchVoucher, setRefetchVoucher] = React.useState(false);
  const { t } = useTranslation();

  const {
    booking: currentBooking,
    formik,
    isLoading,
    paymentMethods,
  } = useTransactionForm({
    bookingData,
    bookingId,
    date,
    onSuccess(transaction) {
      afterResponse && afterResponse(onClose, transaction);
    },
  });

  const { mode } = useNetworkStatus();

  const booking = mode ? currentBooking : bookingData;
  const debouncedRefetchVoucher = useDebouncedCallback(() => {
    if (formik.values.voucher && formik.values.voucherCode) {
      setRefetchVoucher(true);
      formik.setFieldValue('voucher', null);
    }
  }, 800);

  return (
    <DialogBackdrop onClose={props.onClose} cancelable={false}>
      <Panel>
        {booking && (
          <>
            <TransactionRow>
              <DetailContainer>
                <H4Title>{t('transaction.booking_id')}</H4Title>
                <P>{booking.id}</P>
              </DetailContainer>
              <DetailContainer>
                <H4Title>{t('common.stylist')}</H4Title>
                <P>{booking.stylist?.name}</P>
              </DetailContainer>
            </TransactionRow>
            <TransactionRow>
              <DetailContainer>
                <H4Title>{t('common.name')}</H4Title>
                <P>{booking.member.name}</P>
              </DetailContainer>
              <DetailContainer>
                <H4Title>{t('common.phone_number')}</H4Title>
                <P>{booking.member.phoneNumber || '-'}</P>
              </DetailContainer>
            </TransactionRow>
            <Separator />
            <Form formik={formik}>
              <Label>{t('common.email')}</Label>
              <Field
                type="text"
                name="email"
                placeholder={t('common.email')}
                disabled
              />
              <TransactionContent>
                <OutletServiceInput
                  values={formik.values.services}
                  onChange={(services) => {
                    formik.setFieldValue('services', services);
                    debouncedRefetchVoucher();
                  }}
                />
                <OutletProductsInput
                  values={formik.values.products}
                  onChange={(products) => {
                    formik.setFieldValue('products', products);
                    debouncedRefetchVoucher();
                  }}
                />
                <Separator />
                <PriceContainer>
                  <Row>
                    <H4Title>{t('transaction.sub_total')}</H4Title>
                    <P>{`Rp ${string2money(formik.values.subTotal)}`}</P>
                  </Row>
                  <Row>
                    <H4Title>{t('transaction.discount')}</H4Title>
                    <P>{`Rp ${string2money(formik.values.discount)}`}</P>
                  </Row>
                  <Row>
                    <H4Title>{t('transaction.total_pay')}</H4Title>
                    <P>{`Rp ${string2money(formik.values.totalPay)}`}</P>
                  </Row>
                  <Row>
                    <H4Title>{t('common.changes')}</H4Title>
                    <P>{`Rp ${string2money(
                      formik.values.payAmount - formik.values.totalPay,
                    )}`}</P>
                  </Row>
                </PriceContainer>
                {/* use any for temp till formik typing issue solved */}
                {mode && (
                  <VoucherInput
                    refetchVoucher={refetchVoucher}
                    setRefetchVoucher={setRefetchVoucher}
                    servicesName="services"
                    productsName="products"
                    voucherCode={formik.values.voucherCode}
                    voucher={formik.values.voucher}
                    onChange={formik.handleChange('voucherCode') as any}
                    onBlur={formik.handleBlur('voucherCode') as any}
                    onApplied={(voucher) => {
                      formik.setFieldValue('voucher', voucher);
                    }}
                    transactionAt={formik.values.transactionAt}
                    memberId={formik.values.memberCheckResult?.data?.id}
                  />
                )}

                <Field
                  isSearchable={false}
                  type="select"
                  name="paymentId"
                  options={paymentMethods}
                  placeholder={t('transaction.choose_payment_method')}
                  onAfterChange={(val) => {
                    formik.setFieldValue('paymentLabel', val.label);
                  }}
                />
                {formik.values.paymentLabel ===
                  PaymentPointEnum.PAYMENT_TYPE_POINT &&
                  process.env.REACT_APP_APP_NAME === 'Sir Salon' && (
                    <Field
                      type="text"
                      name="otpMember"
                      required
                      placeholder={t('transaction.input_member_otp')}
                    />
                  )}
                <Field
                  type="number"
                  name="payAmount"
                  placeholder={t('common.total_money')}
                />
                <Divider />
                {formik.values.transactionAt && (
                  <>
                    <Field
                      type="time"
                      name="transactionAt"
                      placeholder={t('common.transaction_time')}
                    />
                    <Field
                      type="text"
                      name="otp"
                      placeholder={t('common.pin')}
                    />
                    <MB16 />
                  </>
                )}
                <Button
                  type="submit"
                  isLoading={isLoading}
                  disabled={!formik.isValid}
                >
                  {t('common.completed')}
                </Button>
              </TransactionContent>
            </Form>
          </>
        )}
        <CloseButton onClick={onClose}>x</CloseButton>
        <LoadingView isOpen={isLoading} block />
      </Panel>
    </DialogBackdrop>
  );
}
