import * as React from 'react';
import { Container, Header, InputContainer, StyledReactSelect } from './style';
import { useTranslation } from 'react-i18next';
import { TableComponent } from 'components/element';
import {
  useQueryGetTransactionSummary,
  TransactionSummary,
} from 'repositories/transaction';
import { string2money } from 'utils/string';
import { getMonth } from 'utils/date';
import { useStateGetCurrentOutlet } from 'repositories/outlet';
import RecapTransactionDetail from './component/recapTransactionDetail';
import useDialog from 'hooks/useDialog';
import { LoadingContainer } from './style';
import LoadingIndicator from 'components/Loading/LoadingIndicator';
import { DateFilter } from 'pages/bookingManagement/style';
import DateInput from 'components/element/DateInput';
import { format } from 'date-fns';

function dateFormatter(date?: Date) {
  if (date) {
    return format(date, 'dd/MM/yyyy');
  }
  return "";
}

export default function TransactionRecap() {
  const { t } = useTranslation();
  const dialog = useDialog();
  const [filter, setFilter] = React.useState<{ label: string; value: string }>({
    label: t('common.daily'),
    value: 'daily',
  });
  const [dateFrom, setDateFrom] = React.useState<Date | undefined>();
  const [dateTo, setDateTo] = React.useState<Date | undefined>();

  const [selection, setSelection] = React.useState<any[]>([]);

  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';

  const [
    loadTransactionSummary,
    { called, data, loading },
  ] = useQueryGetTransactionSummary(
    outletId,
    filter.value,
    dateFormatter(dateFrom),
    dateFormatter(dateTo),
  );

  React.useEffect(() => {
    if (outletId && !called) {
      loadTransactionSummary();
    }
  }, [called, loadTransactionSummary, outletId]);

  const onFilterChange = (value: { label: string; value: string }) => {
    setFilter(value);
  };

  const onSelectionChange = (params: React.ReactText[]) => {
    setSelection([params[params.length - 1]]);
    if (params[params.length - 1] !== undefined && data) {
      const row = data.transactionSummary[params[params.length - 1] as number];
      dialog.showCustom({
        render(onClose) {
          return (
            <RecapTransactionDetail
              onClose={() => {
                onClose();
                setSelection([]);
              }}
              adminEmail={row.adminEmail}
              adminId={row.adminId}
              date={row.date}
              month={row.month}
              year={row.year}
            />
          );
        },
      });
    }
  };

  const column = React.useMemo(
    () => [
      {
        name: 'date',
        title: t('common.date'),
        getCellValue: (row: TransactionSummary) => {
          if (filter && filter.value === 'daily') {
            return row.date;
          } else {
            if (filter && filter.value === 'monthly') {
              const month = getMonth(parseFloat(row.month), t);
              if (row.month && row.year) {
                return `${month} ${row.year}`;
              }
            }
          }

          return '';
        },
      },
      {
        name: 'adminEmail',
        title: t('common.cashier'),
      },
      {
        name: 'service',
        title: t('transaction.total_service'),
      },
      { name: 'product', title: t('transaction.total_product') },
      { name: 'pending', title: t('transaction.total_pending') },
      {
        name: 'totalPrice',
        title: t('transaction.total_sale'),
        getCellValue: (row: TransactionSummary) =>
          `Rp. ${string2money(row.totalPrice)}`,
      },
      {
        name: 'totalDiscount',
        title: t('transaction.total_discount'),
        getCellValue: (row: TransactionSummary) =>
          `Rp. ${string2money(row.totalDiscount)}`,
      },
      {
        name: 'totalPay',
        title: t('transaction.total_payment'),
        getCellValue: (row: TransactionSummary) =>
          `Rp. ${string2money(row.totalPay)}`,
      },
      {
        name: 'totalRefund',
        title: t('transaction.total_refund'),
        getCellValue: (row: TransactionSummary) =>
          `Rp. ${string2money(row.totalRefund)}`,
      },
    ],
    [filter, t],
  );

  const options = React.useMemo(
    () => [
      {
        label: t('common.daily'),
        value: 'daily',
      },
      {
        label: t('common.monthly'),
        value: 'monthly',
      },
    ],
    [t],
  );

  const onDateFromChange = (date: any) => {
    setDateFrom(date);
  };

  const onDateToChange = (date: any) => {
    setDateTo(date);
  };

  return (
    <Container>
      <Header>
        <InputContainer>
          <StyledReactSelect
            className="react-select__container"
            classNamePrefix="react-select"
            name="day"
            value={filter}
            options={options}
            //@ts-ignore
            onChange={onFilterChange}
            isSearchable={false}
          />
        </InputContainer>
        <InputContainer>
          <DateFilter
            selected={dateFrom}
            onChange={onDateFromChange}
            dateFormat="yyyy-MM-dd"
            customInput={<DateInput />}
            placeholderText="Dari Tanggal"
            // onFocus={(e) => (e.target.readOnly = true)}
            // onBlur={(e) => (e.target.readOnly = false)}
          />
        </InputContainer>
        <InputContainer>
          <DateFilter
            selected={dateTo}
            onChange={onDateToChange}
            dateFormat="yyyy-MM-dd"
            customInput={<DateInput />}
            placeholderText="Sampai Tanggal"
            // onFocus={(e) => (e.target.readOnly = true)}
            // onBlur={(e) => (e.target.readOnly = false)}
          />
        </InputContainer>
        <LoadingContainer>
          <LoadingIndicator isOpen={called && loading} />
        </LoadingContainer>
      </Header>
      <TableComponent
        rows={data ? data.transactionSummary : []}
        columns={column}
        selection={selection}
        onSelectionChange={onSelectionChange}
        enableSelection
      />
    </Container>
  );
}
