import * as React from 'react';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import { Button } from 'components/element';
import { withTranslation, WithTranslation } from 'react-i18next';
import color from 'styles/color';

class ErrorBoundary extends React.Component<
  WithTranslation,
  { hasError: boolean }
> {
  constructor(props: WithTranslation) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    if (!!this.state.hasError) {
      return (
        <Container onClick={() => window.location.reload()}>
          <ErrorPanel>
            <Image src={require('assets/images/png/crash.png')} alt="" />
          </ErrorPanel>
          <Message>{this.props.t('common.error_text')}</Message>
          <CustomButton>{this.props.t('common.reload')}</CustomButton>
        </Container>
      );
    }
    // @ts-expect-error
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const ErrorPanel = styled.div`
  width: 40vh;
  height: 40vh;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const Message = styled.h1`
  margin-bottom: 16px;
  text-align: center;
`;

const CustomButton = styled(Button)`
  min-width: 150px;
  max-width: 300px;
  background-color: ${color.white};
  color: ${color.black};
  border-radius: 10px;
  font-size: 20px;
`;
