import * as React from 'react';
import { Container, Brand, Subtitle, HeaderSection } from './styles';
import useAppState from 'hooks/useAppState';

interface Props {
  children: React.ReactNode;
}

const ReceiptLayout = React.forwardRef<any, Props>((props, ref) => {
  const { children } = props;
  const { currentOutlet } = useAppState();
  return (
    <Container ref={ref}>
      {currentOutlet && (
        <HeaderSection>
          <Brand>{currentOutlet.name.toLowerCase()}</Brand>
          <Subtitle>{currentOutlet.address}</Subtitle>
          <Subtitle>Telp: {currentOutlet.phoneNumber}</Subtitle>
        </HeaderSection>
      )}
      {children}
    </Container>
  );
});

export default ReceiptLayout;
