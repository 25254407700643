import * as React from 'react';
import Select, { SelectProps } from './Select';

export interface SimpleSelectProps<T>
  extends Pick<
    SelectProps<T>,
    | 'isSearchable'
    | 'isLoading'
    | 'placeholder'
    | 'value'
    | 'onBlur'
    | 'options'
    | 'isDisabled'
  > {
  name: string;
  onChange?: (value: string) => void;
  onMenuOpen?: any;
  onAfterChange?: (value: any) => void;
}

export default function SimpleSelect<T>(props: SimpleSelectProps<T>) {
  const { name, value, onChange, onAfterChange, ...rest } = props;

  // todo: support multiple value for simpleValue support
  const _value = React.useMemo(
    () => (props.options as any).find((o: any) => o.value === value) as any,
    [props.options, value],
  );

  const _onChange = React.useCallback(
    (value: any) => {
      onChange?.(value.value);
      onAfterChange?.(value);
    },
    [onAfterChange, onChange],
  );

  return <Select value={_value || null} onChange={_onChange} {...rest} />;
}
