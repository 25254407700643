import * as React from 'react';
import DialogBackdrop from 'components/dialog/dialogBackdrop';
import styled from 'styled-components';
import { H1, H4, P, H3, Button, Select } from 'components/element';
import { useTranslation } from 'react-i18next';
import color from 'styles/color';
import ReactSelect from 'react-select';
import { useQueryGetBooking } from 'repositories/booking/booking.query';
import {
  useMutationChangeBookingStatus,
  useMutationCancelBookingSheet,
  useMutationUpdateBookingSheet,
} from 'repositories/booking/booking.mutation';
import { useApolloClient } from '@apollo/client';
import LoadingView from 'components/LoadingView';
import { toast } from 'react-toastify';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';

interface Props {
  bookingData: any;
  bookingId: string;
  onClose: () => void;
  showStatus?: boolean;
  showPending?: boolean;
}

interface CancelInterface {
  cancelNote: string;
  isShowing: boolean;
}

const initialCancelState = {
  cancelNote: '',
  isShowing: false,
};

export default function BookingDetail(props: Props) {
  const { t } = useTranslation();
  const apolloClient = useApolloClient();

  const [isLoading, setIsLoading] = React.useState(false);
  const [cancelState, setCancelState] = React.useState<CancelInterface>(
    initialCancelState,
  );
  const [status, setStatus] = React.useState<{
    label: string;
    value: number;
  }>();

  const {
    bookingData,
    bookingId,
    onClose,
    showStatus = false,
    showPending = false,
  } = props;

  const { mode } = useNetworkStatus();
  const { data, loading } = useQueryGetBooking(bookingId);
  const [setBookingStatus] = useMutationChangeBookingStatus();
  const [unsetBookingSheet] = useMutationCancelBookingSheet();
  const [updateBookingSheet] = useMutationUpdateBookingSheet();

  const booking = data ? data.booking.data : bookingData;
  const timeDuration = React.useMemo(
    () =>
      (booking?.services.reduce((prev, curr) => prev + curr.timeInMinutes, 0) ||
        180) / 10,
    [booking?.services, booking?.thresholdTime],
  );

  const options = React.useMemo(
    () => [
      {
        label: t('status.scheduled'),
        value: 1,
      },
      {
        label: t('status.started'),
        value: 2,
      },
      {
        label: t('status.completed'),
        value: 3,
      },
      {
        label: t('status.cancelled'),
        value: 9,
      },
    ],
    [t],
  );

  const durations = React.useMemo(
    () =>
      Array.from({ length: timeDuration }, (v, index) => {
        const duration = (index + 1) * 10;
        return {
          value: `${duration}`,
          label: `${duration} mins`,
        };
      }),
    [timeDuration],
  );

  React.useEffect(() => {
    if (booking) {
      switch (booking.status) {
        case 1:
          setStatus({
            label: t('status.scheduled'),
            value: 1,
          });
          break;
        case 2:
          setStatus({
            label: t('status.started'),
            value: 2,
          });
          break;
        case 3:
          setStatus({
            label: t('status.completed'),
            value: 3,
          });
          break;
        case 9:
          setStatus({
            label: t('status.cancelled'),
            value: 9,
          });
          break;
        default:
          setStatus({
            label: t('status.scheduled'),
            value: 1,
          });
      }
    }
  }, [booking, t]);

  const onStatusChange = React.useCallback(
    (value: { label: string; value: number }) => {
      setStatus(value);
    },
    [],
  );

  const onClickSave = React.useCallback(async () => {
    if (status) {
      if (status.value === 9) {
        setCancelState((prev: CancelInterface) => ({
          ...prev,
          isShowing: true,
        }));
        return;
      }

      try {
        setIsLoading(true);
        if (booking.status !== status.value) {
          await setBookingStatus({
            variables: {
              bookingId: bookingId,
              payload: {
                status: status?.value,
              },
            },
          });

          apolloClient.reFetchObservableQueries();
        }
      } catch (e) {
        if (!mode) {
          if ((e as any).message) {
            toast.error((e as any).message);
          }
        }
      } finally {
        setIsLoading(false);
        onClose();
      }
    }
  }, [
    apolloClient,
    booking.status,
    bookingId,
    mode,
    onClose,
    setBookingStatus,
    status,
  ]);

  const onConfirmCancel = React.useCallback(async () => {
    // if (status?.value === 9 && !(await authorizeWithTotp())) {
    //   toast.error(t('common.wrong_pin'));
    //   return;
    // }

    try {
      setIsLoading(true);
      await setBookingStatus({
        variables: {
          bookingId: bookingId,
          payload: {
            status: status?.value,
            cancel_note: cancelState.cancelNote,
          },
        },
      });

      apolloClient.reFetchObservableQueries();
    } catch (e) {
      if (!mode) {
        toast.error((e as any).message);
      }
    } finally {
      setIsLoading(false);
      onClose();
    }
  }, [
    apolloClient,
    bookingId,
    cancelState.cancelNote,
    mode,
    onClose,
    setBookingStatus,
    status,
  ]);

  const onClickPending = React.useCallback(async () => {
    const response = window.confirm(t('common.confirmation_change_text'));
    if (response) {
      try {
        setIsLoading(true);
        await unsetBookingSheet({
          variables: {
            payload: {
              booking_id: bookingId,
            },
          },
        });
        apolloClient.reFetchObservableQueries();
        onClose();
      } catch (e) {
        if (!mode) {
          toast.error((e as any).message);
        }
      } finally {
        setIsLoading(false);
      }
    }
  }, [apolloClient, bookingId, mode, onClose, t, unsetBookingSheet]);

  const onTimeDurationChange = React.useCallback(
    async (value) => {
      try {
        setIsLoading(true);
        const { data } = await updateBookingSheet({
          variables: {
            payload: {
              booking_id: bookingId,
              time_in_minutes: value,
            },
          },
        });
        setIsLoading(false);
        toast.success(data?.bookingSheets.message || 'Success');
        onClose();
        apolloClient.reFetchObservableQueries();
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    },
    [bookingId, updateBookingSheet, apolloClient, onClose],
  );

  const onCancelNoteChange = React.useCallback((e) => {
    e.persist();
    setCancelState((prev: CancelInterface) => ({
      ...prev,
      cancelNote: e.target.value,
    }));
  }, []);

  const buttonContent = React.useMemo(() => {
    if (cancelState.isShowing && status?.value === 9) {
      return (
        <>
          <H3Title>{t('common.cancel_reason')}</H3Title>
          <CancelNoteTextArea
            placeholder={t('common.write_reason')}
            name="cancelReason"
            onChange={onCancelNoteChange}
            value={cancelState.cancelNote}
          />
          <ButtonPad />
          <ConfirmCancelButton
            onClick={onConfirmCancel}
            disabled={!cancelState.cancelNote}
          >
            {t('common.confirm_cancel')}
          </ConfirmCancelButton>
        </>
      );
    }
    return (
      <>
        {showStatus && (
          <>
            <ButtonPad />
            <Button
              onClick={onClickSave}
              disabled={isLoading}
              isLoading={isLoading}
            >
              {t('common.save')}
            </Button>
          </>
        )}
        {showPending && (
          <>
            <ButtonPad />
            <OutlineButton onClick={onClickPending} isLoading={isLoading}>
              {t('common.pending')}
            </OutlineButton>
          </>
        )}
      </>
    );
  }, [
    cancelState.cancelNote,
    cancelState.isShowing,
    isLoading,
    onCancelNoteChange,
    onClickPending,
    onClickSave,
    onConfirmCancel,
    showPending,
    showStatus,
    status,
    t,
  ]);

  return (
    <DialogBackdrop onClose={onClose} cancelable={false}>
      <Panel>
        {booking && (
          <>
            <Title>{t('common.booking_detail')}</Title>
            <ButtonPad />
            <H4Title>{t('common.name')}</H4Title>
            {showStatus && (
              <StyledReactSelect
                className="react-select__container"
                classNamePrefix="react-select"
                name="day"
                value={status}
                options={options}
                //@ts-expect-error
                onChange={onStatusChange}
              />
            )}
            <P>{booking.member.name}</P>
            <H4Title>{t('common.phone_number')}</H4Title>
            <P>{booking.member.phoneNumber || '-'}</P>
            <H4Title>{t('common.random_stylist')}</H4Title>
            <P>
              {typeof booking.isRandomStylist === 'boolean'
                ? booking.isRandomStylist.toString()
                : '-'}
            </P>
            <H4Title>{t('common.email')}</H4Title>
            <P>{booking.member.email || '-'}</P>
            <H4Title>{t('common.stylist')}</H4Title>
            <P>{booking.stylist ? booking.stylist.name : '-'}</P>
            <Divider />
            <Select<any>
              name="timeInMinutes"
              value={`${booking.timeInMinutes}`}
              onChange={onTimeDurationChange}
              options={durations}
              isDisabled={isLoading}
            />
            <H3Title>{t('common.service')}</H3Title>
            {booking.services &&
              booking.services.map((service: any) => {
                return (
                  <Item>{`${
                    service.service ? service.service.name : service.name
                  }`}</Item>
                );
              })}
            {booking.products && !!booking.products.length && (
              <H3Title>{t('common.product')}</H3Title>
            )}
            {booking.products &&
              booking.products.map((product: any) => {
                return (
                  <Item>{`${product.qty ? product.qty + 'x' : ''} ${
                    product.product ? product.product.name : product.name
                  }`}</Item>
                );
              })}
            {buttonContent}
          </>
        )}
        <LoadingView isOpen={loading} block />
        <CloseButton onClick={onClose} disabled={isLoading}>
          x
        </CloseButton>
      </Panel>
    </DialogBackdrop>
  );
}

const ButtonPad = styled.div`
  margin-bottom: 1rem;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding-bottom: 1px;
  color: ${color.black};
  background-color: ${color.white};
  font-size: 36px;
  line-height: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  outline: none;
  border: none;
  appearance: none;
`;

const OutlineButton = styled(Button)`
  background-color: ${color.white};
  color: ${color.black};
`;

const Panel = styled.div`
  width: 28%;
  background-color: white;
  border-radius: 5px;
  min-height: 300px;
  max-height: 90vh;
  padding: 1rem;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow-y: scroll;

  @media (max-width: 1023px) {
    width: 70%;
  }
`;

const Title = styled(H1)`
  text-align: center;
`;

const H4Title = styled(H4)`
  margin-top: 0.8rem;
`;

const Item = styled(P)`
  margin-bottom: 4px;
`;

const H3Title = styled(H3)`
  margin-bottom: 0.5rem;
  padding-bottom: 0.2rem;
  display: flex;
  margin-top: 0.8rem;
  align-self: flex-start;
  width: auto;
  border-bottom: 1px solid ${color.black};
`;

const StyledReactSelect = styled(ReactSelect)`
  &.react-select__container {
    width: 150px;
    position: absolute;
    right: 16px;
    top: 96px;
    .react-select__control {
      border-radius: 20px;
      min-height: 30px;
      border: 1px solid ${color.black};
    }
  }
`;

const CancelNoteTextArea = styled.textarea`
  margin-top: 0.5rem;
  padding: 0.5rem;
  min-height: 60px;
  min-width: 100%;
  max-width: 100%;
`;

const ConfirmCancelButton = styled(Button)`
  background-color: ${color.redOrange1};
  border: 1px solid ${color.redOrange1};
`;

const Divider = styled.div`
  margin-bottom: 8px;
`;
