import * as React from 'react';
import {
  ProductRow,
  H3Title,
  Space,
  ItemRow,
  QuantityButton,
  ProductQuantity,
  AddButton,
  Text,
} from 'pages/transactionManagement/style';
import { pad } from 'utils/date';
import { useQueryGetOutletProducts, OutletProduct } from 'repositories/outlet';
import { useTranslation } from 'react-i18next';
import { ProductSingleValue } from 'components/SingleValue';
import { P, Div } from 'components/element';
import PlusIcon from 'assets/images/plus';
import { TransactionProduct } from 'repositories/transaction';
import useAppState from 'hooks/useAppState';
import Select, { onSelect } from 'components/element/Select';
import { NewSelectDetail } from 'components/SelectDetail';
import PriceComponent from 'components/PriceComponent';

interface Props {
  values: TransactionProduct[];
  onChange?: (transactionproducts: TransactionProduct[]) => void;
}

export interface ProductOption {
  extra: OutletProduct;
  qty: number;
  [key: string]: any;
}

export default function OutletProductsInput(props: Props) {
  const { onChange, values } = props;
  const { t } = useTranslation();
  const { currentOutlet } = useAppState();
  const outletId = currentOutlet?.id || '';
  const [options, setOptions] = React.useState<ProductOption[]>([]);
  const [
    selectedProduct,
    setSelectedProduct,
  ] = React.useState<ProductOption | null>(null);

  useQueryGetOutletProducts(
    outletId,
    React.useCallback((data) => {
      setOptions(
        data.outletProducts.data.map((product: any) => ({
          label: product.name,
          value: product.id,
          extra: product,
          qty: 1,
        })),
      );
    }, []),
  );

  const updateSelectedProductQty = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSelectedProduct((prevState: ProductOption | null) => {
        if (prevState) {
          return {
            ...prevState,
            qty: parseFloat(value),
          };
        }
        return null;
      });
    },
    [],
  );

  function updateTransactionProductQty(
    id: string,
    qty: number,
  ): [TransactionProduct[], boolean] {
    let isUpdate = false;
    let newProducts = values.map((transactionProduct) => {
      if (transactionProduct.product.id === id) {
        isUpdate = true;
        const newQty = transactionProduct.qty + qty;
        return {
          ...transactionProduct,
          qty: newQty > 0 ? newQty : transactionProduct.qty,
        };
      }
      return transactionProduct;
    });
    return [newProducts, isUpdate];
  }

  function onProductAdded() {
    if (!selectedProduct || selectedProduct.qty <= 0) {
      return;
    }
    let [newProducts, isUpdate] = updateTransactionProductQty(
      selectedProduct.extra.id,
      selectedProduct.qty,
    );
    if (!isUpdate) {
      newProducts = newProducts.concat({
        price: selectedProduct.extra.price,
        qty: selectedProduct.qty,
        totalPrice: selectedProduct.extra.price * selectedProduct.qty,
        product: {
          id: selectedProduct.extra.id,
          name: selectedProduct.extra.name,
          imagePath: selectedProduct.extra.imagePath,
          price: selectedProduct.extra.price,
          point: selectedProduct.extra.point,
          isPriceList: selectedProduct.extra.isPriceList,
          regularPrice: selectedProduct.extra.regularPrice,
        },
      });
    }
    onChange && onChange(newProducts);
    setSelectedProduct(null);
  }

  function increaseQty(id: string) {
    const [newProducts] = updateTransactionProductQty(id, 1);
    onChange && onChange(newProducts);
  }

  function decreaseQty(id: string) {
    const [newProducts] = updateTransactionProductQty(id, -1);
    onChange && onChange(newProducts);
  }

  function removeProduct(index: number) {
    onChange &&
      onChange(values.slice(0, index).concat(values.slice(index + 1)));
  }

  return (
    <>
      <H3Title>{t('common.product')}</H3Title>
      {values &&
        values.map((product, index) => (
          <Space>
            <Div>
              <P>{product.product.name}</P>
              <PriceComponent
                {...{
                  price: product.price,
                  isPriceList: product.product.isPriceList,
                  isSelected: false,
                  regularPrice: product.product.regularPrice,
                }}
              />
            </Div>
            <ItemRow>
              <QuantityButton
                onClick={() => {
                  if (product.qty <= 1) {
                    removeProduct(index);
                  } else {
                    decreaseQty(product.product.id);
                  }
                }}
              >
                -
              </QuantityButton>
              <P>{`${pad(product.qty)}`}</P>
              <QuantityButton
                onClick={() => {
                  increaseQty(product.product.id);
                }}
              >
                +
              </QuantityButton>
            </ItemRow>
          </Space>
        ))}
      <ProductRow>
        <Select<ProductOption>
          isMulti={false}
          options={options}
          components={{ SingleValue: ProductSingleValue }}
          formatOptionLabel={NewSelectDetail}
          value={selectedProduct}
          onChange={onSelect(({ singleValue }) => {
            setSelectedProduct(singleValue || null);
          })}
          placeholder={t('booking.product_type')}
        />
        <ProductQuantity
          type="number"
          value={selectedProduct?.qty || ''}
          onChange={updateSelectedProductQty}
          placeholder={t('booking.quantity')}
        />
      </ProductRow>
      <AddButton type="button" onClick={onProductAdded}>
        <PlusIcon width={25} height={25} />
        <Text>{t('common.product')}</Text>
      </AddButton>
    </>
  );
}
