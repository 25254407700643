import styled from 'styled-components';
import color from 'styles/color';
import ReactSelect from 'react-select';

export const Container = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${color.border};
  margin-bottom: 1rem;
  padding-bottom: 1rem;
`;

export const StyledReactSelect = styled(ReactSelect)`
  &.react-select__container {
    width: 150px;
    .react-select__control {
      border-radius: 20px;
      min-height: 30px;
      border: 1px solid ${color.black};
    }

    .react-select__menu {
      z-index: 1000 !important;
    }
  }
`;

export const LoadingContainer = styled.div`
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
`;

export const InputContainer = styled.div`
  padding-right: 16px;
`;
