import * as React from 'react';
import { FormikProvider, FormikContextType } from 'formik';
import styled from 'styled-components';

interface Props {
  formik: FormikContextType<any>;
  children: React.ReactNode;
  disabled?: boolean;
}

function Form(props: Props) {
  const { formik, disabled: propsDisabled = false } = props;
  const disabled = propsDisabled || !formik.isValid;
  const onSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!disabled && formik.handleSubmit) {
        formik.handleSubmit();
      }
    },
    [disabled, formik],
  );
  return (
    <FormikProvider value={formik}>
      <StyledForm onSubmit={onSubmit}>{props.children}</StyledForm>
    </FormikProvider>
  );
}

export const StyledForm = styled.form`
  width: 100%;
`;

export default Form;
