import * as React from 'react';
import { Container, Header } from './style';
import { useTranslation } from 'react-i18next';
import { TableComponent } from 'components/element';
import { useStateGetCurrentOutlet } from 'repositories/outlet';
import { LoadingContainer } from './style';
import LoadingIndicator from 'components/Loading/LoadingIndicator';
import { useQueryGetStockBalances } from 'repositories/item/item.query';

export default function TransactionRecap(props: any) {
  const [page, setPage] = React.useState(1);
  const limit = 10;
  const { t } = useTranslation();

  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';

  const { called, data, loading } = useQueryGetStockBalances(
    outletId,
    page,
    limit,
  );

  const column = React.useMemo(
    () => [
      {
        name: 'name',
        title: t('common.name'),
      },
      {
        name: 'qty',
        title: t('common.quantity'),
      },
    ],
    [t],
  );

  return (
    <Container>
      <Header>
        <LoadingContainer>
          <LoadingIndicator isOpen={called && loading} />
        </LoadingContainer>
      </Header>
      <TableComponent
        rows={data ? data.getStockBalances.data : []}
        columns={column}
        loading={loading}
        totalCount={data?.getStockBalances.meta.total || 0}
        pageSize={limit}
        page={page}
        onPageChange={(page) => setPage(page + 1)}
        enablePagination
        enableDetailRow
        rowDetail={({ row }) => {
          return (
            <>
              {row.description && (
                <div dangerouslySetInnerHTML={{ __html: row.description }} />
              )}
            </>
          );
        }}
      />
    </Container>
  );
}
