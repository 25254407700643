import * as React from 'react';
import styled from 'styled-components';
import size from 'styles/size';
import color from 'styles/color';
import { useDebounce } from 'use-debounce';
import { take } from 'hooks/useEmailAutocomplete';

type TextPredictionProps = {
  textPrediction?: string[] | ((input: string) => string[]);
  maxPredictionCount?: number;
};
export type Props = React.InputHTMLAttributes<HTMLInputElement> &
  TextPredictionProps & {
    clearable?: boolean;
  };

function PredictionWindow(
  props: Pick<Props, 'value' | 'onChange' | 'name' | keyof TextPredictionProps>,
) {
  const {
    textPrediction,
    value,
    onChange,
    name,
    maxPredictionCount = 8,
  } = props;
  const [predictions] = useDebounce(
    React.useMemo(() => {
      if (!value || !textPrediction) return [];
      const raw = value.toString();
      if (typeof textPrediction === 'function') {
        return textPrediction(raw);
      }
      return take(textPrediction, maxPredictionCount, (prediction) =>
        prediction.startsWith(raw),
      );
    }, [maxPredictionCount, textPrediction, value]),
    500,
  );
  // Stealing focus will close the popup
  const doNotStealFocus = React.useCallback((e) => e.preventDefault(), []);

  if (predictions.length === 0) return null;
  return (
    <PredictionWindowContainer className="prediction-window-container">
      <PredictionItemsContainer>
        {predictions.map((x) => (
          <PredictionItemContainer
            key={x}
            onClick={() => {
              onChange?.({
                currentTarget: {
                  name: name || '',
                  value: x,
                },
              } as any);
            }}
            onMouseDown={doNotStealFocus}
          >
            {x}
          </PredictionItemContainer>
        ))}
      </PredictionItemsContainer>
    </PredictionWindowContainer>
  );
}

const TextInput = React.forwardRef<HTMLInputElement, Props>(function TextInput(
  props,
  ref,
) {
  const {
    onChange,
    clearable = false,
    value,
    style,
    textPrediction,
    autoComplete,
    name,
    maxPredictionCount,
    ...restProps
  } = props;

  return (
    <InputContainer style={style} {...restProps}>
      {clearable && !!value && (
        <ClearContainer
          onClick={(e) => {
            onChange?.({
              currentTarget: {
                name: name || '',
                value: '',
              },
            } as any);
          }}
        >
          ✕
        </ClearContainer>
      )}
      <StyledInput
        ref={ref}
        name={name}
        style={style}
        value={value}
        onChange={onChange}
        autoComplete={textPrediction ? 'off' : autoComplete || 'auto'}
        {...restProps}
        clearable={clearable}
      />
      {textPrediction && (
        <PredictionWindow
          name={name}
          onChange={onChange}
          textPrediction={textPrediction}
          value={value}
          maxPredictionCount={maxPredictionCount}
        />
      )}
    </InputContainer>
  );
});

const PredictionWindowContainer = styled.div`
  position: absolute;
  top: 110%;
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  z-index: 3;
  max-height: 200px;
  border-radius: ${size.inputBorder};
  overflow-y: auto;
  padding: 16px;
`;

const PredictionItemsContainer = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const PredictionItemContainer = styled.li`
  padding: 8px;
  &:hover {
    background-color: ${color.neutralSpindle};
    cursor: pointer;
  }
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  border-radius: ${size.inputBorder};
  min-height: ${size.inputMinHeight};
`;

const ClearContainer = styled.div`
  position: absolute;
  right: 8px;
  cursor: pointer;
  z-index: 100;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledInput = styled.input<{ clearable: boolean }>`
  width: 100%;
  padding-right: ${(props) => (props.clearable ? '36px !important' : 'unset')};
  min-height: ${size.inputMinHeight};
  font-weight: bold;
  margin-bottom: 0px !important;
  padding: 0px ${size.inputPadding};
  border-radius: ${size.inputBorder};
  border: 1px solid ${color.border};
  appearance: none;
  outline: none;
  &:focus {
    box-shadow: none;
    border-color: ${color.accent};
  }
  &:focus ~ .prediction-window-container {
    display: block;
  }
  &:not(:focus) ~ .prediction-window-container {
    display: none;
  }
  &::placeholder {
    color: ${color.placeholder};
  }
`;

export default TextInput;
