import {
  gql,
  useMutation,
  MutationFunctionOptions,
  useApolloClient,
} from '@apollo/client';
import { Transaction } from './transaction.model';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';
import { camelizeKeys } from 'humps';
import {
  createTransaction,
  addOfflineTransactionCache,
  cancelTransaction,
  printTransaction,
} from 'helpers/transaction';
import { MasterData } from 'repositories/common/common.model';
import localForage from 'localforage';
import { removeFinishedBooking } from 'helpers/booking';
import { useStateGetCurrentOutlet } from 'repositories/outlet';
import { TRANSACTION_FRAGMENT } from './transaction.fragment';
import { v4 as uuidv4 } from 'uuid';

const POST_TRANSACTION = gql`
  fragment TransactionInput on REST {
    id: String
    phone_number: String
    outlet_id: String
    service_ids: any
    products: any
    voucher_code: String
    booking_id: String
    payment_id: String
    customer_name: String
    transaction_at: String
    otp: String
    pay_amount: Int
  }

  mutation transaction($payload: TransactionInput!) {
    transaction(payload: $payload)
      @rest(
        type: "TransactionPayload"
        path: "/transactions"
        method: "POST"
        bodyKey: "payload"
      ) {
      data @type(name: "TransactionDetail") {
        id
        outlet {
          id
          name
          phoneNumber
          description
          address
          longitude
          latitude
          cityId
          rate
          rateCount
          outletImages @type(name: "OutletImage") {
            id
            index
            filePath
          }
        }
        booking {
          id
          date
          outlet {
            id
            name
            phoneNumber
            description
            address
            longitude
            latitude
            cityId
            rate
            rateCount
            outletImages @type(name: "OutletImage") {
              id
              index
              filePath
            }
          }
        }
        transactionPayment {
          amount
          paymentFee
          paymentName
          fee1
          fee1Type
          fee22
          fee2Type
          fee3
          fee3Type
        }
        stylist {
          id
          name
          email
          phoneNumber
          rate
          rateCount
          imagePath
        }
        startMinute
        timeInMinutes
        isRandomStylist
        type
        status
        services @type(name: "Service") {
          totalPrice
          price
          timeInMinutes
          qty
          service {
            id
            name
            price
            imagePath
            timeInMinutes
          }
        }
        products @type(name: "Product") {
          totalPrice
          price
          timeInMinutes
          qty
          product {
            id
            code
            name
            imagePath
            description
            price
            productDetails @type(name: "ProductDetail") {
              id
              index
              filePath
            }
          }
        }
        member {
          id
          phoneNumber
          code
          name
          birthDate
          email
          address
          verificationToken
          isVerified
          verifyAt
          isEnable
          point
          memberCardPath
          createdAt
          notificationCount
        }
        voucher {
          id
          code
          startAt
          validUntil
          description
          discountType
          discountValue
          maxDiscountAmount
          limit
          category
          isAvailable
          createdAt
          updatedAt
        }
        stylist {
          id
          name
          email
          phoneNumber
          rate
          rateCount
          imagePath
        }
        review {
          rateOutlet
          rateStylist
          comment
          member {
            name
          }
          createdAt
        }
        isRandomStylist
        timeInMinutes
        status
        totalPrice
        discount
        totalPay
        payAmount
        paymentFee
        totalStylistCommission
        adminCommission
        payAmount
        inputBy {
          name
          email
        }
        updatedBy {
          name
          email
        }
        transactionAt
        totalPoint
        pointMutation {
          amount
          title
          transactionAt
          transactionableId
        }
      }
    }
  }
`;

const POST_CANCEL_TRANSACTION = gql`
  fragment CancelTransactionInput on REST {
    cancel_note: String
    cancel_payment_id: String
  }

  mutation cancelTransaction(
    $transactionId: String!
    $payload: CancelTransactionInput!
  ) {
    cancelTransaction(transactionId: $transactionId, payload: $payload)
      @rest(
        path: "/transactions/{args.transactionId}/cancel"
        method: "POST"
        bodyKey: "payload"
      ) {
      message
    }
  }
`;

const POST_PRINT_TRANSACTION = gql`
  mutation printTransaction($transactionId: String!) {
    printTransaction(transactionId: $transactionId, input: {})
      @rest(path: "/transactions/{args.transactionId}/print", method: "POST") {
      message
    }
  }
`;

export function useMutationCreateTransaction() {
  const { mode } = useNetworkStatus();
  const client = useApolloClient();

  const mutation = useMutation<{ transaction: { data: Transaction } }>(
    POST_TRANSACTION,
  );

  if (!mode) {
    const createTransactionFunction = async function (
      options?: MutationFunctionOptions<any, Record<string, any>>,
    ) {
      let currentTransaction;
      if (options?.variables?.payload) {
        const payload: any = camelizeKeys(options?.variables.payload);

        let result: MasterData | string | null = localStorage.getItem(
          'masterData',
        );
        const adminEmail = await localForage.getItem('adminEmail');
        let admins: any[] = [];
        let stylists: any[] = [];
        let payments: any[] = [];

        if (result && result !== 'undefined') {
          result = JSON.parse(result) as MasterData;

          const outlet = result?.outlets.find(
            (outlet: any) => outlet.id === payload.outletId,
          );

          if (outlet) {
            stylists = outlet.stylists;
          }

          if (result) {
            admins = result.admins || [];
            payments = result.payments || [];
          }
        }

        const currentStylist = stylists.find(
          (stylist) => stylist.id === payload.stylistId,
        );
        const currentAdmin = admins.find((admin) => admin.email === adminEmail);
        const currentPayment = payments.find(
          (payment) => payment.id === payload.paymentId,
        );

        const printlog = [
          {
            id: uuidv4(),
            printAt: new Date().toISOString(),
            transactionId: payload.id,
            inputBy: currentAdmin.id || 0,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          },
        ];

        await createTransaction(payload, client);
        await addOfflineTransactionCache(payload, printlog);
        if (payload.bookingId) {
          await removeFinishedBooking(
            payload.bookingId,
            payload.outletId,
            client,
          );
        }

        const totalPointService = payload.serviceId.reduce(
          (prev: number, next: any) => {
            return prev + next.service.point;
          },
          0,
        );

        const totalPointProduct = payload.products.reduce(
          (prev: number, next: any) => {
            return prev + next.product.point * next.qty;
          },
          0,
        );

        currentTransaction = {
          id: payload.id,
          bookingId: payload.bookingId || '',
          outlet: null,
          status: payload.status,
          totalPrice: payload.totalPay,
          totalPay: payload.totalPay,
          totalPoint: totalPointService + totalPointProduct,
          payAmount: payload.payAmount,
          stylist: {
            id: currentStylist ? currentStylist.id : '',
            name: currentStylist ? currentStylist.name : '',
          },
          services: payload.serviceId || [],
          products: payload.products || [],
          member: {
            id: '',
            name: payload.customerName || '',
            phoneNumber: payload.phoneNumber || '',
            description: '',
            address: null,
            birthDate: null,
            point: null,
            email: null,
            imagePath: '',
          },
          voucher: null,
          discount: 0,
          inputBy: {
            name: currentAdmin.name || '',
            email: currentAdmin.email || '',
          },
          updatedBy: {
            name: currentAdmin.name || '',
            email: currentAdmin.email || '',
          },
          transactionPayment: {
            paymentName: currentPayment.name || '',
          },
          transactionAt: new Date().toISOString(),
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
      }

      return {
        data: {
          transaction: {
            data: currentTransaction as any,
          },
        },
      };
    };

    return [createTransactionFunction];
  }

  return mutation;
}

export function useMutationCancelTransaction() {
  const { mode } = useNetworkStatus();
  const client = useApolloClient();

  const mutation = useMutation<{ cancelTranscation: { message: string } }>(
    POST_CANCEL_TRANSACTION,
  );

  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';

  if (!mode) {
    const cancelTransactionFunction = async function (
      options?: MutationFunctionOptions<any, Record<string, any>>,
    ) {
      if (options?.variables?.payload) {
        const payload: any = camelizeKeys(options?.variables.payload);
        const transactionId = options?.variables?.transactionId;

        await cancelTransaction(
          transactionId,
          outletId,
          client,
          payload.cancelNote,
          payload.status,
        );
      }
    };

    return [cancelTransactionFunction];
  }

  return mutation;
}

export function useMutationPrintTransaction() {
  const { mode } = useNetworkStatus();
  const client = useApolloClient();

  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';

  const mutation = useMutation<{ printTransaction: { message: string } }>(
    POST_PRINT_TRANSACTION,
  );

  if (!mode) {
    const printTransactionFunction = async function (
      options?: MutationFunctionOptions<any, Record<string, any>>,
    ) {
      if (options?.variables?.transactionId) {
        const transactionId = options?.variables?.transactionId;
        let transaction: any;
        let result: MasterData | string | null = localStorage.getItem(
          'masterData',
        );
        let payments: any[] = [];

        if (result && result !== 'undefined') {
          result = JSON.parse(result) as MasterData;

          if (result) {
            payments = result.payments || [];
          }
        }

        try {
          transaction = client.readFragment({
            id: `Transactions:${transactionId}`,
            fragment: TRANSACTION_FRAGMENT,
          });
        } catch (e) {}

        let transactionPayload;

        if (transaction) {
          const currentPayment = payments.find(
            (payment) =>
              payment.name === transaction.transactionPayment.paymentName,
          );

          transactionPayload = {
            id: transactionId,
            outletId,
            bookingId: transaction.bookingId || '',
            status: transaction.status,
            totalPay: transaction.totalPay,
            payAmount: transaction.payAmount,
            paymentId: currentPayment.id || '',
            stylistId: transaction.stylist ? transaction.stylist.id : '',
            serviceId: transaction.services.map((service: any) => ({
              price: service.price,
              totalPrice: service.price,
              qty: 0,
              timeInMinutes: service.timeInMinutes,
              service: {
                id: service.id,
                name: service.name,
                imagePath: '',
                price: service.price,
                timeInMinutes: service.timeInMinutes,
              },
            })),
            products: transaction.products.map((product: any) => ({
              price: product.price,
              totalPrice: product.price * product.qty,
              qty: product.qty,
              product: {
                id: product.id,
                name: product.name,
                point: product.point,
                imagePath: product.imagePath,
                price: product.price,
              },
            })),
            phoneNumber: transaction.member.phoneNumber || '',
            customerName: transaction.member.name || '',
            email: transaction.member.email || '',
          };
        }

        if (transactionPayload) {
          await printTransaction(transactionPayload);
        }
      }
    };

    return [printTransactionFunction];
  }

  return mutation;
}
