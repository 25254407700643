import styled from 'styled-components';
import { H3, Button } from 'components/element';
import size from 'styles/size';
import color from 'styles/color';

export const Panel = styled.div`
  width: 30%;
  background-color: white;
  border-radius: 5px;
  position: relative;
  min-height: 100px;
  padding: 1rem;

  @media (max-width: 1023px) {
    width: 70%;
  }
`;

export const Title = styled(H3)`
  margin-bottom: 0.5rem;
  text-align: justify;
`;

export const Message = styled.p`
  font-size: ${size.body};
  margin-bottom: 1rem;
  text-align: justify;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const CustomButton = styled(Button)`
  background-color: ${color.white};
  color: ${color.black};
  border: none;
  width: auto;
  min-height: 35px;
  min-width: 100px;
  border-radius: 0;
`;

export const HeaderPadding = styled.div`
  margin-bottom: 24px;
`;
