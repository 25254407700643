import styled from 'styled-components';

export const HaircutContainer = styled.div`
  max-height: 420px;
  overflow: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const PreviousContainer = styled.div`
  border-radius: 8px;
  padding: 16px;
  background-color: #ededed;
  margin-bottom: 16px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const ImagePreviewContainer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
`;

export const ImageContainer = styled.div`
  width: 22%;
  border-radius: 10px;
  text-align: center;
`;

export const ImageRatioContainer = styled.div`
  width: 100%;
  padding-top: 100%;
  border-radius: 10px;
  position: relative;
  background-color: #bcbcbc;
`;

export const PreviousImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 10px;
  height: 100%;
  bottom: 0;
  right: 0;
  object-fit: contain;

  &:hover {
    transform: scale(1.02);
  }
`;

export const LabelText = styled.span`
  font-size: 16px;
`;

export const ValueText = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

export const OutletText = styled.span`
  font-size: 16px;
  margin-bottom: 16px;
`;

export const ModelText = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const Separator = styled.div`
  margin-bottom: 16px;
`;

export const Separator8 = styled.div`
  margin-bottom: 8px;
`;

export const DateText = styled.span`
  font-size: 12px;
`;
