import * as React from 'react';
import styled, { keyframes, css } from 'styled-components';

interface PropsInterface {
  isOpen: boolean;
  block?: boolean;
}

const LoadingView = (props: PropsInterface) => {
  const { isOpen, block = false } = props;

  return !!isOpen ? (
    <LoadingContainer block={block}>
      <LoadingRingContainer>
        <LoadingDiv />
        <LoadingDiv />
        <LoadingDiv />
        <LoadingDiv />
      </LoadingRingContainer>
    </LoadingContainer>
  ) : null;
};

const RingKeyframe = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const LoadingContainer = styled.div<{ block: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0.5, 0.5, 0.5, 0.2);
  ${(props) =>
    props.block &&
    css`
      position: absolute;
    `}
`;
const LoadingDiv = styled.div``;
const LoadingRingContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  top: 40%;
  bottom: 0;
  left: 50%;
  right: 0;
  ${LoadingDiv} {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    border: 6px solid #fff;
    border-radius: 50%;
    animation: ${RingKeyframe} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  ${LoadingDiv}:nth-child (1) {
    animation-delay: -0.45s;
  }
  ${LoadingDiv}:nth-child (2) {
    animation-delay: -0.3s;
  }
  ${LoadingDiv}:nth-child (3) {
    animation-delay: -0.15s;
  }
`;

export default LoadingView;
