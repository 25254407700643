import { gql } from '@apollo/client';

export const TRANSACTION_FRAGMENT = gql`
  fragment transactionFragment on Transactions {
    id
    bookingId
    stylist {
      id
      name
    }
    status
    totalPrice
    totalPay
    payAmount
    createdAt
    updatedAt
    services {
      id
      name
      description
      price
      point
      imagePath
      timeInMinutes
    }
    products {
      id
      code
      name
      description
      qty
      price
      point
    }
    member {
      id
      phoneNumber
      name
      birthDate
      email
      address
      point
      imagePath
    }
    voucher {
      code
    }
    discount
    inputBy {
      name
      email
    }
    updatedBy {
      name
      email
    }
    transactionAt
    transactionPayment {
      paymentName
    }
  }
`;

export const TRANSACTION_DETAIL_FRAGMENT = gql`
  fragment transactionDetailFragment on TransactionDetail {
    id
    bookingId
    outlet {
      id
      name
      phoneNumber
      description
      address
      longitude
      latitude
      cityId
      rate
      rateCount
      outletImages @type(name: "OutletImage") {
        id
        index
        filePath
      }
    }
    # booking {
    #   id
    #   date
    #   outlet {
    #     id
    #     name
    #     phoneNumber
    #     description
    #     address
    #     longitude
    #     latitude
    #     cityId
    #     rate
    #     rateCount
    #     outletImages @type(name: "OutletImage") {
    #       id
    #       index
    #       filePath
    #     }
    #   }
    # }
    stylist {
      # id
      name
      # email
      # phoneNumber
      # rate
      # rateCount
      # imagePath
    }
    startMinute
    timeInMinutes
    isRandomStylist
    type
    status
    services @type(name: "Service") {
      totalPrice
      price
      timeInMinutes
      qty
      service {
        id
        name
        price
        imagePath
        timeInMinutes
      }
    }
    products @type(name: "Product") {
      totalPrice
      price
      timeInMinutes
      qty
      product {
        id
        code
        name
        imagePath
        description
        price
        productDetails @type(name: "ProductDetail") {
          id
          index
          filePath
        }
      }
    }
    transactionPayment {
      # amount
      # paymentFee
      paymentName
      # fee1
      # fee1Type
      # fee22
      # fee2Type
      # fee3
      # fee3Type
    }
    member {
      id
      phoneNumber
      name
      birthDate
      email
      address
      point
      # code
      # verificationToken
      # isVerified
      # verifyAt
      # isEnable
      # memberCardPath
      # createdAt
      # notificationCount
    }
    voucher {
      # id
      code
      # startAt
      # validUntil
      # description
      # discountType
      # discountValue
      # maxDiscountAmount
      # limit
      # category
      # isAvailable
      # createdAt
      # updatedAt
    }
    stylist {
      id
      name
      email
      phoneNumber
      rate
      rateCount
      imagePath
    }
    # review {
    #   rateOutlet
    #   rateStylist
    #   comment
    #   member {
    #     name
    #   }
    #   createdAt
    # }
    # isRandomStylist
    # timeInMinutes
    status
    totalPrice
    discount
    totalPay
    payAmount
    # paymentFee
    # totalStylistCommission
    # adminCommission
    payAmount
    # totalPoint
    inputBy {
      name
      email
    }
    updatedBy {
      name
      email
    }
    pointMutation {
      amount
      # title
      # transactionAt
      # transactionableId
    }
    transactionAt
  }
`;

export const TRANSACTION_HAS_RESULT_FRAGMENT = gql`
  fragment transactionHasResultFragment on TransactionHasResult {
    id
    date
    photoAngles
    sideHaircutModel
    topHaircutModel
    haircut {
      sideback
      top
      hairoil
    }
    stylist {
      id
      name
    }
    outlet {
      name
    }
    hairOil
    services
    files
    totalPay
    startMinute
    timeInMinutes
    isRandomStylist
    type
    status
    transactionAt
  }
`;
