import * as React from 'react';
import styled from 'styled-components';
import Loader from 'react-loaders';
import color from 'styles/color';
import size from 'styles/size';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, Props>(function Button(
  props,
  ref,
) {
  const { isLoading, children, disabled, ...rest } = props;
  return (
    <StyledButton ref={ref} disabled={disabled || isLoading} {...rest}>
      {isLoading ? <Loader active type="ball-pulse" /> : children}
    </StyledButton>
  );
});

const StyledButton = styled.button`
  background-color: ${color.black};
  border: 1px solid ${color.black};
  color: white;
  cursor: pointer;
  line-height: normal;
  font-size: ${size.inputH4};
  font-weight: bold;
  outline: none;
  border-radius: ${size.inputBorder};
  min-height: ${size.inputMinHeight};
  white-space: nowrap;
  text-align: center;
  width: 100%;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
  &:disabled {
    opacity: 0.4;
  }
`;

export default Button;
