import * as React from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import {
  Transaction,
  TransactionSummaryDetail,
  TransactionSummary,
  BookingHistory,
} from './transaction.model';
import { PaginatedApiResult } from 'repositories';
import {
  TRANSACTION_DETAIL_FRAGMENT,
  TRANSACTION_FRAGMENT,
  TRANSACTION_HAS_RESULT_FRAGMENT,
} from './transaction.fragment';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';

const GET_TRANSACTIONS = gql`
  query getTransactions(
    $outletId: String!
    $date: String!
    $statuses: String!
  ) {
    transactions(outletId: $outletId, date: $date, statuses: $statuses)
      @rest(
        type: "TransactionPayload"
        path: "/transactions?outlet_id={args.outletId}&date={args.date}&statuses={args.statuses}"
      ) {
      data @type(name: "Transactions") {
        ...transactionFragment
      }
    }
  }
  ${TRANSACTION_FRAGMENT}
`;

export const GET_TRANSACTIONS_CACHE = gql`
  query getTransactions(
    $outletId: String!
    $date: String!
    $statuses: String!
  ) {
    transactions(outletId: $outletId, date: $date, statuses: $statuses) {
      data @type(name: "Transactions") {
        ...transactionFragment
      }
    }
  }
  ${TRANSACTION_FRAGMENT}
`;

const GET_TRANSACTION = gql`
  query getTransaction($transactionId: String!, $outletId: String!) {
    transaction(transactionId: $transactionId, outletId: $outletId)
      @rest(
        type: "TransactionPayload"
        path: "/transactions/{args.transactionId}?outlet_id={args.outletId}"
      ) {
      data @type(name: "TransactionDetail") {
        ...transactionDetailFragment
      }
    }
  }
  ${TRANSACTION_DETAIL_FRAGMENT}
`;

const GET_TRANSACTION_RESULT = gql`
  query getTransactionResult($memberId: String!, $page: Number!) {
    transactionResult(memberId: $memberId, page: $page)
      @rest(
        type: "TransactionPayload"
        path: "/booking-haircut-histories?member_id={args.memberId}&page={args.page}"
      ) {
      data @type(name: "TransactionHasResult") {
        ...transactionHasResultFragment
      }
      meta @type(name: "PaginationMeta") {
        currentPage
        lastPage
        total
        perPage
      }
    }
  }
  ${TRANSACTION_HAS_RESULT_FRAGMENT}
`;

const GET_TRANSACTION_SUMMARY = gql`
  query getTransactionSummary(
    $outletId: String!
    $filter: String!
    $timeFrom: String
    $timeTo: String
  ) {
    transactionSummary(
      outletId: $outletId
      filter: $filter
      timeFrom: $timeFrom
      timeTo: $timeTo
    )
      @rest(
        type: "TransactionSummaryPayload"
        path: "/summary?outlet_id={args.outletId}&filter={args.filter}&time_from={args.timeFrom}&time_to={args.timeTo}"
      ) {
      date
      month
      year
      totalPay
      totalRefund
      totalDiscount
      totalPrice
      pending
      service
      product
      adminId
      adminEmail
    }
  }
`;

const GET_TRANSACTION_SUMMARY_DETAIL = gql`
  query getTransactionSummaryDetail(
    $outletId: String!
    $adminId: Int!
    $date: String!
    $month: String!
    $year: String!
  ) {
    transactionSummaryDetail(
      outletId: $outletId
      adminId: $adminId
      date: $date
      month: $month
      year: $year
    )
      @rest(
        type: "TransactionSummaryDetailPayload"
        path: "/summary/details?outlet_id={args.outletId}&date={args.date}&month={args.month}&year={args.year}&admin_id={args.adminId}"
      ) {
      outletName
      time
      totalPrice
      totalDiscount
      products
      pending
      services
      payments
      adminId
      adminEmail
    }
  }
`;

export function useQueryGetTransactions(
  outletId: string,
  date: string,
  status: string,
) {
  const queryResult = useLazyQuery<{
    transactions: PaginatedApiResult<Transaction[]>;
  }>(GET_TRANSACTIONS, {
    variables: {
      outletId,
      date,
      statuses: status,
    },
  });

  const { mode } = useNetworkStatus();
  const [, { startPolling, stopPolling }] = queryResult;

  React.useEffect(() => {
    if (mode) {
      startPolling && startPolling(15000);
    }

    return () => {
      stopPolling && stopPolling();
    };
  }, [startPolling, stopPolling, date, outletId, mode]);

  return queryResult;
}

export function useQueryGetTransaction(
  transactionId: string,
  outletId: string,
) {
  return useQuery<{ transaction: { data: Transaction } }>(GET_TRANSACTION, {
    variables: {
      transactionId,
      outletId,
    },
  });
}

export function useQueryGetTransactionResult(memberId: string, page: number) {
  return useQuery<{ transactionResult: { data: BookingHistory[]; meta: any } }>(
    GET_TRANSACTION_RESULT,
    {
      variables: {
        memberId,
        page,
      },
    },
  );
}

export function useQueryGetTransactionSummary(
  outletId: string,
  filter: string,
  timeFrom?: string,
  timeTo?: string,
) {
  const queryResult = useLazyQuery<{
    transactionSummary: TransactionSummary[];
  }>(GET_TRANSACTION_SUMMARY, {
    variables: {
      filter,
      outletId,
      timeFrom,
      timeTo,
    },
  });

  const { mode } = useNetworkStatus();
  const [, { startPolling, stopPolling }] = queryResult;

  React.useEffect(() => {
    if (mode) {
      startPolling && startPolling(15000);
    }
    return () => {
      stopPolling && stopPolling();
    };
  }, [startPolling, stopPolling, filter, outletId, mode, timeFrom, timeTo]);

  return queryResult;
}

export function useQueryGetTransactionSummaryDetail(
  outletId: string,
  adminId: number,
  date: string | undefined,
  month: string | undefined,
  year: string | undefined,
) {
  return useQuery<{ transactionSummaryDetail: TransactionSummaryDetail }>(
    GET_TRANSACTION_SUMMARY_DETAIL,
    {
      variables: {
        date,
        month,
        year,
        outletId,
        adminId,
      },
    },
  );
}
