import * as React from 'react';
import styled from 'styled-components';

export interface SwitchProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  text?: string;
  type: 'switch';
  name: string;
  onAfterChange?: (val: boolean) => void;
}

export default function Switch(props: SwitchProps) {
  const { text, onAfterChange, value, ...rest } = props;
  const ref = React.useRef<HTMLInputElement>(null);

  return (
    <SwitchContainer>
      <SwitchContent>
        <input
          ref={ref}
          {...rest}
          onChange={(event) => {
            rest.onChange?.(event);
            onAfterChange?.(event?.currentTarget.checked);
          }}
          hidden
          type="checkbox"
        />
        <span className="slider" onClick={() => ref.current?.click()} />
      </SwitchContent>
      {text && <span className="text">{text}</span>}
    </SwitchContainer>
  );
}

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  .text {
    padding-left: 8px;
  }
`;

const SwitchContent = styled.div`
  position: relative;
  display: inline-block;
  height: 16px;
  width: 32px;

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 8px;
    width: 8px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  input:disabled + .slider {
    cursor: not-allowed;
  }
`;
