// @ts-nocheck
import * as React from 'react';
import { Detector } from 'react-detect-offline';
import invariant from 'invariant';

interface Props {
  children: React.ReactNode;
  mode: boolean;
}

interface NetworkHandleProps {
  online: boolean;
  setOnline: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}

export interface NetworkStateProps {
  online: boolean;
  mode: boolean;
  setOnline: React.Dispatch<React.SetStateAction<boolean>>;
  setMode: (val: boolean, logout: () => void) => void;
}

export const NetworkContext = React.createContext<NetworkStateProps>({
  online: true,
  mode: true,
  setMode: () => {},
  setOnline: () => {},
});

export default function NetworkStatusContainer(props: Props) {
  const [networkState, setNetworkState] = React.useState(props.mode);
  const [mode, setMode] = React.useState(props.mode);

  // const [syncBooking] = useMutationSyncBooking();
  // const [syncTransaction] = useMutationSyncBooking();

  React.useEffect(() => {
    localStorage.setItem('mode', JSON.stringify(mode));
  }, [mode]);

  const value = React.useMemo<NetworkStateProps>(
    () => ({
      online: networkState,
      setOnline: setNetworkState,
      mode: mode,
      setMode: async (val, logout) => {
        if (val) {
          await logout();
          setMode(val);
        } else {
          const masterData = localStorage.getItem('masterData');

          if (masterData && masterData !== 'undefined') {
            setMode(val);
            await logout();
          } else {
            window.alert('Mode offline sedang tidak bisa!');
          }
        }
      },
    }),
    [mode, networkState],
  );

  return (
    <NetworkContext.Provider value={value}>
      <Detector
        render={({ online }: any) => (
          <NetworkHandle
            children={props.children}
            setOnline={setNetworkState}
            online={online}
          />
        )}
      />
    </NetworkContext.Provider>
  );
}

function NetworkHandle(props: NetworkHandleProps) {
  const { online, setOnline } = props;

  React.useEffect(() => {
    setOnline(online);
  }, [online, setOnline]);

  return <>{props.children}</>;
}

export function useNetworkStatus() {
  const context = React.useContext(NetworkContext);

  invariant(
    context !== undefined,
    'useNetworkStatus must be used inside Credential Container',
  );

  return context;
}
