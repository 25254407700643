import * as React from 'react';
import { Price, PriceContainer, StrikePrice } from './style';
import { string2money } from 'utils/string';

interface Props {
  price: number;
  isPriceList: boolean;
  regularPrice: number;
  isSelected: boolean;
  fontSize?: 'default' | 'small';
}

export default function PriceComponent(props: Props) {
  const {
    price,
    isPriceList,
    regularPrice,
    isSelected,
    fontSize = 'default',
  } = props;
  return (
    <PriceContainer>
      <Price fontSize={fontSize} isSelected={isSelected}>
        Rp. {string2money(price)}
      </Price>
      {isPriceList && (
        <StrikePrice fontSize={fontSize}>
          Rp. {string2money(parseFloat(regularPrice.toString()))}
        </StrikePrice>
      )}
    </PriceContainer>
  );
}
