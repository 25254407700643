import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Panel, Title, Message, ButtonContainer, CustomButton } from './styles';
import DialogBackdrop from './dialogBackdrop';

export interface ConfirmationOption {
  title?: string;
  message: string;
  positiveLabel?: string;
  negativeLabel?: string;
  cancelable: boolean;
  onPositiveAction: (dismiss: VoidFunction) => void;
  onNegativeAction?: (dismiss: VoidFunction) => void;
  noNegative?: boolean;
}

interface Props extends ConfirmationOption {
  onClose: () => void;
}

export default function ConfirmationDialog(props: Props) {
  const { t } = useTranslation();
  const {
    title,
    message,
    positiveLabel = t('common.sure'),
    negativeLabel = t('common.no'),
    cancelable,
    onPositiveAction,
    onNegativeAction,
    noNegative = false,
    onClose,
  } = props;

  const onPositivePress = React.useCallback(() => {
    onPositiveAction(onClose);
  }, [onClose, onPositiveAction]);

  const onNegativePress = React.useCallback(() => {
    if (onNegativeAction) {
      onNegativeAction(onClose);
    } else {
      onClose();
    }
  }, [onClose, onNegativeAction]);

  return (
    <DialogBackdrop onClose={onClose} cancelable={cancelable}>
      <Panel>
        {title && <Title>{title}</Title>}
        <Message>{message}</Message>
        <ButtonContainer>
          <CustomButton onClick={onPositivePress}>{positiveLabel}</CustomButton>
          {!noNegative && (
            <CustomButton onClick={onNegativePress}>
              {negativeLabel}
            </CustomButton>
          )}
        </ButtonContainer>
      </Panel>
    </DialogBackdrop>
  );
}
