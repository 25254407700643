import * as React from 'react';
import {
  Container,
  Title,
  CenterRow,
  LoadingContainer,
  Center,
  StylistSelect,
  ShowHaircutButton,
} from '../style';
import { TableComponent, TextInput } from '../../../components/element';
import { useTranslation } from 'react-i18next';
import { useQueryGetBookings } from 'repositories/booking/booking.query';
import { Booking } from 'repositories/booking/booking.model';
import { convertTime } from 'utils/date';
import { getBookingStatus } from 'helpers/getStatus';
import Status from 'components/Status';
import BookingDetail from './BookingDetail';
import useDialog from 'hooks/useDialog';
import {
  useStateGetCurrentOutlet,
  useQueryGetOutletStylists,
} from 'repositories/outlet';
import LoadingIndicator from 'components/Loading/LoadingIndicator';
import { getSource } from 'helpers/getSource';
import { format, parseISO } from 'date-fns';
import UnprocessedBookingDetail from './unprocessedBookingDetail';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';
import ShowHaircutDialog from 'components/booking/ShowHaircutDialog';
import { useDebounce } from 'use-debounce';

interface Props {
  show: boolean;
  date: any;
  setActiveBadge: (count: number) => void;
}

export default function ActiveBooking(props: Props) {
  const { t } = useTranslation();
  const { show, date, setActiveBadge } = props;
  const dialog = useDialog();
  const { mode } = useNetworkStatus();
  const [selection, setSelection] = React.useState<any[]>([]);
  const [selectedStylist, setSelectedStylist] = React.useState<{
    label: string;
    value: string;
  } | null>(null);

  const [customerFilter, setCustomerFilter] = React.useState<
    string | undefined
  >();

  const [debouncedCustomer] = useDebounce(customerFilter, 1000);

  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';

  const [loadStylists, getStylists] = useQueryGetOutletStylists(outletId, date);

  const [loadBookings, { called, data, loading }] = useQueryGetBookings(
    outletId,
    date,
    '1,2',
    '',
  );

  const [loadTransactionBookings] = useQueryGetBookings(
    outletId,
    date,
    '1,2,3',
    '',
    1,
    1,
  );

  const [loadCompletedBookings] = useQueryGetBookings(
    outletId,
    date,
    '3,9',
    '',
    0,
  );

  React.useEffect(() => {
    if (outletId) {
      loadBookings();
      if (mode) {
        loadTransactionBookings();
        loadCompletedBookings();
      }
    }
  }, [
    loadBookings,
    loadCompletedBookings,
    loadTransactionBookings,
    mode,
    outletId,
  ]);

  React.useEffect(() => {
    if (outletId && !getStylists.called && !called) {
      loadBookings();
      loadStylists();
    }
  }, [called, getStylists.called, loadBookings, loadStylists, outletId]);

  const stylists = React.useMemo(() => {
    if (getStylists.data?.outletStylists.data) {
      return getStylists.data.outletStylists.data.map((stylist: any) => ({
        value: stylist.id,
        label: stylist.name,
      }));
    }
    return [];
  }, [getStylists.data]);

  const activeBookingData = React.useMemo(() => {
    if (selectedStylist || debouncedCustomer) {
      if (data) {
        let options = data.bookings.data;
        if (selectedStylist) {
          options = options.filter(
            (cur) => cur.stylist?.id === selectedStylist.value,
          );
        }

        if (debouncedCustomer) {
          options = options.filter(
            (cur) =>
              `${cur?.member?.name}`
                .toLowerCase()
                .includes(debouncedCustomer.toLowerCase()) ||
              `${cur?.member?.name}`
                .toLowerCase()
                .includes(debouncedCustomer.toLowerCase()),
          );
        }

        return options;
      } else {
        return [];
      }
    } else {
      return data ? data.bookings.data : [];
    }
  }, [data, debouncedCustomer, selectedStylist]);

  React.useEffect(() => {
    if (data) {
      setActiveBadge(data.bookings.data.length);
    }
  }, [data, setActiveBadge]);

  const onShowHaircut = React.useCallback(
    (memberId) => {
      dialog.showCustom({
        render(onClose) {
          return <ShowHaircutDialog memberId={memberId} onClose={onClose} />;
        },
      });
    },
    [dialog],
  );

  const column = React.useMemo(
    () => [
      {
        name: 'number',
        title: t('common.number'),
        getCellValue: (row: Booking) => {
          const index = data ? data.bookings.data.indexOf(row) : 0;
          return index + 1;
        },
      },
      {
        name: 'name',
        title: t('management.name'),
        getCellValue: (row: Booking) => row.member.name,
      },
      {
        name: 'hour',
        title: t('common.hour'),
        getCellValue: (row: Booking) =>
          row.startMinute || typeof row.startMinute === 'number'
            ? convertTime(row.startMinute)
            : '-',
      },
      {
        name: 'serviceTime',
        title: t('management.service_time'),
        getCellValue: (row: Booking) =>
          row.timeInMinutes + ' ' + t('common.minute'),
      },
      {
        name: 'stylist',
        title: t('common.stylist'),
        getCellValue: (row: Booking) => (row.stylist ? row.stylist.name : ''),
      },
      {
        name: 'status',
        title: t('management.status'),
        getCellValue: (row: Booking) => {
          const bookingStatus = getBookingStatus(row.status, t);
          return (
            <Status text={bookingStatus.text} color={bookingStatus.color} />
          );
        },
      },
      {
        name: 'source',
        title: t('management.source'),
        getCellValue: (row: Booking) => {
          const bookingType = getSource(row.type, t);
          return <Status text={bookingType.text} color={bookingType.color} />;
        },
      },
      {
        name: 'transactionStatus',
        title: t('management.transaction_status'),
        getCellValue: (row: Booking) => {
          if (row?.transactionStatus === 1) {
            return t('status.created');
          } else if (row?.transactionStatus === 2) {
            return t('status.paid');
          } else if (row?.transactionStatus === 8) {
            return t('status.refund');
          } else if (row?.transactionStatus === 9) {
            return t('status.cancelled');
          }
        },
      },
      {
        name: 'showHaircut',
        title: t('common.show_haircut'),
        getCellValue: (row: Booking) => {
          return (
            <ShowHaircutButton onClick={() => onShowHaircut(row?.member?.id)}>
              {t('common.show_haircut')}
            </ShowHaircutButton>
          );
        },
      },
      {
        name: 'time',
        title: t('management.input_time'),
        getCellValue: (row: Booking) =>
          format(parseISO(row.createdAt), 'yyyy-MM-dd, HH:mm:ss'),
      },
    ],
    [data, onShowHaircut, t],
  );

  const onSelectionChange = (params: React.ReactText[]) => {
    setSelection([params[params.length - 1]]);
    if (params[params.length - 1] !== undefined) {
      const booking = activeBookingData[params[params.length - 1] as number];
      const bookingId = booking ? booking.id : '';

      if (booking) {
        if (booking.startMinute || booking.startMinute === 0) {
          dialog.showCustom({
            render(onClose) {
              return (
                <BookingDetail
                  bookingData={booking}
                  onClose={() => {
                    onClose();
                    setSelection([]);
                  }}
                  bookingId={bookingId as string}
                  showPending
                  showStatus
                />
              );
            },
          });
        } else {
          dialog.showCustom({
            render(onClose) {
              return (
                <UnprocessedBookingDetail
                  bookingData={booking}
                  date={date}
                  onClose={onClose}
                  bookingId={bookingId || ''}
                />
              );
            },
          });
        }
      }
    }
  };

  const columnWidths = React.useMemo(
    () => [
      { columnName: 'number', width: 120 },
      { columnName: 'name', width: 140 },
      { columnName: 'hour', width: 120 },
      { columnName: 'serviceTime', width: 120 },
      { columnName: 'stylist', width: 150 },
      { columnName: 'status', width: 120 },
      { columnName: 'source', width: 120 },
      { columnName: 'transactionStatus', width: 150 },
      { columnName: 'showHaircut', width: 120 },
      { columnName: 'time', width: 170 },
    ],
    [],
  );

  return (
    <Container show={show}>
      <Center>
        <CenterRow>
          <Title>{t('management.active_booking')}</Title>
          <LoadingContainer>
            <LoadingIndicator isOpen={called && loading} />
          </LoadingContainer>
        </CenterRow>
      </Center>
      <div
        style={{
          display: 'flex',
        }}
      >
        <StylistSelect
          isSearchable={false}
          options={stylists}
          value={selectedStylist}
          onChange={setSelectedStylist}
          className="react-select__container"
          classNamePrefix="react-select"
          placeholder={t('common.stylist')}
          isClearable
        />
        <div style={{ marginLeft: 8 }} />
        <TextInput
          placeholder={t('common.customer')}
          value={customerFilter || ''}
          clearable
          style={{
            width: 200,
            minHeight: 'unset',
            height: 38,
          }}
          onChange={(e) => setCustomerFilter(e.currentTarget.value)}
        />
      </div>
      <TableComponent
        rows={activeBookingData}
        columns={column}
        selection={selection}
        onSelectionChange={onSelectionChange}
        columnWidths={columnWidths}
        enableSelection
      />
    </Container>
  );
}
