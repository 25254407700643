export default {
  jan: 'Januari',
  feb: 'Febuari',
  mar: 'Maret',
  apr: 'April',
  mei: 'Mei',
  jun: 'Juni',
  jul: 'Juli',
  aug: 'Agustus',
  sep: 'September',
  oct: 'Oktober',
  nov: 'November',
  dec: 'December',
}