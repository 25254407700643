import * as React from 'react';
import { P, Button, Div, Select } from '../../../components/element';
import {
  TransactionRow,
  TransactionContent,
  H3Title,
  H4Title,
  Row,
  Separator,
  DetailContainer,
  Space,
  CancelButton,
  MB16,
  PriceContainer,
  ConfirmCancelButton,
  CancelNoteTextArea,
  ButtonPad,
} from '../style';
import { useTranslation } from 'react-i18next';
import { useQueryGetTransaction } from 'repositories/transaction';
import { useStateGetCurrentOutlet } from 'repositories/outlet';
import { string2money } from 'utils/string';
import { pad } from 'utils/date';
import LoadingView from 'components/LoadingView';
import {
  useMutationCancelTransaction,
  useMutationPrintTransaction,
} from 'repositories/transaction/transaction.mutation';
import ReactToPrint from 'react-to-print';
import { useApolloClient } from '@apollo/client';
import TransactionReceipt from 'components/Receipt/TransactionReceipt';
import { authorizeWithTotp } from 'helpers/authorization';
import { toast } from 'react-toastify';
import {
  CloseButton,
  Panel,
} from 'pages/bookingManagement/components/AddBooking';
import DialogBackdrop from 'components/dialog/dialogBackdrop';

interface Props {
  transactionData?: any;
  transactionId: string;
  afterResponse?: () => void;
  onClose: () => void;
}

interface CancelInterface {
  cancelNote: string;
  status?: number;
  isShowing: boolean;
}

const initialCancelState = {
  cancelNote: '',
  isShowing: false,
};

export default function Transaction(props: Props) {
  const { t } = useTranslation();
  // const [paymentMethods, setPaymentMethods] = React.useState([]);
  const receiptRef = React.useRef<any>();
  const apolloClient = useApolloClient();

  const [isLoading, setIsLoading] = React.useState(false);
  const [cancelState, setCancelState] = React.useState<CancelInterface>(
    initialCancelState,
  );

  const { transactionData, transactionId, afterResponse, onClose } = props;

  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';

  const { data, loading } = useQueryGetTransaction(transactionId, outletId);

  const [cancelTransaction] = useMutationCancelTransaction();
  const [printTransaction] = useMutationPrintTransaction();

  // useQueryGetPayments(
  //   React.useCallback((data) => {
  //     if (data && data.payments && data.payments.data) {
  //       setPaymentMethods(
  //         data.payments.data.map((payment: any) => ({
  //           value: payment.id,
  //           label: payment.name,
  //         })),
  //       );
  //     }
  //   }, []),
  // );

  const StatusOptions = React.useMemo(() => {
    return [
      {
        label: t('transaction.refund_status'),
        value: 8,
      },
      {
        label: t('transaction.cancel_status'),
        value: 9,
      },
    ];
  }, [t]);

  let newTransaction;

  if (transactionData) {
    const totalPointService = transactionData.services
      ? transactionData.services.reduce((prev: number, next: any) => {
          return prev + next.point;
        }, 0)
      : 0;

    const totalPointProduct = transactionData.products
      ? transactionData.products.reduce((prev: number, next: any) => {
          return prev + next.point;
        }, 0)
      : 0;

    newTransaction = {
      ...transactionData,
      services: transactionData.services
        ? transactionData.services.map((service: any) => ({
            price: service.price,
            totalPrice: service.price,
            qty: 1,
            timeInMinutes: service.timeInMinutes,
            totalPoint: totalPointService + totalPointProduct,
            service: {
              id: service.id,
              name: service.name,
              imagePath: '',
              price: service.price,
              point: service.point,
              timeInMinutes: service.timeInMinutes,
            },
          }))
        : [],
      products: transactionData.products
        ? transactionData.products.map((product: any) => ({
            price: product.price,
            totalPrice: product.price * product.qty,
            qty: product.qty,
            product: {
              id: product.id,
              name: product.name,
              point: product.point,
              imagePath: '',
              price: product.price,
            },
          }))
        : [],
    };
  }

  const transaction = data
    ? data.transaction?.data
    : newTransaction || undefined;

  const onPressPrintTransaction = React.useCallback(() => {
    printTransaction({
      variables: {
        transactionId: transactionId,
      },
    });
  }, [printTransaction, transactionId]);

  const onClickCancelTransaction = React.useCallback(() => {
    setCancelState((prev: CancelInterface) => ({
      ...prev,
      isShowing: true,
    }));
    return;
  }, []);

  const onConfirmCancel = React.useCallback(async () => {
    if (await authorizeWithTotp()) {
      try {
        setIsLoading(true);
        await cancelTransaction({
          variables: {
            transactionId: transactionId,
            payload: {
              cancel_note: cancelState.cancelNote,
              status: cancelState.status,
            },
          },
        });

        toast.success(t('transaction.cancel_success'));

        afterResponse && afterResponse();
        apolloClient.reFetchObservableQueries();
      } catch (e) {
        (e as any).message && toast.error((e as any).message);
      } finally {
        onClose();
        setIsLoading(false);
      }
    } else {
      toast.error(t('common.wrong_pin'));
    }
  }, [
    afterResponse,
    apolloClient,
    cancelState.cancelNote,
    cancelState.status,
    cancelTransaction,
    onClose,
    t,
    transactionId,
  ]);

  const onCancelNoteChange = React.useCallback((e) => {
    e.persist();
    setCancelState((prev: CancelInterface) => ({
      ...prev,
      cancelNote: e.target.value,
    }));
  }, []);

  const onCancelStatusChange = React.useCallback((e) => {
    setCancelState((prev: CancelInterface) => ({
      ...prev,
      status: e,
    }));
  }, []);

  const buttonContent = React.useMemo(() => {
    if (cancelState.isShowing) {
      return (
        <>
          <H3Title>{t('common.cancel_reason')}</H3Title>
          <CancelNoteTextArea
            placeholder={t('common.write_reason')}
            name="cancelReason"
            onChange={onCancelNoteChange}
            value={cancelState.cancelNote}
          />
          <MB16 />
          <Select
            value={cancelState?.status || null}
            isDisabled={false}
            isLoading={false}
            onBlur={() => {}}
            name="paymentMethod"
            isSearchable={false}
            options={StatusOptions as any}
            onChange={onCancelStatusChange}
            placeholder={t('transaction.status')}
          />
          <ButtonPad />
          <ConfirmCancelButton
            onClick={onConfirmCancel}
            disabled={!cancelState.cancelNote || !cancelState.status}
          >
            {t('common.confirm_cancel')}
          </ConfirmCancelButton>
        </>
      );
    } else {
      return (
        <>
          <ReactToPrint
            trigger={() => (
              <Button isLoading={isLoading}>{t('common.print')}</Button>
            )}
            onBeforeGetContent={async () => {
              if (!(await authorizeWithTotp())) {
                toast.error(t('common.wrong_pin'));
                throw new Error('unauthorized');
              } else {
                onPressPrintTransaction();
              }
            }}
            content={() => receiptRef.current}
          />
          <MB16 />
          <CancelButton
            onClick={onClickCancelTransaction}
            isLoading={isLoading}
          >
            {t('common.cancel_transaction')}
          </CancelButton>
        </>
      );
    }
  }, [
    StatusOptions,
    cancelState,
    isLoading,
    onCancelNoteChange,
    onCancelStatusChange,
    onClickCancelTransaction,
    onConfirmCancel,
    onPressPrintTransaction,
    t,
  ]);

  return (
    <DialogBackdrop onClose={props.onClose} cancelable={false}>
      <Panel>
        {transaction && (
          <>
            <TransactionRow>
              <DetailContainer>
                <H4Title>{t('transaction.booking_id')}</H4Title>
                <P>{transaction.bookingId ? transaction.bookingId : '-'}</P>
              </DetailContainer>
              <DetailContainer>
                <H4Title>{t('common.stylist')}</H4Title>
                <P>{transaction.stylist ? transaction.stylist.name : '-'}</P>
              </DetailContainer>
            </TransactionRow>
            <TransactionRow>
              <DetailContainer>
                <H4Title>{t('common.name')}</H4Title>
                <P>{transaction.member.name}</P>
              </DetailContainer>
              <DetailContainer>
                <H4Title>{t('common.phone_number')}</H4Title>
                <P>{transaction.member.phoneNumber || '-'}</P>
              </DetailContainer>
            </TransactionRow>
            <TransactionRow>
              <DetailContainer>
                <H4Title>{t('common.payment_method')}</H4Title>
                <P>{transaction.transactionPayment.paymentName || '-'}</P>
              </DetailContainer>
              <DetailContainer>
                <H4Title>{t('common.voucher_code')}</H4Title>
                <P>{transaction.voucher ? transaction.voucher.code : '-'}</P>
              </DetailContainer>
            </TransactionRow>
            <Separator />
            <TransactionContent>
              <H3Title>{t('common.service')}</H3Title>
              {transaction.services &&
                transaction.services.map((service: any) => {
                  return (
                    <Space>
                      <P>{service.name || service.service.name}</P>
                      <P>{`Rp ${string2money(service.totalPrice)}`}</P>
                    </Space>
                  );
                })}
              {transaction.products && !!transaction.products && (
                <H3Title>{t('common.product')}</H3Title>
              )}
              {transaction.products &&
                transaction.products.map((product: any) => {
                  return (
                    <Space>
                      <Div>
                        <P>{product.name || product.product.name}</P>
                        <P>{`Rp ${string2money(product.totalPrice)}`}</P>
                      </Div>
                      <P>{`${pad(product.qty)} qty`}</P>
                    </Space>
                  );
                })}
              <Separator />
              <PriceContainer>
                <Row>
                  <H4Title>{t('transaction.sub_total')}</H4Title>
                  <P>{`Rp ${string2money(transaction.totalPrice)}`}</P>
                </Row>
                <Row>
                  <H4Title>{t('transaction.discount')}</H4Title>
                  <P>{`Rp ${string2money(transaction.discount)}`}</P>
                </Row>
                <Row>
                  <H4Title>{t('transaction.total_pay')}</H4Title>
                  <P>{`Rp ${string2money(transaction.totalPay)}`}</P>
                </Row>
                <Row>
                  <H4Title>
                    {transaction.transactionPayment.paymentName}
                  </H4Title>
                  <P>{`Rp ${string2money(transaction.payAmount)}`}</P>
                </Row>
                <Row>
                  <H4Title>{t('transaction.change_amount')}</H4Title>
                  <P>{`Rp ${string2money(
                    Number(transaction.payAmount) -
                      Number(transaction.totalPay),
                  )}`}</P>
                </Row>
              </PriceContainer>
              {transaction.status !== 9 && <>{buttonContent}</>}
            </TransactionContent>
          </>
        )}
        <div style={{ display: 'none' }}>
          <TransactionReceipt
            transaction={transaction}
            ref={receiptRef}
            showPoint={false}
          />
        </div>
        <LoadingView isOpen={loading} block />
        <CloseButton onClick={onClose}>x</CloseButton>
      </Panel>
    </DialogBackdrop>
  );
}
