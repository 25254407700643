import React from 'react';
// @ts-ignore
import { Ellipsis } from 'react-awesome-spinners';
import { Div } from '../components/element';
import colors from '../styles/color';

interface Props {
  isLoading?: boolean;
  size?: number;
  color?: string;
}

export default function LoadingSpinner(props: Props) {
  const { isLoading = true, size, color = colors.black, ...rest } = props;
  if (isLoading) {
    return (
      <Div {...rest}>
        <Ellipsis size={size} color={color} />
      </Div>
    );
  }
  return null;
}
