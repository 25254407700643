import * as React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Payment } from './payment.model';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';
import { MasterData } from 'repositories/common/common.model';

const GET_PAYMENTS = gql`
  query getPayments {
    payments @rest(type: "PaymentPayload", path: "/payments") {
      data @type(name: "Payment") {
        id
        name
        fee1
        fee1Type
        fee2
        fee2Type
        fee3
        fee3Type
      }
    }
  }
`;

function GetOfflinePayment(
  onCompleted?: (data: PaymentResultData) => void,
): any {
  let payments: any[] = [];
  React.useEffect(() => {
    let result: MasterData | string | null = localStorage.getItem('masterData');

    if (result && result !== 'undefined') {
      result = JSON.parse(result) as MasterData;

      if (result) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        payments = result.payments;

        onCompleted &&
          onCompleted({
            payments: {
              data: payments || [],
            },
          });
      }
    }
  }, [onCompleted]);

  return [
    () => {},
    {
      data: {
        payments: {
          data: payments,
        },
      },
      loading: false,
      called: false,
    },
  ];
}

type PaymentResultData = { payments: { data: Payment[] } };
export function useQueryGetPayments(
  onCompleted?: (data: PaymentResultData) => void,
) {
  const { mode } = useNetworkStatus();

  const queryResult = useQuery<PaymentResultData>(GET_PAYMENTS, {
    onCompleted: mode ? onCompleted : undefined,
  });

  return mode ? queryResult : GetOfflinePayment(onCompleted);
}
