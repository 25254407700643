import * as React from 'react';
import ReceiptLayout from './ReceiptLayout';
import { useTranslation } from 'react-i18next';
import { Transaction } from 'repositories/transaction';
import { DividerRow, FootNote, Subtitle } from './styles';
import { format } from 'utils/date';
import { string2money } from 'utils/string';
import styled from 'styled-components';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';
// import AppUrlQRImage from 'assets/images/png/old-sirsalon-app-url.png';

interface Props {
  transaction?: Transaction;
  showPoint?: boolean;
}

const TransactionReceipt = React.forwardRef((props: Props, ref) => {
  const { t } = useTranslation();
  const { mode } = useNetworkStatus();

  const { transaction, showPoint = true } = props;
  if (!transaction) {
    return null;
  }

  return (
    <ReceiptLayout ref={ref}>
      <Subtitle>{transaction.id}</Subtitle>
      <Subtitle>{format(transaction.transactionAt)}</Subtitle>
      <table style={{ maxWidth: '100%' }}>
        <tbody>
          {!!transaction.inputBy.name && (
            <tr>
              <ShrinkedTD>{t('common.cashier')}</ShrinkedTD>
              <ShrinkedTD>{`${mode ? '' : '-'} ${
                transaction.inputBy.name
              }`}</ShrinkedTD>
            </tr>
          )}
          {!!transaction.stylist && (
            <tr>
              <ShrinkedTD>{t('common.stylist')}</ShrinkedTD>
              <ShrinkedTD>{transaction.stylist.name}</ShrinkedTD>
            </tr>
          )}
          {!!transaction.voucher && (
            <tr>
              <ShrinkedTD>{t('transaction.voucher')}</ShrinkedTD>
              <ShrinkedTD>{transaction.voucher.code}</ShrinkedTD>
            </tr>
          )}
          <DividerRow />
          {transaction.services &&
            transaction.services.map((service) => (
              <tr key={service.service.id}>
                <ShrinkedTD>{service.service.name}</ShrinkedTD>
                <ShrinkedTD>{`Rp ${string2money(
                  service.totalPrice,
                )}`}</ShrinkedTD>
              </tr>
            ))}

          {transaction.products &&
            transaction.products.map((product) => (
              <tr key={product.product.id}>
                <ShrinkedTD>{`(${product.qty || 1}) ${
                  product.product.name
                }`}</ShrinkedTD>
                <ShrinkedTD>{`Rp ${string2money(
                  product.totalPrice,
                )}`}</ShrinkedTD>
              </tr>
            ))}
          <DividerRow />
          <tr>
            <ShrinkedTD>{t('transaction.sub_total')}</ShrinkedTD>
            <ShrinkedTD>{`Rp ${string2money(
              transaction.totalPrice,
            )}`}</ShrinkedTD>
          </tr>
          <tr>
            <ShrinkedTD>{t('transaction.discount')}</ShrinkedTD>
            <ShrinkedTD>{`Rp ${string2money(
              transaction.discount || 0,
            )}`}</ShrinkedTD>
          </tr>
          <tr>
            <ShrinkedTD>{t('transaction.total_pay')}</ShrinkedTD>
            <ShrinkedTD>{`Rp ${string2money(
              transaction.totalPay,
            )}`}</ShrinkedTD>
          </tr>
          <tr>
            <ShrinkedTD>
              {transaction.transactionPayment.paymentName}
            </ShrinkedTD>
            <ShrinkedTD>{`Rp ${string2money(
              transaction.payAmount,
            )}`}</ShrinkedTD>
          </tr>
          <tr>
            <ShrinkedTD>{t('transaction.change_amount')}</ShrinkedTD>
            <ShrinkedTD>{`Rp ${string2money(
              Number(transaction.payAmount) - Number(transaction.totalPay),
            )}`}</ShrinkedTD>
          </tr>
        </tbody>
      </table>
      {transaction.member?.name && (
        <Subtitle>{transaction.member?.name}</Subtitle>
      )}
      <Subtitle>{transaction.member.phoneNumber}</Subtitle>
      {showPoint ? (
        <>
          {mode ? (
            <>
              <Subtitle>
                {t('common.previous_point', {
                  point: string2money(
                    (transaction.member.point || 0) -
                      (transaction.pointMutation?.amount || 0),
                  ),
                })}
              </Subtitle>
              <Subtitle>
                {t('common.point', {
                  point: string2money(transaction.member.point || 0),
                })}
              </Subtitle>
            </>
          ) : (
            <Subtitle>
              {t('common.point_gained', {
                point: string2money(transaction.totalPoint || 0),
              })}
            </Subtitle>
          )}
        </>
      ) : (
        <Subtitle>--Copy--</Subtitle>
      )}
      <br />
      {/* <DivCenter>
        <AppUrlQR alt="app-url" src={AppUrlQRImage} />
      </DivCenter> */}

      <FootNote
        dangerouslySetInnerHTML={{ __html: t('transaction.receipt_footnote') }}
      />
    </ReceiptLayout>
  );
});

const ShrinkedTD = styled.td`
  word-break: break-word;
`;

export default TransactionReceipt;
