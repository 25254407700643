import * as React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import { onError } from '@apollo/link-error';
import { setContext } from 'apollo-link-context';
import { persistCache } from 'apollo3-cache-persist';
import { camelize } from 'humps';
import ky from 'ky';
import localforage from 'localforage';
import { toApiError, ApiError } from 'utils/error';
import { toast } from 'react-toastify';
import { outletLocalResolvers } from 'repositories/outlet/outlet.mutation';
import { authLocalResolvers } from 'repositories/auth/auth.mutation';
import { getAuthenticationToken } from 'utils/storage';
import {
  logout,
  useIsAuthenticated,
  useStateGetLoginToken,
} from 'repositories/auth';
import {
  GET_CURRENT_OUTLET,
  Outlet,
  useStateGetCurrentOutlet,
  useQueryGetAdminOutlets,
} from 'repositories/outlet';
import { GET_LOGIN_TOKEN } from 'repositories/auth';
import { useNetworkStatus } from './NetworkStatusContainer';

interface Props {
  children: React.ReactNode;
}

export const ReselectApolloStateContext = React.createContext<{
  currentOutlet?: Outlet;
}>({});

const initialState = {
  loggedInOutletId: null,
};

const initialTokenState = {
  loginToken: null,
};

export default function ApolloAppProvider(props: Props) {
  const { children } = props;
  const [cache, setCache] = React.useState<InMemoryCache | undefined>(
    undefined,
  );
  const [errorCount, setErrorCount] = React.useState({
    failedToFetch: 0,
    unauthenticated: 0,
  });

  const { mode } = useNetworkStatus();

  React.useEffect(() => {
    async function rehydrate() {
      try {
        const _cache = new InMemoryCache();

        _cache.writeQuery({
          query: GET_CURRENT_OUTLET,
          data: initialState,
        });

        _cache.writeQuery({
          query: GET_LOGIN_TOKEN,
          data: initialTokenState,
        });

        setCache(_cache);
        await persistCache({
          cache: _cache,
          storage: localforage as any,
        });
      } catch (error) {
        console.error(error);
      }
    }
    rehydrate();
  }, []);

  React.useEffect(() => {
    if (errorCount.failedToFetch > 0 || errorCount.unauthenticated > 0) {
      setTimeout(
        () => setErrorCount({ failedToFetch: 0, unauthenticated: 0 }),
        1200,
      );
    }
  }, [errorCount]);

  const client = React.useMemo(() => {
    if (!cache) {
      return undefined;
    }

    const authMiddleware = setContext(async (operation, { headers }) => {
      const token = await getAuthenticationToken();
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    const restLink = new RestLink({
      uri: process.env.REACT_APP_API_URI + '/api/pos',
      headers: {
        'Accept-Language': 'id',
      },
      fieldNameNormalizer: (key: string) => camelize(key),
      customFetch: (uri, options) =>
        new Promise(async (resolve, reject) => {
          try {
            resolve(await ky(uri, { ...options, timeout: 60000 }));
          } catch (e) {
            const err = e as any;
            const error = await toApiError(err);
            if (error.message === 'Failed to fetch') {
              setErrorCount((prev) => {
                if (prev.failedToFetch < 1) {
                  reject(error);
                }
                return { ...prev, failedToFetch: prev.failedToFetch + 1 };
              });
            }
            if (error.code === 401) {
              setErrorCount((prev) => {
                if (prev.unauthenticated < 1) {
                  reject(error);
                }
                return { ...prev, unauthenticated: prev.unauthenticated + 1 };
              });
            }
            if (error.code !== 401 && error.message !== 'Failed to fetch') {
              reject(error);
            }
          }
        }),
    });

    const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
      if (networkError) {
        if (networkError.message !== 'error.timeout') {
          toast.error(networkError.message);
        }

        (window as any).a = networkError;

        if (
          !!client &&
          networkError instanceof ApiError &&
          networkError.code === 401
        ) {
          logout(client, mode);
        }
      }
    });

    return new ApolloClient({
      link: ApolloLink.from(
        [errorLink as any, authMiddleware].concat(mode ? restLink : []),
      ),
      cache,
      resolvers: {
        Mutation: {
          ...outletLocalResolvers,
          ...authLocalResolvers,
        },
      },
      defaultOptions: {
        watchQuery: {
          fetchPolicy: mode ? 'cache-and-network' : 'cache-only',
        },
      },
    });
  }, [cache, mode]);

  React.useEffect(() => {
    client?.onClearStore(async () => {
      await client.cache.writeQuery({
        query: GET_CURRENT_OUTLET,
        data: initialState,
      });
      await client.cache.writeQuery({
        query: GET_LOGIN_TOKEN,
        data: initialTokenState,
      });
    });
  }, [cache, client]);

  return !!client ? (
    <ApolloProvider client={client}>
      <ReselectContainer>{children}</ReselectContainer>
    </ApolloProvider>
  ) : null;
}

function ReselectContainer(props: { children: React.ReactNode }) {
  const { children } = props;
  const { data } = useStateGetLoginToken();
  const rawIsAuthenticated = useIsAuthenticated();
  const { data: currentOutletId } = useStateGetCurrentOutlet();
  const [loadOutlets, { data: outlets, called }] = useQueryGetAdminOutlets();
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(
    null,
  );

  React.useEffect(() => {
    (async () => {
      const prevToken = await getAuthenticationToken();
      if (!rawIsAuthenticated && data?.loginToken === null && !!prevToken) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(rawIsAuthenticated);
      }
    })();
  }, [data, rawIsAuthenticated]);

  React.useEffect(() => {
    if (isAuthenticated && !called) {
      (async () => {
        const token = await getAuthenticationToken();
        loadOutlets({
          context: {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        });
      })();
    }
  }, [called, isAuthenticated, loadOutlets]);

  const currentOutlet = React.useMemo(
    () =>
      outlets?.outlets.data.find(
        (o) => o.id === currentOutletId?.loggedInOutletId,
      ),
    [currentOutletId?.loggedInOutletId, outlets],
  );

  return (
    <ReselectApolloStateContext.Provider value={{ currentOutlet }}>
      {children}
    </ReselectApolloStateContext.Provider>
  );
}
