export default {
  online: 'Online',
  offline: 'Offline',
  reserve: 'Reserve',
  stylist: 'Stylist',
  walk_in: 'Walk-in',
  scheduled: 'Terjadwal',
  started: 'Dimulai',
  completed: 'Selesai',
  created: 'Dibuat',
  refund: 'Direfund',
  paid: 'Dibayar',
  cancelled: 'Dibatalkan',
  new_customer: 'Pelanggan Baru',
  voucher_available: 'Kode Promo Tersedia',
  voucher_not_available: 'Kode Promo Tidak Tersedia',
  choose_booking_first: 'Pilih pesanan terlebih dahulu',
  choose_unprocessed_booking_first: 'Pilih pesanan yang belum diproses',
};
