import * as React from 'react';
import styled from 'styled-components';
import size from 'styles/size';
import color from 'styles/color';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  errorText?: string | boolean;
  noMargin?: boolean;
}

const FormField = React.forwardRef<HTMLDivElement, Props>(function FormField(
  props,
  ref,
) {
  const { children, errorText, noMargin = false, ...rest } = props;
  return (
    <Container ref={ref} {...rest} noMargin={noMargin}>
      <Row>{children}</Row>
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </Container>
  );
});

const Container = styled.div<{ noMargin: boolean }>`
  width: 100%;
  margin: ${(props) =>
    props.noMargin ? 0 : `0px 0px ${size.formFieldSpace} 0px`};
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ErrorText = styled.label`
  color: ${color.error};
  font-size: 0.8rem;
  margin-top: 0.25rem;
`;

export default FormField;
