export interface Payment {
  id: string;
  name: string;
  feel: number;
  feelType: number;
  fee2: number;
  fee2Type: number;
  fee3: number;
  fee3Type: number;
  isAvailable: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum PaymentPointEnum {
  PAYMENT_TYPE_POINT = 'POINT',
  PAYMENT_TYPE_POINT_LAMA = 'POINT LAMA',
}
