const colors = {
  black: '#000000',
  white: '#FFFFFF',
  red: '#FF2C2C',
  lightGrey: '#BFC5CB',
  neutral11: '#808080',
  neutralSpindle: '#BFC5CB',
  charcoal4: '#494949',
  fern1: '#5CB85C',
  cornflowerBlue1: '#4A90E2',
  orange1: '#FBAA03',
  regalBlue1: '#273D52',
  redOrange1: '#FF2C2C',
  gainsboro7: '#D8D8D8',
};

export default {
  ...colors,
  accent: colors.black,
  placeholder: colors.lightGrey,
  border: colors.lightGrey,
  error: colors.red,
};
