import * as React from 'react';
import styled from 'styled-components';
import { P } from './element';
import color from 'styles/color';
import { pad } from 'utils/date';
import { ProductOption } from './transaction/OutletProductsInput';
import { ServiceOption } from './transaction/OutletServiceInput';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';
import PriceComponent from './PriceComponent';

// TODO: after refactor booking to use new SelectDetail
// rename this to SelectDetail and remove the old one
export function NewSelectDetail(option: ProductOption | ServiceOption) {
  const { extra } = option;
  return (
    <SelectDetail
      label={extra.name}
      image={extra.imagePath}
      price={extra.price}
      time={'timeInMinutes' in extra ? extra.timeInMinutes : undefined}
      isPriceList={extra.isPriceList}
      regularPrice={extra.regularPrice}
    />
  );
}

export default function SelectDetail({
  label,
  image,
  price,
  time,
  isPriceList,
  regularPrice,
}: any) {
  const { mode } = useNetworkStatus();

  return (
    <Container>
      {mode && (
        <ImageContainer>
          <Image src={image} alt="" />
        </ImageContainer>
      )}
      <TextContainer>
        <Space>
          <Text>{label}</Text>
          {time && (
            <Time>{`${pad(Math.floor(time / 60))} : ${pad(time % 60)}`}</Time>
          )}
        </Space>
        <PriceComponent
          {...{ price, isPriceList, regularPrice, isSelected: true }}
        />
      </TextContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  height: auto;
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 5px;
`;

const Text = styled(P)``;

const Time = styled(P)`
  color: ${color.neutralSpindle};
  font-size: 14px;
`;

const ImageContainer = styled.div`
  width: 60px;
  display: flex;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  width: 100%;
  justify-content: space-between;
`;

const Space = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
