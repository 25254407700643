import localforage from 'localforage';

const AuthTokenKey = 'token';
const OutletALiasKey = 'outlet_alias';

export async function setAuthenticationToken(token: string | undefined) {
  await localforage.setItem(AuthTokenKey, token);
}

export function getAuthenticationToken() {
  return localforage.getItem(AuthTokenKey);
}

export async function setOutletALiasKey(alias: string | undefined) {
  await localforage.setItem(OutletALiasKey, alias);
}

export async function getOutletAliasKey() {
  return localforage.getItem<string | undefined>(OutletALiasKey);
}
