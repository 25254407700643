import * as React from 'react';
import { H2 } from '../../../components/element';
import {
  Status,
  StatusText,
  NameText,
  HeaderContainer,
  StatusContainer,
  StyledReactSelect,
} from '../style';
import { useTranslation } from 'react-i18next';
//@ts-ignore
import { useQueryGetMe } from 'repositories/auth';
import {
  useStateGetCurrentOutlet,
  useQueryGetAdminOutlets,
} from 'repositories/outlet';
import {
  useStateSetCurrentOutlet,
  useStateSetRememberedOutlet,
} from 'repositories/outlet/outlet.mutation';
import { getAuthenticationToken, setOutletALiasKey } from 'utils/storage';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';

interface Props {
  title: string;
}

export default function Header(props: Props) {
  const { t } = useTranslation();
  const { title } = props;
  const { mode } = useNetworkStatus();

  const [loadOutlets, getOutletsQuery] = useQueryGetAdminOutlets();
  const { data } = useQueryGetMe();
  const [setCurrentOutlet] = useStateSetCurrentOutlet();
  const [setRememberedOutlet] = useStateSetRememberedOutlet();

  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';

  React.useEffect(() => {
    if (!getOutletsQuery.called) {
      (async () => {
        const token = await getAuthenticationToken();

        loadOutlets({context: {
          headers: {
            authorization: `Bearer ${token}`
          }
        }});
      })();
    }
  }, [getOutletsQuery.called, loadOutlets]);

  React.useEffect(() => {
    if (
      !outletId &&
      getOutletsQuery.data?.outlets.data &&
      getOutletsQuery.data?.outlets.data.length
    ) {
      (async function exec() {
        setCurrentOutlet({
          variables: {
            id: getOutletsQuery.data?.outlets.data[0].id,
          },
        });
        await setOutletALiasKey(
          getOutletsQuery.data?.outlets.data[0].outletAlias,
        );
      })();
    }
  }, [getOutletsQuery.data, outletId, setCurrentOutlet]);

  const outlets = React.useMemo(() => {
    if (getOutletsQuery.data?.outlets.data) {
      return getOutletsQuery.data.outlets.data.map((outlet) => ({
        value: outlet.id,
        label: outlet.name,
        outletAlias: outlet.outletAlias,
      }));
    }
    return [];
  }, [getOutletsQuery.data]);

  const currentOutlet = React.useMemo(() => {
    if (outletId && outlets.length) {
      let currentOption = outlets.find((outlet) => outlet.value === outletId);

      if (currentOption) {
        return currentOption;
      }
    }

    return null;
  }, [outletId, outlets]);

  const onOutletChange = React.useCallback(
    async (value: { label: string; value: string; outletAlias: string }) => {
      await setCurrentOutlet({
        variables: { id: value.value },
      });
      await setRememberedOutlet({
        variables: { id: value.value },
      });
      await setOutletALiasKey(value.outletAlias);
    },
    [setCurrentOutlet, setRememberedOutlet],
  );

  return (
    <HeaderContainer>
      <H2>{title}</H2>
      <StyledReactSelect
        className="react-select__container"
        classNamePrefix="react-select"
        name="outlet"
        value={currentOutlet}
        options={outlets}
        //@ts-expect-error
        onChange={onOutletChange}
      />
      <StatusContainer>
        <Status online={mode} />
        <StatusText>
          {mode ? t('common.online') : t('common.offline')}
        </StatusText>
        <NameText>{data && data.user && data.user.data.email}</NameText>
      </StatusContainer>
    </HeaderContainer>
  );
}
