import * as React from 'react';
import {
  Body,
  Title,
  LeftContainer,
  CenterContainer,
  RightContainer,
  BookingDetailRow,
  StylistName,
  BookingName,
  Space,
  OperationalButton,
  NotOperationalButton,
  CenterRow,
  LoadingContainer,
  TableButtonContainer,
  OutlineButton,
  MR8,
  MB35,
  DetailRow,
  BoldText,
  TimeText,
  UpperContainer,
  ShowHaircutButton,
} from '../style';
import { TableComponent, H2 } from '../../../components/element';
import { useTranslation } from 'react-i18next';
import {
  useQueryGetBookingSheets,
  useQueryGetBookingUnset,
} from 'repositories/booking/booking.query';
import { useMutationSetBookingSheet } from 'repositories/booking/booking.mutation';
import { BookingSheet, Booking } from 'repositories/booking/booking.model';
import Status from '../../../components/Status';
import useDialog from '../../../hooks/useDialog';
import { convertTime, pad } from 'utils/date';
import { getBookingStatus } from 'helpers/getStatus';
import BookingDetail from './BookingDetail';
import UnprocessedBookingDetail from './unprocessedBookingDetail';
import LoadingView from 'components/LoadingView';
import { useStateGetCurrentOutlet } from 'repositories/outlet';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import LoadingIndicator from '../../../components/Loading/LoadingIndicator';
import { getSource } from 'helpers/getSource';
import { useApolloClient } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import { useQueryGetTransactions } from 'repositories/transaction/transaction.query';
import ShowHaircutDialog from 'components/booking/ShowHaircutDialog';

interface Props {
  show: boolean;
  date: string;
}

export default function Management(props: Props) {
  const { t } = useTranslation();
  const apolloClient = useApolloClient();
  const dialog = useDialog();
  const [isLoading, setIsLoading] = React.useState(false);
  const [selection, setSelection] = React.useState<any[]>([]);
  const [unProcessedSelection, setUnprocessedSelection] = React.useState<any[]>(
    [],
  );
  const [bookingSelected, setBookingSelected] = React.useState('');
  const [buttonActive, setButtonActive] = React.useState(false);
  const [isOperational, setIsOperational] = React.useState(true);
  const [expandedRowIds, setExpandedRowIds] = React.useState<React.ReactText[]>(
    [],
  );

  const { show, date } = props;

  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';
  const tableRef = React.createRef<any>();

  const [setBookingSheet] = useMutationSetBookingSheet();
  const [
    loadBookingSheets,
    { called, loading, data },
  ] = useQueryGetBookingSheets(outletId, date);

  const [loadCompletedTranscations] = useQueryGetTransactions(
    outletId,
    format(new Date(), 'yyyy-MM-dd'),
    '2',
  );

  const [loadCancelledTranscations] = useQueryGetTransactions(
    outletId,
    format(new Date(), 'yyyy-MM-dd'),
    '8,9',
  );

  const [
    loadBookingUnset,
    {
      called: unprocessedCalled,
      loading: unprocessedLoading,
      data: unprocessedData,
    },
  ] = useQueryGetBookingUnset(outletId, date);

  React.useEffect(() => {
    if (outletId && !called && !unprocessedCalled) {
      loadBookingSheets();
      loadBookingUnset();
    }
  }, [
    called,
    loadBookingSheets,
    loadBookingUnset,
    outletId,
    unprocessedCalled,
  ]);

  React.useEffect(() => {
    if (outletId) {
      loadCompletedTranscations();
      loadCancelledTranscations();
    }
  }, [loadCancelledTranscations, loadCompletedTranscations, outletId]);

  const onSelectionChange = (params: React.ReactText[]) => {
    setSelection([params[params.length - 1]]);
  };

  const onUnprocessedSelectionChange = (params: React.ReactText[]) => {
    setUnprocessedSelection([params[params.length - 1]]);
    setBookingSelected('');
    if (params[params.length - 1] !== undefined) {
      const booking =
        unprocessedData?.bookingUnset.data[params[params.length - 1] as number];

      if (booking) {
        dialog.showCustom({
          render(onClose) {
            return (
              <UnprocessedBookingDetail
                bookingData={booking}
                date={date}
                onClose={() => {
                  onClose();
                  setUnprocessedSelection([]);
                }}
                bookingId={booking!.id || ''}
              />
            );
          },
        });
      }
    }
  };

  const OngoingColumn = React.useMemo(
    () => [
      {
        name: 'hour',
        title: t('common.hour'),
        getCellValue: (row: BookingSheet) => {
          return (
            <BoldText>
              {`${convertTime(row.startMinute)} - ${convertTime(
                row.endMinute,
              )}`}
            </BoldText>
          );
        },
      },
      {
        name: 'booking',
        title: t('management.booking'),
        getCellValue: (row: BookingSheet) => {
          return (
            <BoldText>
              {`${row.bookings.length} ${t('management.booking')}`}
            </BoldText>
          );
        },
      },
      { name: 'status', title: t('management.status') },
      { name: 'source', title: t('management.source') },
    ],
    [t],
  );

  const NotProcessedColumn = React.useMemo(
    () => [
      {
        name: 'name',
        title: t('management.name'),
        getCellValue: (row: Booking) => (row.member ? row.member.name : ''),
      },
      {
        name: 'status',
        title: t('management.status'),
        getCellValue: (row: Booking) => {
          const bookingStatus = getBookingStatus(row.status, t);
          return (
            <Status
              size={84}
              fontSize={12}
              text={bookingStatus.text}
              color={bookingStatus.color}
            />
          );
        },
      },
      {
        name: 'source',
        title: t('management.source'),
        getCellValue: (row: Booking) => {
          const bookingType = getSource(row.type, t);
          return (
            <Status
              size={84}
              fontSize={12}
              text={bookingType.text}
              color={bookingType.color}
            />
          );
        },
      },
      {
        name: 'time',
        title: t('management.input_time'),
        getCellValue: (row: Booking) =>
          format(parseISO(row.createdAt), 'yyyy-MM-dd, HH:mm:ss'),
      },
    ],
    [t],
  );

  const BookingSheetData = React.useMemo(() => {
    if (isOperational) {
      if (data) {
        if (data.bookingSheets.data) {
          return data.bookingSheets.data.filter(
            (data) => data.isOperationalHour,
          );
        } else {
          return [];
        }
      }
    } else {
      return data ? data.bookingSheets.data : [];
    }
  }, [data, isOperational]);

  // Scroll to nearest time
  React.useEffect(() => {
    if (BookingSheetData) {
      let id = 0;
      const date = new Date();
      const currentMinute = date.getHours() * 60 + date.getMinutes();

      for (let i = 0; i < BookingSheetData.length; i++) {
        if (
          currentMinute >= BookingSheetData[i].startMinute &&
          currentMinute <= BookingSheetData[i].endMinute
        ) {
          id = i;
          break;
        }
      }

      tableRef.current &&
        tableRef.current.scrollToRow &&
        tableRef.current.scrollToRow(id - 3 < 0 ? id : id - 3);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!unProcessedSelection[0]) {
      setSelection([]);
      setButtonActive(false);
    }
  }, [unProcessedSelection]);

  React.useEffect(() => {
    if (selection.length && selection[0] !== undefined) {
      dialog.showConfirmation({
        title: t('common.transaction'),
        message: t('common.confirmation_change_text'),
        cancelable: false,
        onPositiveAction: async (dismiss) => {
          const booking =
            unprocessedData &&
            unprocessedData.bookingUnset.data[unProcessedSelection[0]];
          const sheet = data && data.bookingSheets.data[selection[0]];

          dismiss();
          try {
            setIsLoading(true);
            await setBookingSheet({
              variables: {
                payload: {
                  booking_id: booking?.id,
                  start_minute: sheet?.minute,
                },
              },
            });

            apolloClient.reFetchObservableQueries();
          } catch (e) {
          } finally {
            setIsLoading(false);
          }
        },
      });
    }
  }, [dialog, selection]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickRowDetail = React.useCallback(
    (booking: any) => {
      if (booking.id === bookingSelected) {
        setBookingSelected('');
      } else {
        setBookingSelected(booking.id);
        setUnprocessedSelection([]);
        dialog.showCustom({
          render(onClose) {
            return (
              <BookingDetail
                bookingData={booking}
                onClose={() => {
                  onClose();
                  setBookingSelected('');
                }}
                bookingId={booking.id}
                showPending
                showStatus
              />
            );
          },
        });
      }
    },
    [bookingSelected, dialog],
  );

  const onClickCollapse = React.useCallback(() => {
    setExpandedRowIds([]);
  }, []);

  const onClickExpand = React.useCallback(() => {
    const arr: any[] = [];
    if (BookingSheetData) {
      for (let i = 0; i < BookingSheetData.length; i++) {
        arr.push(i);
      }
    }

    setExpandedRowIds(arr);
  }, [BookingSheetData]);

  const onShowHaircut = React.useCallback(
    (memberId) => {
      dialog.showCustom({
        render(onClose) {
          return <ShowHaircutDialog memberId={memberId} onClose={onClose} />;
        },
      });
    },
    [dialog],
  );

  const columnWidths = React.useMemo(
    () => [
      { columnName: 'name', width: 100 },
      { columnName: 'status', width: 100 },
      { columnName: 'source', width: 100 },
      { columnName: 'time', width: 170 },
    ],
    [],
  );

  return (
    <Body show={show}>
      <LeftContainer>
        <Space>
          <CenterRow>
            <H2>{t('management.ongoing')}</H2>
            <LoadingContainer>
              <LoadingIndicator isOpen={called && loading} />
            </LoadingContainer>
          </CenterRow>
          <CenterRow>
            <OperationalButton
              active={isOperational}
              onClick={() => setIsOperational(true)}
            >
              {t('common.operational_time')}
            </OperationalButton>
            <NotOperationalButton
              active={!isOperational}
              onClick={() => setIsOperational(false)}
            >
              {t('common.not_operational_time')}
            </NotOperationalButton>
          </CenterRow>
        </Space>
        <TableButtonContainer>
          <OutlineButton onClick={onClickCollapse}>
            <ExpandLess />
            {t('common.collapse_all')}
          </OutlineButton>
          <MR8 />
          <OutlineButton onClick={onClickExpand}>
            <ExpandMore />
            {t('common.expand_all')}
          </OutlineButton>
        </TableButtonContainer>
        <TableComponent
          ref={tableRef}
          rows={BookingSheetData ? BookingSheetData : []}
          columns={OngoingColumn}
          selection={selection}
          expandedRowIds={expandedRowIds}
          setExpandedRowIds={setExpandedRowIds}
          onSelectionChange={onSelectionChange}
          rowDetail={({ row }) => {
            return (
              <>
                {row.bookings &&
                  row.bookings.map((booking: Booking) => {
                    const bookingType = getSource(booking.type, t);

                    const bookingStatus = getBookingStatus(booking.status, t);

                    const startTime = `${pad(
                      Math.floor(booking.startMinute / 60),
                    )} : ${pad(booking.startMinute % 60)}`;

                    const endTime = `${pad(
                      Math.floor(
                        (booking.startMinute + booking.timeInMinutes) / 60,
                      ),
                    )} : ${pad(
                      (booking.startMinute + booking.timeInMinutes) % 60,
                    )}`;

                    const time = `${startTime} - ${endTime}`;

                    return (
                      <>
                        <UpperContainer>
                          <TimeText>{time}</TimeText>
                          <ShowHaircutButton
                            onClick={() => onShowHaircut(booking?.member?.id)}
                          >
                            {t('common.show_haircut')}
                          </ShowHaircutButton>
                        </UpperContainer>
                        <BookingDetailRow
                          selected={bookingSelected === booking.id}
                          onClick={() => onClickRowDetail(booking)}
                        >
                          <DetailRow>
                            <StylistName>{booking.stylist?.name}</StylistName>
                          </DetailRow>
                          <DetailRow>
                            <BookingName>{booking.member.name}</BookingName>
                          </DetailRow>
                          <DetailRow>
                            <Status
                              text={bookingStatus.text}
                              color={bookingStatus.color}
                            />
                          </DetailRow>
                          <DetailRow>
                            <Status
                              text={bookingType.text}
                              color={bookingType.color}
                            />
                          </DetailRow>
                        </BookingDetailRow>
                      </>
                    );
                  })}
              </>
            );
          }}
          enableSelection={buttonActive}
          enableDetailRow
        />
      </LeftContainer>
      <CenterContainer />
      <RightContainer>
        <CenterRow>
          <Title>{t('management.not_processed')}</Title>
          <LoadingContainer>
            <LoadingIndicator
              isOpen={unprocessedCalled && unprocessedLoading}
            />
          </LoadingContainer>
        </CenterRow>
        <MB35 />
        <TableComponent
          rows={unprocessedData ? unprocessedData.bookingUnset.data : []}
          columns={NotProcessedColumn}
          selection={unProcessedSelection}
          onSelectionChange={onUnprocessedSelectionChange}
          enableSelection
          columnWidths={columnWidths}
        />
      </RightContainer>
      <LoadingView isOpen={isLoading} />
    </Body>
  );
}
