import {
  Resolver,
  gql,
  useMutation,
  MutationFunctionOptions,
} from '@apollo/client';
import { ApiResult } from 'repositories';
import { Member } from './auth.model';
import { GET_LOGIN_TOKEN } from './auth.query';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';
import { MasterData } from 'repositories/common/common.model';
import { camelizeKeys } from 'humps';
import bcrypt from 'bcryptjs';

const POST_LOGIN = gql`
  fragment LoginInput on REST {
    email: String
    password: String
  }

  mutation login($payload: LoginInput!) {
    auth(payload: $payload)
      @rest(
        type: "LoginPayload"
        path: "/login"
        method: "POST"
        bodyKey: "payload"
      ) {
      data @type(name: "Auth") {
        token
        outletAlias
      }
      message
    }
  }
`;

const POST_CHANGE_PASSWORD = gql`
  fragment ChangePasswordInput on REST {
    password: String
    password_confirmation: String
  }

  mutation changePassword($payload: LoginInput!) {
    changePassword(payload: $payload)
      @rest(
        type: "ChangePasswordPayload"
        path: "/change-password"
        method: "POST"
        bodyKey: "payload"
      ) {
      message
    }
  }
`;

const POST_CHECK_USER = gql`
  fragment CheckUserInput on REST {
    phone_number: String
    member_code: String
  }

  mutation CheckUser($payload: CheckUserInput!) {
    checkUser(payload: $payload)
      @rest(
        type: "CheckUserPayload"
        path: "/check-user"
        method: "POST"
        bodyKey: "payload"
      ) {
      data {
        exist
        member {
          id
          phoneNumber
          name
          birthDate
          email
          address
          point
        }
      }
      message
    }
  }
`;

const SET_LOGIN_TOKEN = gql`
  mutation setLoginToken($token: String!) {
    setLoginToken(token: $token) @client
  }
`;

export function useStateSetLoginToken() {
  return useMutation<null, { token?: string }>(SET_LOGIN_TOKEN);
}

export function useMutationLogin() {
  const { mode } = useNetworkStatus();
  const mutation = useMutation<
    { auth: ApiResult<{ token: string; outletAlias: string }> },
    { payload: { email: string; password: string } }
  >(POST_LOGIN);

  let result: MasterData | string | null = localStorage.getItem('masterData');
  let admins: any[] = [];

  if (result && result !== 'undefined') {
    result = JSON.parse(result) as MasterData;

    if (result.admins) {
      admins = result.admins;
    }
  }

  if (!mode) {
    const loginFunction = async function (
      options?: MutationFunctionOptions<any, Record<string, any>>,
    ) {
      if (options?.variables?.payload) {
        const payload: any = camelizeKeys(options?.variables.payload);
        const { email, password } = payload;

        if (admins.length) {
          const admin = admins.find((admin) => admin.email === email);

          if (admin) {
            if (!bcrypt.compareSync(password, admin.cashierId)) {
              throw new Error('Gagal Masuk');
            }
          } else {
            throw new Error('Gagal Masuk');
          }
        } else {
          throw new Error('Gagal Masuk');
        }
      }

      return { data: undefined };
    };

    return [loginFunction];
  }

  return mutation;
}

export type CheckUserResultModel = {
  checkUser: ApiResult<{ member: Member; exist: boolean }>;
};

export function useMutationChangePassword() {
  return useMutation<{ changePassword: { message: string } }>(
    POST_CHANGE_PASSWORD,
  );
}

export function useMutationCheckUser() {
  return useMutation<CheckUserResultModel>(POST_CHECK_USER);
}

export const authLocalResolvers: { [key: string]: Resolver } = {
  setLoginToken(_, variables, { cache }) {
    cache.writeQuery({
      query: GET_LOGIN_TOKEN,
      data: {
        loginToken: variables.token,
      },
    });
    return null;
  },
};
