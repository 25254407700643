import * as React from 'react';
import {
  CustomInput,
  CheckButton,
  CustomContainer,
  StatusText,
} from 'pages/transactionManagement/style';
import { Voucher } from 'repositories/voucher/voucher.model';
import { useTranslation } from 'react-i18next';
import { useMutationCheckVoucher } from 'repositories/voucher/voucher.mutation';
import useAppState from 'hooks/useAppState';
import { useField } from 'formik';
import {
  TransactionService,
  TransactionProduct,
} from 'repositories/transaction';

interface Props {
  refetchVoucher: boolean;
  setRefetchVoucher: React.Dispatch<React.SetStateAction<boolean>>;
  servicesName: string;
  productsName: string;
  voucherCode: string;
  voucher: Voucher | null;
  memberId?: string;
  transactionAt?: Date;
  setIsLoading?: (isLoading: boolean) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onApplied: (voucher: Voucher | null) => void;
}

export default function VoucherInput(props: Props) {
  const { t } = useTranslation();
  const {
    servicesName,
    productsName,
    onChange,
    onBlur,
    voucherCode,
    voucher,
    memberId,
    setIsLoading,
    onApplied,
    refetchVoucher,
    setRefetchVoucher,
    transactionAt,
  } = props;
  const [message, setMessage] = React.useState('');
  const [checkVoucher, { loading }] = useMutationCheckVoucher();
  const { currentOutlet } = useAppState();

  const [servicesField, servicesMeta, servicesHelper] = useField<
    TransactionService[]
  >(servicesName);

  const [productsField, productsMeta, productsHelper] = useField<
    TransactionProduct[]
  >(productsName);

  const onVoucherCheck = React.useCallback(async () => {
    if (!currentOutlet) {
      return;
    }
    try {
      setIsLoading && setIsLoading(true);
      const { data } = await checkVoucher({
        variables: {
          payload: {
            transaction_at: transactionAt && transactionAt.toISOString(),
            outlet_id: currentOutlet.id,
            code: voucherCode,
            member_id: memberId,
            ...(servicesField.value && servicesField.value.length
              ? {
                  service_id: servicesField.value.map(
                    (service) => service.service.id,
                  ),
                }
              : {}),
            ...(productsField.value && productsField.value.length
              ? {
                  products: productsField.value.map((product) => ({
                    id: product.product.id,
                    qty: product.qty,
                  })),
                }
              : {}),
          },
        },
      });
      onApplied(data!.voucher.data);
      setMessage(t('status.voucher_available'));
    } catch (e) {
      onApplied(null);
      setMessage(t('status.voucher_not_available'));
    } finally {
      setIsLoading && setIsLoading(false);
    }
  }, [
    currentOutlet,
    setIsLoading,
    checkVoucher,
    transactionAt,
    voucherCode,
    memberId,
    servicesField.value,
    productsField.value,
    onApplied,
    t,
  ]);

  React.useEffect(() => {
    if (refetchVoucher) {
      onVoucherCheck();
      setRefetchVoucher(false);
    }
  }, [onVoucherCheck, refetchVoucher, setRefetchVoucher]);

  const _onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setMessage('');
      onApplied(null);
      onChange && onChange(e);
    },
    [setMessage, onApplied, onChange],
  );

  return (
    <>
      <CustomContainer>
        <CustomInput
          name="voucherCode"
          value={voucherCode}
          onChange={_onChange}
          onBlur={onBlur}
          placeholder={t('transaction.optional_promo_code')}
        />
        <CheckButton isLoading={loading} onClick={onVoucherCheck}>
          {t('common.check')}
        </CheckButton>
      </CustomContainer>
      <StatusText available={!!voucher}>
        {message
          ? `${message} ${
              !!voucher && voucher.limit
                ? `(${t('common.remaining_quota')} : ${voucher.restLimit})`
                : ''
            }`
          : ''}
      </StatusText>
    </>
  );
}
