import styled from 'styled-components';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import size from 'styles/size';
import color from 'styles/color';

// function ComposedDatePicker(props: ReactDatePickerProps) {
//   return <DatePicker {...props} />;
// }

export type DateTimePickerProps = ReactDatePickerProps;

const DateTimePicker = styled(ReactDatePicker)`
  width: 100%;
  min-height: ${size.inputMinHeight};
  font-weight: bold;
  padding: 0px ${size.inputPadding};
  border-radius: ${size.inputBorder};
  border: 1px solid ${color.border};
  appearance: none;
  outline: none;
  text-align: left !important;
  &:focus {
    box-shadow: none;
    border-color: ${color.accent};
  }
  &::placeholder {
    color: ${color.placeholder};
  }
`;

export default DateTimePicker;
