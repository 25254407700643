import * as React from 'react';
import {
  Container,
  Header,
  MarginHorizontal,
  DateContainer,
  Label,
  DateFilter,
} from './style';
import { useTranslation } from 'react-i18next';
import { TableComponent } from 'components/element';
import { string2money } from 'utils/string';
import {
  useStateGetCurrentOutlet,
  useQueryGetOutletStylistCommission,
  StylistCommission,
} from 'repositories/outlet';
import { format } from 'date-fns';
import StylistCommissionDetail from './component/stylistCommissionDetail';
import useDialog from 'hooks/useDialog';
import { LoadingContainer } from './style';
import LoadingIndicator from 'components/Loading/LoadingIndicator';
import DateInput from 'components/element/DateInput';

interface DateInterface {
  startDate: Date;
  endDate: Date;
}

const startDate = new Date();
startDate.setDate(startDate.getDate() - 7);

const initialDate = {
  startDate: startDate,
  endDate: new Date(),
};

export default function TransactionRecap(props: any) {
  const { t } = useTranslation();
  const dialog = useDialog();
  const [selection, setSelection] = React.useState<any[]>([]);
  const [date, setDate] = React.useState<DateInterface>(initialDate);

  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';

  React.useEffect(() => {
    setDate(initialDate);
  }, [outletId]);

  const [
    loadStylistCommissions,
    { called, data, loading },
  ] = useQueryGetOutletStylistCommission(
    outletId,
    format(date.startDate, 'dd/MM/yyyy'),
    format(date.endDate, 'dd/MM/yyyy'),
  );

  React.useEffect(() => {
    if (outletId && !called) {
      loadStylistCommissions();
    }
  }, [called, loadStylistCommissions, outletId]);

  const onSelectionChange = (params: React.ReactText[]) => {
    setSelection([params[params.length - 1]]);

    if (params[params.length - 1] !== undefined) {
      const currentSelection =
        data?.outletStylistCommission[params[params.length - 1] as number];

      dialog.showCustom({
        render(onClose) {
          return (
            <StylistCommissionDetail
              onClose={() => {
                onClose();
                setSelection([]);
              }}
              dateFrom={format(date.startDate, 'dd/MM/yyyy')}
              dateTo={format(date.endDate, 'dd/MM/yyyy')}
              stylistId={currentSelection ? currentSelection.stylistId : ''}
            />
          );
        },
      });
    }
  };

  const onStartDateChange = React.useCallback((date: Date) => {
    setDate((prev: DateInterface) => ({
      ...prev,
      startDate: date,
    }));
  }, []);

  const onEndDateChange = React.useCallback((date: Date) => {
    setDate((prev: DateInterface) => ({
      ...prev,
      endDate: date,
    }));
  }, []);

  const column = React.useMemo(
    () => [
      {
        name: 'filterDate',
        title: t('common.date'),
      },
      {
        name: 'stylistName',
        title: t('common.stylist'),
      },
      { name: 'serviceCnt', title: t('transaction.total_service') },
      {
        name: 'commision',
        title: t('common.commission'),
        getCellValue: (row: StylistCommission) =>
          `Rp. ${string2money(row.totalStylistCommission)}`,
      },
    ],
    [t],
  );

  return (
    <Container>
      <Header>
        <DateContainer>
          <Label>{t('common.start_date')}</Label>
          <DateFilter
            selected={date.startDate}
            onChange={onStartDateChange}
            dateFormat="yyyy-MM-dd"
            disabledKeyboardNavigation
            customInput={<DateInput />}
          />
        </DateContainer>
        <MarginHorizontal />
        <DateContainer>
          <Label>{t('common.end_date')}</Label>
          <DateFilter
            selected={date.endDate}
            onChange={onEndDateChange}
            dateFormat="yyyy-MM-dd"
            customInput={<DateInput />}
          />
        </DateContainer>
        <LoadingContainer>
          <LoadingIndicator isOpen={called && loading} />
        </LoadingContainer>
      </Header>
      <TableComponent
        rows={data ? data.outletStylistCommission : []}
        columns={column}
        // selection={selection}
        // onSelectionChange={onSelectionChange}
        // enableSelection
        enableDetailRow={false}
      />
    </Container>
  );
}
