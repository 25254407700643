import { gql } from '@apollo/client';

export const PAYMENT_FRAGMENT = gql`
  fragment Payment on Payment {
    id
    name
    fee1
    fee1Type
    fee2
    fee2Type
    fee3
    fee3Type
    isAvailable
    createdAt
    updatedAt
  }
`;
