import color from 'styles/color';

export const getBookingStatus = (status: number, t: any) => {
  switch (status) {
    case 1:
      return {
        text: t('status.scheduled'),
        color: color.cornflowerBlue1,
      };
    case 2:
      return {
        text: t('status.started'),
        color: color.cornflowerBlue1,
      };
    case 3:
      return {
        text: t('status.completed'),
        color: color.regalBlue1,
      };
    case 9:
      return {
        text: t('status.cancelled'),
        color: color.red,
      };
    default:
      return {
        text: t('status.scheduled'),
        color: color.cornflowerBlue1,
      };
  }
};
