import styled from 'styled-components';
import color from 'styles/color';
import size from 'styles/size';
import { H1 } from 'components/element';

export const Container = styled.div`
  background-color: ${color.lightGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
`;

export const Panel = styled.div`
  background-color: white;
  border-radius: ${size.panelBorder};
  width: 500px;
  padding: 26px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1023px) {
    width: 70%;
  }
`;

export const Title = styled(H1)`
  text-align: center;
  margin-bottom: 1rem;
`;
