import * as React from 'react';
import DialogBackdrop from 'components/dialog/dialogBackdrop';
import styled from 'styled-components';
import { H3, P } from 'components/element';
import { useTranslation } from 'react-i18next';
import color from 'styles/color';
import {
  useStateGetCurrentOutlet,
  useQueryGetOutletStylistCommissionDetail,
} from 'repositories/outlet';
import LoadingView from 'components/LoadingView';
import { string2money } from 'utils/string';

interface Props {
  onClose: () => void;
  dateFrom: string;
  dateTo: string;
  stylistId: string;
}

export default function StylistCommissionDetail(props: Props) {
  const { t } = useTranslation();
  const { onClose, dateFrom, dateTo, stylistId } = props;

  const outlet = useStateGetCurrentOutlet();
  const outletId = outlet.data ? outlet.data.loggedInOutletId || '' : '';

  const { data, loading } = useQueryGetOutletStylistCommissionDetail(
    outletId,
    dateFrom,
    dateTo,
    stylistId,
  );

  const stylistCommission = data ? data?.outletStylistCommissionDetail : null;

  const total = React.useMemo(() => {
    let totalCommission = 0;
    if (stylistCommission) {
      stylistCommission.forEach(
        (cur) => (totalCommission += Number(cur.totalStylistCommission)),
      );
    }

    return totalCommission;
  }, [stylistCommission]);

  return (
    <DialogBackdrop onClose={onClose} cancelable={false}>
      <Panel>
        {stylistCommission && (
          <>
            <Container>
              <H3Title>{t('common.date')}</H3Title>
              <P>{`${dateFrom} - ${dateTo}`}</P>
            </Container>
            <Separator />
            <Container>
              <ServiceTitle>{t('common.service')}</ServiceTitle>
              {stylistCommission.map((service) => {
                return (
                  <Row>
                    <P>{`( x${service.serviceCnt} ) ${service.serviceName}`}</P>
                    <P>{`Rp ${string2money(
                      service.totalStylistCommission,
                    )}`}</P>
                  </Row>
                );
              })}
            </Container>
            <Separator />
            <Container>
              <Row>
                <P>{t('common.total')}</P>
                <P>{`Rp ${string2money(total)}`}</P>
              </Row>
            </Container>
          </>
        )}
        <CloseButton onClick={onClose}>x</CloseButton>
        <LoadingView isOpen={loading} block />
      </Panel>
    </DialogBackdrop>
  );
}

const Container = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.3rem;
  width: 100%;
  justify-content: space-between;
`;

const H3Title = styled(H3)`
  margin-bottom: 0.3rem;
`;

const Panel = styled.div`
  width: 40%;
  background-color: white;
  border-radius: 5px;
  min-height: 300px;
  max-height: 500px;
  position: relative;
  padding: 1rem;
  overflow-y: scroll;

  @media (max-width: 1023px) {
    width: 70%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding-bottom: 1px;
  background-color: ${color.white};
  color: black;
  font-size: 36px;
  line-height: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  outline: none;
  border: none;
  appearance: none;
`;

const Separator = styled.div`
  border-bottom: 1px solid ${color.border};
  width: 100%;
  margin: 1rem 0;
`;

const ServiceTitle = styled(H3)`
  margin-bottom: 1rem;
  padding-bottom: 0.3rem;
  display: flex;
  border-bottom: 1px solid ${color.black};
`;
